.view_details_main_container {
  max-width: 90%;
  margin: 2% auto;
  box-shadow: 0px 2px 4px 0px rgb(0 23 55 / 55%);
  -webkit-box-shadow: 0px 2px 4px 0px rgb(0 23 55 / 55%);
  -moz-box-shadow: 0px 2px 4px 0px rgb(0 23 55 / 55%);
  border-radius: 5px;
  padding: 0 0 2% 0;
}

.view_details_course_btn_img_container {
  position: relative;
}

.view_details_img_container11 {
  max-height: 300px;
  height: 300px;
  border: 1px solid rgb(124, 124, 124);
  display: flex;
}

.view_details_contianer_course {
  display: flex;
  justify-content: space-between;
  /* gap: 21px; */
  width: 100%;
}

.like_icon_container {}

.view_detail_btn_course {
  margin: 0;
  width: fit-content;
  padding: 5px;
  display: flex;
  place-items: normal;
  justify-content: center;
  border: 1px solid var(--theme-blue);
  outline: none;
  color: var(--theme-blue);
  font-size: 14px;
  font-family: IBMMed;
  background-color: transparent;
  height: 33px;
  /* padding: 1% 4% 1% 4%; */
  border-radius: 4px;
}

.view_details_univercity_img {
  width: 100%;
  object-fit: cover;
}

.view_course_details_btn {
  position: absolute;
  left: 0.1%;
  bottom: 0;
  height: 50px;
  font-size: 22px;
  width: 16%;
  border: 0;
  font-family: "IBMMed";
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  background: var(--theme-blue);
  color: #fff;
}

.view_course_details_btn:hover {
  background: #233a5e;
  color: #fff;
}

.clg_name_view_details_text {
  font-family: "IBMSemBold";
  letter-spacing: 0.5px;
  margin: 2% 0 2% 2%;
}

.left_right_view_details_contianer {
  display: flex;
  flex-direction: row;
  width: 98%;
  /* background: red; */
  margin: 0 auto 0 auto;
}

.left_view_details_container {
  width: 60%;
  padding: 0 2% 0 1%;
}

.right_view_details_container {
  width: 40%;
}

.img_text_contaier_unive_view_details {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 2% 0 0 0;
}

.canada_img_univ_view_details {
  width: 30px;
  height: 30px;
}

.text_sub_uiver_view_details {
  font-family: "IBMMed";
  font-size: 20px;
  text-transform: capitalize;
  color: var(--theme-blue);
  margin: 0 0 0 2%;
}

.view_details_heading_text {
  font-size: 22px;
  font-weight: 600;
  font-family: "IBMMED";
}

.summary_text_content_view_details {
  margin: 4% 0 0 0;
}

.paragraph_view_details_text {
  font-family: "IBMReg";
  font-size: 18px;
  letter-spacing: 1px;
  color: var(--theme-blue);
  line-height: 20px;
  user-select: auto;
}

.paragraph_view_details_text h1,
.paragraph_view_details_text h2,
.paragraph_view_details_text h3,
.paragraph_view_details_text h4,
.paragraph_view_details_text h5,
.paragraph_view_details_text h6 {
 font-size: 12px;
 font-family: IBMSemBold;
 color: var(--theme-blue);
}

.location_map {
  width: 80%;
  height: 200px;
}

/* course view details */

.view_course_univercity_box {
  outline: none;
  background: transparent;
  /* border: 1px solid rgba(1, 1, 2, 0.16); */
  width: 30.33%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid var(--theme-blue);
  border-radius: 4px;
  padding: 1%;
  margin: 0 0 2% 2%;
  text-align: left;
  color: var(--theme-blue);
}

.view_course_univercity_box:hover {
  /* background: var(--theme-blue);
  color: #fff; */
}

.wrap_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.view_details_heading_text1 {
  font-size: 20px;
  font-weight: 500;
  font-family: "IBMMED";
  margin: 0 0 1% 2%;
}

.view_course_univer_button_container {}

@media (max-width: 767px) {
  .paragraph_view_details_text h1,
.paragraph_view_details_text h2,
.paragraph_view_details_text h3,
.paragraph_view_details_text h4,
.paragraph_view_details_text h5,
.paragraph_view_details_text h6 {
 font-size: 11.5px;

}
  .view_details_img_container11 {
    max-height: 200px;
    height: 200px;
  }

  .view_course_univercity_box {
    outline: none;
    background: transparent;
    width: 94%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border: 1px solid var(--theme-blue);
    border-radius: 4px;
    padding: 3%;
    margin: 0 0 4% 3%;
    text-align: left;
    color: var(--theme-blue);
  }

  .view_details_heading_text1 {
    font-size: 17px;

    margin: 0 0 3% 2%;
  }

  .view_details_main_container {
    max-width: 90%;
    margin: 32px auto;
  }

  .view_course_details_btn {
    width: 45%;
    height: 40px;
    font-size: 16px;
  }

  .clg_name_view_details_text {
    font-size: 16px;
    margin: 3% 0 3% 3%;
  }

  .left_right_view_details_contianer {
    flex-direction: column;
  }

  .left_view_details_container {
    width: 100%;
    padding: 0 2% 0 2%;
  }

  .view_details_heading_text {
    font-size: 13px;
  }

  .img_text_contaier_unive_view_details {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2% 0 2% 0;
  }

  .canada_img_univ_view_details {
    width: 20px;
    height: 20px;
  }

  .text_sub_uiver_view_details {
    font-size: 13px;

    margin: 0 0 0 3%;
  }

  .paragraph_view_details_text {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: justify;
  }

  .paragraph_view_details_text> p ,.paragraph_view_details_text p ,   .paragraph_view_details_text> p ,.paragraph_view_details_text li,
  .paragraph_view_details_text> p > strong,   
  .paragraph_view_details_text span{
    font-size: 12px !important;
  }

  .right_view_details_container {
    width: 98%;
    padding: 0 2%;
  }

  .location_map {
    width: 70%;
    height: 160px;
  }
}

@media (min-width: 768px) {

  .paragraph_view_details_text h1,
  .paragraph_view_details_text h2,
  .paragraph_view_details_text h3,
  .paragraph_view_details_text h4,
  .paragraph_view_details_text h5,
  .paragraph_view_details_text h6 {
   font-size: 13px;
  
  }

  .paragraph_view_details_text>p,
  .paragraph_view_details_text>p>strong ,
   .paragraph_view_details_text span{
    font-size: 13px !important;
  }

  .view_details_img_container11 {
    max-height: 250px;
    height: 250px;
  }

  .wrap_contianer {
    width: 98%;
  }

  .view_course_univercity_box {
    width: 31.33%;

    padding: 1%;
    margin: 0 0 2% 2%;
  }

  .view_details_main_container {
    max-width: 690px;
    margin: 32px auto;
  }

  .view_course_details_btn {
    width: 25%;
    height: 40px;
    font-size: 17px;
  }

  .clg_name_view_details_text {
    font-size: 20px;
    margin: 2% 0 2% 2%;
  }

  .left_right_view_details_contianer {
    flex-direction: column;
  }

  .left_view_details_container {
    width: 100%;
    padding: 0 2% 0 1%;
  }

  .view_details_heading_text {
    font-size: 15px;
  }

  .img_text_contaier_unive_view_details {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 1% 0 0% 0;
  }

  .canada_img_univ_view_details {
    width: 25px;
    height: 25px;
  }

  .text_sub_uiver_view_details {
    font-size: 14px;
    margin: 0 0 0 1.5%;
  }

  .paragraph_view_details_text {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 19px;
    text-align: justify;
  }

  .right_view_details_container {
    width: 98%;
    padding: 0 1%;
  }

  .location_map {
    width: 70%;
    height: 160px;
  }

  .summary_text_content_view_details {
    margin: 2% 0 0 0;
  }
}

@media (min-width: 991px) {
  
  .paragraph_view_details_text h1,
  .paragraph_view_details_text h2,
  .paragraph_view_details_text h3,
  .paragraph_view_details_text h4,
  .paragraph_view_details_text h5,
  .paragraph_view_details_text h6 {
   font-size: 18px;
  
  }

  .paragraph_view_details_text>p,
  .paragraph_view_details_text>p>strong ,.paragraph_view_details_text span{
    font-size: 16.5px !important;
  }

  .view_details_img_container11 {
    max-height: 300px;
    height: 300px;
  }

  .view_course_univercity_box {
    width: 22.33%;

    padding: 1%;
    margin: 0 0 2% 2%;
  }

  .view_details_main_container {
    max-width: 920px;
    margin: 2% auto;
    border-radius: 5px;
    padding: 0 0 2% 0;
  }

  .view_details_course_btn_img_container {
    position: relative;
  }

  .view_details_univercity_img {
    width: 100%;
  }

  .view_course_details_btn {
    position: absolute;
    left: 0.1%;
    bottom: 0;
    height: 50px;
    font-size: 22px;
    width: 23%;

    border-radius: 5px;
    letter-spacing: 1px;
  }

  .clg_name_view_details_text {
    font-size: 26px;
    margin: 2% 0 1% 2%;
  }

  .left_right_view_details_contianer {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 0 auto 0 auto;
  }

  .left_view_details_container {
    width: 60%;
    padding: 0 2% 0 1%;
  }

  .right_view_details_container {
    width: 40%;
  }

  .img_text_contaier_unive_view_details {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2% 0 0 0;
  }

  .canada_img_univ_view_details {
    width: 30px;
    height: 30px;
  }

  .text_sub_uiver_view_details {
    font-size: 18px;

    margin: 0 0 0 2%;
  }

  .view_details_heading_text {
    font-size: 19px;
  }

  .summary_text_content_view_details {
    margin: 4% 0 0 0;
  }

  .paragraph_view_details_text {
    font-size: 18px;
    letter-spacing: 0px;

    line-height: 20px;
  }

  .location_map {
    width: 90%;
    height: 260px;
  }

  /* course view details */

  .wrap_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .view_details_heading_text1 {
    font-size: 20px;

    margin: 0 0 1% 2%;
  }
}

@media (min-width: 1200px) {
  .paragraph_view_details_text h1,
  .paragraph_view_details_text h2,
  .paragraph_view_details_text h3,
  .paragraph_view_details_text h4,
  .paragraph_view_details_text h5,
  .paragraph_view_details_text h6 {
   font-size: 18.5px;
  
  }
  .view_details_img_container11 {
    max-height: 500px;
    height: 500px;
  }

  .view_course_univercity_box {
    width: 22.33%;

    padding: 1%;
    margin: 0 0 2% 2%;
  }

  .view_details_main_container {
    max-width: 1100px;
    margin: 2% auto;
    border-radius: 5px;
    padding: 0 0 2% 0;
  }

  .view_details_course_btn_img_container {
    position: relative;
  }

  .view_details_univercity_img {
    width: 100%;
  }

  .view_course_details_btn {
    position: absolute;
    left: 0.1%;
    bottom: 0;
    height: 50px;
    font-size: 22px;
    width: 23%;

    border-radius: 5px;
    letter-spacing: 1px;
  }

  .clg_name_view_details_text {
    font-size: 26px;
    margin: 2% 0 2% 2%;
    font-family: "IBMSemBold";
    letter-spacing: 0.5px;
  }

  .left_right_view_details_contianer {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 0 auto 0 auto;
  }

  .left_view_details_container {
    width: 60%;
    padding: 0 2% 0 1%;
  }

  .right_view_details_container {
    width: 40%;
  }

  .img_text_contaier_unive_view_details {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2% 0 0 0;
  }

  .canada_img_univ_view_details {
    width: 30px;
    height: 30px;
  }

  .text_sub_uiver_view_details {
    font-size: 18px;

    margin: 0 0 0 2%;
  }

  .view_details_heading_text {
    font-size: 19px;
  }

  .summary_text_content_view_details {
    margin: 4% 0 0 0;
  }

  .paragraph_view_details_text {
    font-size: 18px;
    letter-spacing: 1px;

    line-height: 20px;
  }

  .location_map {
    width: 90%;
    height: 260px;
  }

  /* course view details */

  .view_course_univercity_box {
    width: 22.33%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 1%;
    margin: 0 0 2% 2%;
    text-align: left;
  }

  .wrap_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .view_details_heading_text1 {
    font-size: 20px;

    margin: 0 0 1% 2%;
  }
}

@media (min-width: 1400px) {
  .paragraph_view_details_text h1,
  .paragraph_view_details_text h2,
  .paragraph_view_details_text h3,
  .paragraph_view_details_text h4,
  .paragraph_view_details_text h5,
  .paragraph_view_details_text h6 {
   font-size: 19.5px;
  
  }
  .view_details_main_container {
    max-width: 1200px;
    margin: 2% auto;
    border-radius: 5px;
    padding: 0 0 2% 0;
  }

  .view_details_course_btn_img_container {
    position: relative;
  }

  .view_details_univercity_img {
    width: 100%;
  }

  .view_course_details_btn {
    position: absolute;
    left: 0.1%;
    bottom: 0;
    height: 55px;
    font-size: 22px;
    width: 22%;

    border-radius: 5px;
    letter-spacing: 1px;
  }

  .clg_name_view_details_text {
    font-size: 28px;
    margin: 2% 0 1% 2%;
  }

  .left_right_view_details_contianer {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 0 auto 0 auto;
  }

  .left_view_details_container {
    width: 60%;
    padding: 0 2% 0 1%;
  }

  .right_view_details_container {
    width: 40%;
  }

  .img_text_contaier_unive_view_details {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2% 0 0 0;
  }

  .canada_img_univ_view_details {
    width: 30px;
    height: 30px;
  }

  .text_sub_uiver_view_details {
    font-size: 20px;
    margin: 0 0 0 2%;
  }

  .view_details_heading_text {
    font-size: 20px;
  }

  .summary_text_content_view_details {
    margin: 4% 0 0 0;
  }

  .paragraph_view_details_text {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 23px;
  }

  .location_map {
    width: 90%;
    height: 260px;
  }

  /* course view details */

  .view_course_univercity_box {
    width: 22.33%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 1%;
    margin: 0 0 2% 2%;
    text-align: left;
  }

  .wrap_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .view_details_heading_text1 {
    font-size: 20px;

    margin: 0 0 1% 2%;
  }
}

.section_box_container {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
}
.center_no_likes_container{
  width: 100%;
    margin: 0 auto;
}
.no_likes_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no_likes_container a:last-child{
  font-family: 'IBMMed';
  font-size: 17px;
  color: var(--theme-blue);
  padding: 7px 10px;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -ms-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}
.no_likes_container a:last-child:hover{
  font-family: 'IBMBold';

  background-color: #b4b4b442;
  padding: 7px 10px;
  border-radius: 10px;
  
}
.no_likes_container > img{
  width: 400px;
  height: 400px;
 
}
.left_count_container {
  width: 10%;
}
.avtar_container {
}

.avtar_container > img {
  width: 50px;
  object-fit: cover;
  height: 50px;
  border: 1px solid #0000002b;
  border-radius: 50%;
}
.right_count_container {
  width: 90%;
  color: var(--theme-blue);
}
.right_count_container > strong {
  font-family: IBMMed;
  font-size: 15px;
  text-transform: capitalize;
}
.right_count_container > p {
  margin: 0;
  font-family: IBMReg;
  font-size: 12px;
}

@media (max-width: 299px) {
  .no_likes_container > img{
    width: 200px;
    height: 200px;
   
  }
  
  .section_box_container {
    align-items: center;
    margin: 5px 0;
  }
  .avtar_container > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .left_count_container {
    width: 20%;
  }
  .right_count_container {
    width: 80%;
  }
  .right_count_container > strong {
    font-size: 12px;
  }
  .right_count_container > p {
    font-size: 10px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .no_likes_container > img{
    width: 250px;
    height: 250px;
   
  }
  .section_box_container {
    align-items: center;
    margin: 5px 0;
    width: 299px;
  }
  .avtar_container > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .left_count_container {
    width: 15%;
  }
  .right_count_container {
    width: 85%;
  }
  .right_count_container > strong {
    font-size: 12px;
  }
  .right_count_container > p {
    font-size: 11px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .no_likes_container > img{
    width: 250px;
    height: 250px;
   
  }
  .section_box_container {
    align-items: center;
    margin: 5px 0;
    width: 399px;
  }
  .avtar_container > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .left_count_container {
    width: 13%;
  }
  .right_count_container {
    width: 87%;
  }
  .right_count_container > strong {
    font-size: 14px;
  }
  .right_count_container > p {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .no_likes_container > img{
    width: 250px;
    height: 250px;
   
  }
  .section_box_container {
    align-items: center;
    margin: 5px 0;
    width: 100%;
  }
  .avtar_container > img {
    width: 40px;
    height: 40px;
   
    border-radius: 50%;
  }
  .left_count_container {
    width: 13%;
  }
  .right_count_container {
    width: 87%;
  }
  .right_count_container > strong {
    font-size: 14px;
  }
  .right_count_container > p {
    font-size: 12px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .no_likes_container > img{
    width: 350px;
    height: 350px;
   
  }
  .section_box_container {
    align-items: center;
    margin: 10px 0;
    width: 100%;
  }
  .avtar_container > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .left_count_container {
    width: 8%;
  }
  .right_count_container {
    width: 92%;
  }
  .right_count_container > strong {
    font-size: 15px;
  }
  .right_count_container > p {
    font-size: 13px;
  }
}

@media (min-width: 1400px) {
  .left_count_container {
    width: 8%;
  }
  .right_count_container {
    width: 92%;
  }
  .right_count_container > strong {
    font-size: 19px;
  }
  .right_count_container > p {
    font-size: 18px;
  }
}

.one_under_main_container {
  /* width: 20%;
  height: 80%;
  overflow-y: auto;
  position: fixed; */
  color: var(--theme-blue);
  /* width: 90%; */
}

.profile_circle_container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f5f5f5;
  border: 1px solid #c3c3c3;
}

.profile_pic_self {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;

  /* border: 1px solid #c3c3c3; */
}

.active_self {
  position: absolute;
  right: 0;
  bottom: 15%;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(16, 183, 89);
}

.name_of_profile {
  letter-spacing: -0.5px;
  margin-bottom: 2px;
  font-family: IBMMed;
  text-transform: capitalize;
  font-size: 18px;
  color: var(--theme-blue);
}

.username_profile {
  color: rgb(131, 146, 165);
  margin-bottom: 25px;
  font-family: IBMReg;
}

.name_username_editbtn_tagline_count_container {
  margin-top: 25px;
}

.msg_btn {
}

.edit_profile_btn {
  border: 1px solid rgb(1, 104, 250);
  color: rgb(255, 255, 255);
  background-color: rgb(1, 104, 250);
  margin-left: 10px;
}

.msg_btn {
  border: 1px solid rgb(192, 204, 218);
  color: rgba(27, 46, 75, 0.7);
  background-color: rgb(255, 255, 255);
}

.two_btn_cotainer {
  margin-bottom: 25px;
  display: flex;
}

.tagline_content {
  font-size: 13px;
  color: rgb(27, 46, 75);
  margin-bottom: 25px;
  font-family: IBMReg;
  text-align: justify;
}

.read_text_more_left_side {
  font-family: IBMMed;
  font-size: 13px;
  color: rgb(22, 112, 214);
  cursor: pointer;
}

.post_follow_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post_container {
  display: flex;
  width: 33.33%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.number_text {
  color: var(--theme-blue);
  font-family: InterMed;
  font-size: 20px;
}

.title_number_text {
  font-family: InterMed;
  display: block;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: rgb(131, 146, 165);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.univercity_contianer {
  color: var(--theme-blue);
}

.heading_university_text {
  display: block;
  font-size: 10px;
  margin-block-start: 1.67em;
  /* margin-block-end: 1.67em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: InterSemBold;
  color: var(--theme-blue);
}

.univercity_under_contianer {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  list-style: none;

  font-family: "IBMReg";
}

.univercity_under_container1 {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.ox_foard_text {
  display: block;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(192, 204, 218);
  padding: 3px 8px 1px;
  color: rgb(27, 46, 75);
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: 12px;
  cursor: default;
  font-family: IBMReg;
  margin: 0;
}

.score_text {
  display: block;
  font-size: 10px;
  margin-block-start: 1.67em;
  /* margin-block-end: 1.67em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: InterMed;
}

.progressbar_contianer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.progress_per_contianer {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5% 0 0;
}

.exam_text {
  margin-top: 10%;
  color: var(--theme-red);
  font-family: IBMMed;
  font-size: 10px;
  letter-spacing: 1px;
}

.same_btn_profile {
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  font-size: 14px;
  padding: 5px 0.9375rem;
  border-radius: 0.25rem;
  font-family: IBMReg;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  width: 50%;
}

.contact_icon_title_contianer {
  display: flex;
  align-items: center;
  margin: 0 0 3% 0;
}

.icon_cotanct_info {
  color: rgb(27, 46, 75);
  font-size: 17px;
}

.data_contact_info {
  font-family: IBMReg;
  font-size: 12px;
  text-decoration: none;
  color: rgb(27, 46, 75);
  margin: 0 0 0 8px;
}

.edit_profile_btn:hover {
  background-color: #0153c7;
  color: rgb(179, 174, 174);
}

.msg_btn:hover {
  border-color: rgb(105, 105, 105);
  color: rgb(105, 105, 105);
  font-weight: 500;
}
.about_text_description {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #1e2b4e;
  font-family: "IBMMed";
}
.anothrr_text_description {
  font-size: 16px;
  line-height: 22px;
  color: #1e2b4e;
  margin: 0;
  font-family: "IBMReg";
}

@media screen and (max-width: 767px) {
  .anothrr_text_description {
    font-size: 11px;
    line-height: 12px;
  }
  .about_text_description {
    margin: 15px 0;
    font-size: 13px;
    line-height: 16px;
  }
  .name_username_editbtn_tagline_count_container {
    margin-top: 12px;
  }
  .profile_circle_container {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .name_of_profile {
    letter-spacing: -0.5px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .one_under_main_container {
    width: 90%;
    margin: 10px auto 0 auto;
  }
  .username_profile {
    color: rgb(131, 146, 165);
    margin-bottom: 12px;
    font-family: IBMReg;
    font-size: 12px;
  }
  .same_btn_profile {
    font-size: 12px;
  }
  .tagline_content {
    font-size: 11px;
    margin-bottom: 11px;
    line-height: 15px;
  }
  .read_text_more_left_side {
    font-size: 12px;
  }
  .number_text {
    font-size: 15px;
    margin: 0 0 3px 0;
  }
  .ox_foard_text {
    font-size: 9px;
  }
  .heading_university_text {
    margin-block-end: 6px;
  }
  .score_text {
    margin-block-end: 6px;
  }
  .progress_per_contianer {
    width: 39px;
    margin: 2% 5% 0 0;
  }
  .exam_text {
    margin-top: 13%;
    font-size: 9px;
  }
  .icon_cotanct_info {
    font-size: 14px;
  }
  .data_contact_info {
    font-size: 11px;
    margin-left: 9px;
  }
}

@media screen and (min-width: 768px) {
  .profile_pic_self {
    width: 100px;
    height: 100px;
  }
  .username_profile {
    margin-bottom: 10px;
  }
  .one_under_main_container {
    padding: 2% 2%;
    /* background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
  }
  .univercity_under_contianer {
    font-size: 13px;
  }
  .about_text_description {
    margin: 16px 0;
    font-size: 16px;
  }
  .anothrr_text_description {
    margin: 0px 0 5px;
    font-size: 11px;
    line-height: 14px;
  }
  .tagline_content {
    font-size: 13px;
    color: rgb(27, 46, 75);
    margin-bottom: 25px;
    font-family: IBMReg;
    text-align: justify;
    line-height: 17px;
  }
  .title_number_text {
    font-size: 13px;
  }
  .heading_university_text {
    font-size: 13px;
    margin: 3% 0 2% 0;
  }
  .score_text {
    font-size: 13px;
    margin: 5px 0;
  }
  .contact_icon_title_contianer {
    display: flex;
    align-items: center;
    margin: 0 0 1% 0;
  }
  .same_btn_profile {
    width: 160px;
  }
}

@media screen and (min-width: 991px) {
  .about_text_description {
    margin: 0;

    font-size: 12px;
    line-height: 13px;
  }
  .one_under_main_container {
    border: 1px solid #0000002b;
    margin: 3% auto 0 0;
    position: fixed;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    padding: 15px 1%;
    bottom: 5px;
    width: 260px;
    overflow: hidden;
    overflow-y: auto;
  }
  .profile_circle_container {
    margin-top: 6px;

    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .profile_pic_self {
    border-radius: 50%;
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
  .active_self {
    position: absolute;
    right: 0;
    bottom: 15%;
    border: 2px solid #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(16, 183, 89);
  }
  .name_of_profile {
    letter-spacing: -0.5px;
    margin-bottom: 2px;
    font-family: IBMMed;
    font-size: 16px;
  }
  .username_profile {
    color: rgb(131, 146, 165);
    margin-bottom: 15px;
    font-family: IBMReg;
    font-size: 14px;
  }
  .name_username_editbtn_tagline_count_container {
    margin: 10px 0;
  }
  .msg_btn {
  }
  .edit_profile_btn {
    margin-left: 10px;
  }
  .msg_btn {
  }
  .two_btn_cotainer {
    margin-bottom: 25px;
    display: flex;
  }
  .tagline_content {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
    line-height: 14px;
    width: 90%;
  }
  .read_text_more_left_side {
    font-size: 13px;
  }
  .post_follow_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .post_container {
    display: flex;
    width: 33.33%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .number_text {
    font-size: 17px;
    margin: 0;
  }
  .title_number_text {
    display: block;
    margin-bottom: 0px;
    text-transform: uppercase;

    font-size: 10px;
    font-weight: 500;

    letter-spacing: 0.5px;
  }
  .univercity_contianer {
  }
  .heading_university_text {
    display: block;
    font-size: 10px;
    margin-block-start: 1.67em;
    /* margin-block-end: 1.67em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .univercity_under_contianer {
    font-size: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
    list-style: none;
    margin: 0;
  }
  .univercity_under_container1 {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }
  .ox_foard_text {
    display: block;

    padding: 3px 5px 1px 5px;

    border-radius: 0.25rem;
    text-decoration: none;
    font-size: 11px;
  }
  .score_text {
    display: block;
    font-size: 10px;
    margin-block-start: 12px;
    /* margin-block-end: 1.67em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .progressbar_contianer {
    display: flex;
    justify-content: flex-start;
  }
  .progress_per_contianer {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5% 0 0;
  }
  .exam_text {
    margin-top: 10%;

    font-family: IBMMed;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .same_btn_profile {
    font-size: 14px;
    padding: 5px;

    line-height: 1.5;

    width: 40%;
  }
  .contact_icon_title_contianer {
    display: flex;
    align-items: center;
    margin: 0 0 3% 0;
  }
  .icon_cotanct_info {
    font-size: 17px;
  }
  .data_contact_info {
    font-size: 12px;

    margin-left: 8px;
  }
}

@media screen and (min-width: 1200px) {
  .anothrr_text_description {
    margin: 0px 0 5px;
    font-size: 13px;
    line-height: 16px;
  }
  .univercity_under_contianer {
    font-size: 13px;
  }
  .two_btn_cotainer {
    margin-bottom: 12px;
    display: flex;
  }
  .profile_circle_container {
    margin-top: 30px;
  }
  .read_text_more_left_side {
    font-size: 13px;
  }
  .one_under_main_container {
    position: fixed;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    padding: 0 1% 6%;
    bottom: 5px;
    width: 273px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #0000002b;
    border-radius: 5px;
    /* border: 1px solid #00000057; */
    /* background: #fff;

    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
  }
  .same_btn_profile {
    font-size: 13.5px;
  }
  .tagline_content {
    font-size: 13px;
    line-height: 17px;
  }
  .number_text {
    font-size: 18px;
    margin: 0;
  }
  .title_number_text {
    font-size: 10px;
  }
  .heading_university_text {
    font-size: 13px;
    margin-block-start: 10px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .ox_foard_text {
    font-size: 12px;
  }
  .score_text {
    font-size: 13px;
    margin-block-start: 10px;
    margin-block-end: 4px;
  }
  .exam_text {
    font-size: 11px;
  }
  .icon_cotanct_info {
    font-size: 19px;
  }
  .data_contact_info {
    font-size: 13px;
    line-height: 16px;
  }
}

@media (min-width: 1400px) {
  .one_under_main_container {
    width: 322px;
  }
  .one_under_main_container {
    position: fixed;
    height: calc(100vh - 100px);
  }
}

.conectin_under_right_side_contianer {
  margin: 4% 0 0 0;
}
.people_main_title_text {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: IBMReg;
  color: var(--theme-blue);
  margin: 0 0 4% 0;
}
.dicover_by_postion_container {
  margin: 4% 0 0 0;
}

/* events title subtitle */

.profile_event_title_sub_title_container {
  display: flex;
  flex-direction: row;

  outline: none;
  border: 0;
  background: transparent;
}
.event_box_img_contianer {
  width: 70px;
  height: 40px;
  display: flex;
  flex-direction: row;
}
.event_img_popular_self {
  width: 100%;
}
.event_title_text {
  font-weight: 500;
  margin-bottom: 0px;
  color: var(--theme-blue);
  font-size: 14px;
  font-family: IBMMed;
  text-transform: capitalize;
}
.event_sub_title_text {
  font-size: 12px;
  font-family: IBMReg;
  color: rgb(131, 146, 165);
}
.event_title_text_subtitle_contianer {
  display: flex;
  flex-direction: column;
  padding: 0% 0 0 2%;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 0 3%;
}

@media (max-width: 767px) {
  .event_sub_title_text {
    margin: 0;
  }
  .profile_event_title_sub_title_container {
    margin: 0px 0 15px 0%;
    align-items: center;
  }
  .event_title_text {
    font-size: 13px;
  }
  .event_box_img_contianer {
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .event_sub_title_text {
    margin: 0;
    font-size: 14px;
  }
  .profile_event_title_sub_title_container {
    margin: 0px 0 15px 0%;
    align-items: center;
  }
  .event_title_text {
    font-size: 15px;
  }
  .event_box_img_contianer {
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 991px) {
  .event_sub_title_text {
    margin: 0;
    font-size: 13px;
  }
  .profile_event_title_sub_title_container {
    margin: 0px 0 15px 0%;
    align-items: center;
  }
  .event_title_text {
    font-size: 14px;
  }
  .event_box_img_contianer {
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .event_sub_title_text {
    margin: 0;
    font-size: 13px;
  }
  .profile_event_title_sub_title_container {
    margin: 0px 0 15px 0%;
    align-items: center;
  }
  .event_title_text {
    font-size: 14px;
  }
  .event_box_img_contianer {
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
  }
}

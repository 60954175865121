::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.274);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.274);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.274);
}
.forgot_main_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
}
.forgot_under_contianer {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgot_img {
  width: 80%;
  margin: 0 0 3% 0;
}
.reset_content_contianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}
.reset_pwd_text {
  font-size: 24px;
  font-family: IBMMed;
}
.reset_extra_content {
  color: rgb(131, 146, 165);
  margin-bottom: 40px;
  font-family: IBMReg;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
}

.text_input_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.reset_input {
  outline: none;
  border: 0;
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: IBMReg;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.reset_btn_self:active {
  color: #fff;
}
.reset_btn_self:hover {
  color: rgb(175, 169, 169);
}
.reset_btn_self {
  min-width: 140px;
  text-decoration: none;
  font-family: IBMReg;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(4, 40, 147);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin: 0 0 0 12px;
}

@media screen and (max-width: 767px) {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.274);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.274);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.274);
  }
  .forgot_main_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }
  .forgot_under_contianer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 3% 0 3%;
    padding: 0 5% 0 5%;
  }
  .forgot_img {
    width: 80%;
    margin: 0 0 3% 0;
  }
  .reset_content_contianer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .reset_pwd_text {
    font-size: 20px;
    font-family: IBMMed;
  }
  .reset_extra_content {
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
    text-align: center;
    line-height: 16px;
    font-size: 11px;
  }

  .text_input_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .reset_input {
    outline: none;
    border: 0;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: IBMReg;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .reset_btn_self {
    min-width: 100%;
    font-family: IBMReg;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid rgb(4, 40, 147);
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    margin: 7% 0 6% 0px;
  }
}

@media screen and (min-width: 768px) {
  .forgot_main_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }
  .forgot_under_contianer {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .forgot_img {
    width: 95%;
    margin: 0 0 3% 0;
  }
  .reset_content_contianer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .reset_pwd_text {
    font-size: 20px;
    font-family: IBMMed;
  }
  .reset_extra_content {
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
    text-align: center;
    line-height: 16px;
    font-size: 11px;
  }

  .text_input_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .reset_input {
    outline: none;
    border: 0;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: IBMReg;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .reset_btn_self {
    min-width: 100%;
    font-family: IBMReg;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid rgb(4, 40, 147);
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    margin: 7% 0 6% 0px;
  }
}

@media screen and (min-width: 991px) {
  .forgot_main_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }
  .forgot_under_contianer {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .forgot_img {
    width: 65%;
    margin: 0 0 3% 0;
  }
  .reset_content_contianer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
  }
  .reset_pwd_text {
    font-size: 24px;
    font-family: IBMMed;
  }
  .reset_extra_content {
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
    text-align: center;
    line-height: 16px;
    font-size: 14px;
  }

  .text_input_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .reset_input {
    outline: none;
    border: 0;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: IBMReg;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .reset_btn_self {
    min-width: 140px;

    font-family: IBMReg;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;
    border: 1px solid rgb(4, 40, 147);
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    margin: 0 0 0 12px;
  }
}

@media screen and (min-width: 1200px) {
  .forgot_img {
    width: 75%;
    margin: 0 0 3% 0;
  }
}

.apk_main_content_container {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
}
.apk_under_content_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.all_check_apk_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.all_title_check_apk_content > h6 {
  font-size: 18px;
  font-family: "IBMReg";
  color: var(--theme-blue);
}
.check_box_text_container {
  display: flex;
  align-items: center;
  width: 50%;
}

@media (max-width: 767px) {
  .all_check_apk_content {
    display: flex;
    flex-direction: column;
  }
  .check_box_text_container {
    width: 100%;
  }
}

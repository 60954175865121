.modal_main_container {
  min-width: 500px;
  max-width: 600px;
  max-height: 400px;
}
.ReactModal__Overlay {
  z-index: 5;
}
.heading_title_closebtn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000001f;
}
.heading_title {
  font-size: 20px;
  color: #1a1a1abd;
  font-family: IBMMed;
  margin: 0;
}

.close_btn_contianer {
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  border: 0;
}
.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 85%) !important;
}
.close_btn_contianer:active {
  background: #c9bebe47;
}
.close_btn_contianer:hover {
  background: #c9bebe47;
}
.modal_posttitle_profiletext_another_container {
}
.modal_create_post_contaier {
  display: flex;
  align-items: center;
  padding: 3% 0 3% 0;
}
.img_upload_contianer {
  display: flex;
  justify-content: center;
  /* width: 10%; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
  background: #f5f5f5;
}
.upload_btn {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.uplaod_perosna_name {
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  color: var(--theme-blue);
  font-family: IBMMed;
}
.text_area_input {
  width: 100%;
  border: 0;
  outline: none;
  font-family: IBMReg;
  color: #000000c7;
}
.video_gallery_document_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 15px;
}
.three_btn_container_upload {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery_contianre {
  outline: none;
  border: 0;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery_contianre:hover {
  background: #c9bebe47;
  border-radius: 5px;
}
.icon_img_self {
  font-size: 20px;
  color: #00000094;
}
.post_cancel_btn_contaienr {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.cancel_btn_contact {
  outline: none;
  border: 0;
  background: #00000026;
  border-radius: 5px;
  width: 65px;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: IBMReg;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.post_container_btn {
  outline: none;
  border: 0;
  background: #00000026;
  border-radius: 5px;
  width: 65px;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: IBMReg;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  transition: var(--transition);
}
.post_container_btn:active {
  font-family: IBMBold;
}
.post_container_btn:hover,
.cancel_btn_contact:hover {
  font-family: IBMBold;
}
.img_flex_wrap_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 3% 0;
}
.uplaod_moadl_pic {
  margin: 1% 2% 3% 0;
}
.img_pic_container {
  position: relative;
  margin: 0 2% 0 0;
}
.cancel_pic_btn {
  position: absolute;
  right: 4%;
  outline: none;
  border: 0;
  background: #ffffff91;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4%;
}
.cancel_img {
  color: rgba(0, 0, 0, 0.479);
}
.blank_img {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
  position: relative;
  border: 2px dashed rgba(72, 94, 144, 0.16);
}
.upload_item {
  color: rgba(0, 0, 0, 0.479);
  font-size: 20px;
}
.upload_text_item {
  color: rgba(0, 0, 0, 0.479);
  font-size: 10px;
  font-family: IBMBold;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .gallery_contianre {
    width: 30px;
    height: 30px;
  }
  .img_upload_contianer {
    
    width: 30px;
    height: 30px;
  }
  .heading_title_closebtn_container {
    padding: 0 0 10px;
  }
  .close_btn_contianer:hover {
    background: #c9bebe47;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .modal_main_container {
    min-width: 220px;
    max-width: 225px;
    max-height: 400px;
  }
  .heading_title {
    font-size: 15px;
    color: #1a1a1abd;
    font-family: IBMMed;
  }
  .close_btn_contianer {
    width: 30px;
    height: 30px;
    margin: 0 0 0% 0;
  }

  .modal_create_post_contaier {
    padding: 5% 0;
    flex-direction: row;
    align-items: center;
  }
  .uplaod_perosna_name {
    margin-left: 10px;
    font-size: 13px;
    margin: 0 0 0 7px;
  }
  .text_area_input {
    font-size: 13px;
  }
  .uplaod_moadl_pic {
    width: 100px;
    height: 80px;
    margin: 1% 2% 3% 0;
  }
  .cancel_pic_btn {
    height: 15px;
    width: 15px;
  }
  .cancel_img {
    font-size: 11px;
  }
  .icon_img_self {
    font-size: 17px;
    color: #00000094;
  }
  .cancel_btn_contact {
    outline: none;
    border: 0;
    background: #00000026;
    border-radius: 5px;
    width: 57px;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: IBMReg;
    text-align: center;

    height: 32px;
  }
  .post_container_btn {
    outline: none;
    border: 0;
    background: #00000026;
    border-radius: 5px;
    width: 50px;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: IBMReg;
    text-align: center;

    height: 32px;
  }
}

@media screen and (min-width: 768px) {
  .heading_title {
    font-size: 18px;
    color: #1a1a1abd;
    font-family: IBMMed;
  }
  .close_btn_contianer {
    outline: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    border: 0;
    margin: 0 0 2% 0;
  }
  .modal_main_container {
    min-width: 430px;
    max-width: 450px;
    max-height: 390px;
  }
  .uplaod_moadl_pic {
    margin: 1% 2% 3% 0;
  }
  .cancel_pic_btn {
    height: 20px;
    width: 20px;
  }
  .cancel_img {
    font-size: 13px;
  }
  .cancel_btn_contact {
    width: 62px;
    font-size: 12px;

    height: 35px;
  }
  .post_container_btn {
    width: 53px;
    font-size: 12px;

    height: 35px;
  }
}

@media screen and (min-width: 991px) {
  .heading_title_closebtn_container {
    padding: 0 0 15px;
  }
  .close_self {
    font-size: 23px;
  }
  .close_btn_contianer {
    outline: none;
    width: 34px;
    height: 33px;
    border-radius: 50%;
    background: transparent;
    border: 0;
    margin: 0 0 0% 3%;
  }
  .img_pic_container {
    position: relative;
    margin: 0 2% 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .uplaod_perosna_name {
    margin-left: 10px;
    font-size: 16px;
  }
  .uplaod_moadl_pic {
    margin: 1% 2% 3% 0;
    width: 140px;
    height: 110px;
  }
  .icon_img_self {
    font-size: 21px;
    color: #00000094;
  }
  .close_btn_contianer {
    margin: 0 0 0% 0;
  }
  .img_upload_contianer {
    /* width: 10%; */
    width: 35px;
    height: 35px;
  }
}

.main_connection_contianer {
  position: relative;
}
.connection_under_main_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2% 0 0 0;
}
.conn_title_search_bar_contianer {
  display: flex;
  justify-content: space-between;
  width: 86%;
  margin: 0 auto 0 auto;
  padding: 2% 0 2% 0;
  align-items: center;
}
.conn_title {
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  font-size: 21px;
  color: var(--theme-blue);
  font-family: "IBMMed";
  letter-spacing: 0.5px;
}
.connec_search_bar_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
}
.input_search_conn {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 0;
  outline: none;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.btn_conn_search {
  border: 0;
  outline: none;
  background: transparent;
  margin: 0 1% 0 0;
}
.search_conn_self {
  font-size: 25px;

  color: rgb(131, 146, 165);
}
.search_conn_self:hover {
  color: rgb(27, 46, 75);
}
.conn_line {
  margin: 0;
}
.left_side_connection_contianer {
  width: 70%;
  /* background-color: red; */
}
.right_side_connection_contianer {
  width: 30%;
  /* background-color: green; */
}
.filter_container_univercity {
  display: flex;

  width: 86%;
  margin: -1.5% auto 3% auto;
  padding: 0% 0 0% 0;
  align-items: center;
}
.filter_btn_univercity {
  border: 1px solid rgb(27, 46, 75);
  outline: none;
  color: rgb(27, 46, 75);
  font-size: 17px;
  font-family: IBMMed;
  background-color: transparent;
  width: 10%;
  height: 34px;
  border-radius: 4px;
  letter-spacing: 1px;
}
.filter_btn_univercity:hover {
  background-color: rgb(27, 46, 75);
  color: #fff;
}
.group_title {
  font-family: "IBMMed";
}
.member_count_text {
  font-family: "IBMReg";
}
.connection_text_mutual {
  font-family: "IBMReg";
}
.same_btn_group {
  font-family: "IBMReg";
}
.floating_shortlisted_icon{
  display: inline-flex;
  position: fixed;
  right: 5%;
  bottom: 6%;
}
.floating_shortlisted_icon > button{
  font-family: 'IBMBold';
  font-size: 19px;
  background: #a4a9a9a9;
  padding: 10px 10px;
  opacity: .8;
  color: var(--theme-blue);
  border-radius: 6px;
  outline: none;
  border: 0;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 75%);
}
.floating_shortlisted_icon > button:hover{
  background: var(--theme-blue);
  color: #ffffff;
  opacity: 1;
}
.floating_shortlisted_icon > button > span{
 position: absolute; 
 top: -10px;
 background: red;
 /* padding: 2px 4px; */
 
 border-radius: 50%;
 display: flex;
 color: #fff;
 right: -13px;
 font-size: 14px;
 align-items: center;
 justify-content: center;
}
.one_number_count{
  height: 25px;
  width: 25px;
}
.more_than_no_count{
  height: 22px;
  width: 22px;
}

@media (max-width: 767px) {
  .more_than_no_count{
    height: 22px;
    width: 22px;
  }
  .one_number_count{
    height: 18px;
    width: 18px;
  }
  .floating_shortlisted_icon > button{
    font-size: 15px;
    padding:5px;
  }
  .floating_shortlisted_icon > button > span{
   
    top: -8px;
    
    /* padding: 2px 4px; */
    
   
    right: -10px;
    font-size: 10px;
   
   }
  .filter_container_univercity {
    width: 90%;
    margin: -5.5% auto 6% auto;
  }
  .filter_btn_univercity {
    width: 27%;
    font-size: 14px;
    height: 33px;
  }
  .conn_title_search_bar_contianer {
    display: flex;

    width: 90%;
    padding: 10% 0 5% 0;

    flex-direction: column;
  }
  .connec_search_bar_contianer {
    margin: 20px 0;
    width: 100%;
  }
  .connection_under_main_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5% 0 0 0;
  }
  .left_side_connection_contianer {
    width: 100%;
  }
  .conectin_under_left_side_contianer {
    margin: 4% auto 0;
    width: 90%;
  }
  .group_contianer {
    width: 100%;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .group_box_container {
    justify-content: flex-start;
    padding: 3%;
    width: 46.5%;
    margin: 0 5px 10px 5px;
  }
  .group_pic_self {
    width: 45%;
  }
  .group_title {
    font-size: 13px;
    line-height: 15px;
  }
  .member_count_text {
    font-size: 10px;
    margin: 0;
  }
  .avtar_group_img_contianer {
    margin: 0 0 5% 0;
  }
  .avatar_img {
    width: 18%;

    margin: 0 -6% 0 0%;
  }
  .connection_text_mutual {
    font-size: 10px;
    margin: 0;
  }
  .avtar_img_text_container {
    margin: 1px 0px 11px 0;
  }
  .pagination_event_tab_container {
    margin: 19px auto 1rem auto;
    width: 100%;
    display: flex;
    place-items: normal;
    justify-content: center;
  }
  .right_side_connection_contianer {
    width: 87%;

    margin: auto;
  }
}

@media (min-width: 768px) {
  .one_number_count{
    height: 18px;
    width: 18px;
  }
  .floating_shortlisted_icon > button {
    
    font-size: 14px;
    
    padding: 6px 7px;
  
    
    border-radius: 5px;

}
.floating_shortlisted_icon > button > span {
  
  top: -8px;
  
  padding: 2px 4px;
  
  
  right: -10px;
  font-size: 10px;

}
  .filter_container_univercity {
    width: 662px;
    margin: -1.5% auto 4% auto;
  }
  .filter_btn_univercity {
    width: 23%;
    font-size: 19px;
    height: 40px;
  }
  .conn_title_search_bar_contianer {
    display: flex;
    width: 768px;
    padding: 5% 0 2% 0;
    flex-direction: column;
  }
  .conn_title {
    font-size: 23px;
  }
  .connec_search_bar_contianer {
    margin: 20px auto;
    width: 660px;
  }
  .connection_under_main_container {
    width: 768px;
    display: flex;
    flex-direction: column;
    margin: 1% auto 0 auto;
  }
  .left_side_connection_contianer {
    width: 100%;
  }
  .conectin_under_left_side_contianer {
    margin: 4% auto 0;
    width: 90%;
  }
  .group_contianer {
    width: 100%;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .group_box_container {
    justify-content: flex-start;
    padding: 3%;
    width: 31.5%;
    margin: 0 6px 12px 6px;
  }
  .group_pic_self {
    width: 30%;
  }
  .group_title {
    font-size: 16px;
    line-height: 17px;
  }
  .member_count_text {
    font-size: 13px;
    margin: 0;
  }
  .avtar_group_img_contianer {
    margin: 0px 10px 5% 5px;
  }
  .avatar_img {
    width: 18%;
    margin: 0 -7% 0 0%;
  }
  .connection_text_mutual {
    font-size: 13px;
    margin: 0;
  }
  .avtar_img_text_container {
    margin: 7px 0px 12px 0;
  }
  .pagination_event_tab_container {
    margin: 15px auto 1rem auto;
    width: 100%;
    display: flex;
    place-items: normal;
    justify-content: center;
  }
  .right_side_connection_contianer {
    width: 87%;

    margin: auto;
  }
}

@media (min-width: 991px) {
  .floating_shortlisted_icon > button {
    
    font-size: 14px;
    
    padding: 6px 7px;
  
    
    border-radius: 5px;

}
.floating_shortlisted_icon > button > span {
  
  top: -8px;
  
  padding: 2px 4px;
  
  
  right: -10px;
  font-size: 10px;

}
  .group_pic_self {
    width: 27%;
  }
  .group_box_container {
    justify-content: flex-start;
    padding: 3%;
    width: 46.5%;
    margin: 0 10px 21px 10px;
  }
  .main_connection_contianer {
  }
  .connection_under_main_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 2% 0 0 0;
    align-items: flex-start;
  }
  .conn_title_search_bar_contianer {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 2% 0 2% 0;
    align-items: center;
    flex-direction: row;
  }
  .conn_title {
    letter-spacing: -0.5px;
    margin-bottom: 0px;
    font-size: 24px;
    color: var(--theme-blue);
    font-family: "IBMMed";
    letter-spacing: 0.5px;
  }
  .connec_search_bar_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    width: 30%;
    margin: 20px 0;
  }

  .input_search_conn {
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 0;
    outline: none;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .btn_conn_search {
    border: 0;
    outline: none;
    background: transparent;
    margin: 0 1% 0 0;
  }
  .search_conn_self {
    font-size: 25px;

    color: rgb(131, 146, 165);
  }
  .search_conn_self:hover {
    color: rgb(27, 46, 75);
  }
  .conn_line {
    margin: 0;
  }
  .left_side_connection_contianer {
    width: 70%;
    /* background-color: red; */
  }
  .right_side_connection_contianer {
    width: 30%;
    margin: 17px auto;

    /* background-color: green; */
  }
  .filter_container_univercity {
    display: flex;

    width: 90%;
    margin: -1.5% auto 3% auto;
    padding: 0% 0 0% 0;
    align-items: center;
  }
  .filter_btn_univercity {
    border: 1px solid rgb(27, 46, 75);
    outline: none;
    color: rgb(27, 46, 75);
    font-size: 17px;
    font-family: IBMMed;
    background-color: transparent;
    width: 20%;
    height: 40px;

    border-radius: 4px;
    letter-spacing: 1px;
  }
  .filter_btn_univercity:hover {
    background-color: rgb(27, 46, 75);
    color: #fff;
  }
  .group_title {
    font-size: 21px;
    line-height: 17px;
  }
  .member_count_text {
    font-size: 18px;
    margin: 0;
  }
  .connection_text_mutual {
    font-size: 17.5px;
  }
  .same_btn_group {
    padding: 9px 0px;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .more_than_no_count{
    height: 30px;
    width: 30px;
  }
  .one_number_count{
    height: 25px;
    width: 25px;
  }
  .floating_shortlisted_icon > button {
    font-size: 19px;
    padding: 10px 10px;
    border-radius: 5px;
}
.floating_shortlisted_icon > button > span {
  top: -11px;
  padding: 3px 5px;
  right: -15px;
  font-size: 14px;

}
  .conectin_under_left_side_contianer {
    margin: 4% auto 0;
    width: 100%;
  }
  .avtar_img_text_container {
    margin: 14px 0px 19px 0;
  }
  .group_pic_self {
    width: 65px;
  }
  .group_box_container {
    padding: 2.5%;
    width: 30.5%;
    margin: 0 8px 17px 8px;
  }
  .main_connection_contianer {
  }
  .connection_under_main_container {
    width: 1100px;

    margin: 1% auto 0;
  }
  .conn_title_search_bar_contianer {
    width: 1100px;

    padding: 2% 0 1% 0;
  }
  .conn_title {
    letter-spacing: -0.5px;
    margin-bottom: 0px;
    font-size: 24px;
    color: var(--theme-blue);
    font-family: "IBMMed";
    letter-spacing: 0.5px;
  }
  .connec_search_bar_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    width: 30%;
  }

  .input_search_conn {
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
  }
  .btn_conn_search {
    border: 0;
    outline: none;
    background: transparent;
    margin: 0 1% 0 0;
  }
  .search_conn_self {
    font-size: 25px;

    color: rgb(131, 146, 165);
  }
  .search_conn_self:hover {
  }
  .conn_line {
    margin: 0;
  }
  .left_side_connection_contianer {
    width: 70%;
  }
  .right_side_connection_contianer {
    width: 30%;
    margin: 17px auto;
  }
  .filter_container_univercity {
    display: flex;

    width: 1100px;
    margin: -1.5% auto 3% auto;
    padding: 0% 0 0% 0;
    align-items: center;
  }
  .filter_btn_univercity {
    font-size: 20px;
    width: 14%;
    height: 40px;
    border-radius: 4px;
    letter-spacing: 1px;
  }

  .group_title {
    font-size: 18px;
    line-height: 21px;
  }
  .member_count_text {
    font-size: 14px;
    margin: 0;
  }
  .connection_text_mutual {
    font-size: 13.5px;
  }
  .same_btn_group {
    padding: 6px 0px;
    font-size: 17px;
  }
  .avtar_group_img_contianer {
    margin: 0px 11px 5% 0px;
  }
  .avatar_img {
    width: 31px;
    margin: 0 -7% 0 0%;
  }
}

@media (min-width: 1400px) {
  .one_number_count{
    height: 28px;
    width: 28px;
  }
  .conectin_under_left_side_contianer {
    margin: 4% auto 0;
    width: 100%;
  }
  .avtar_img_text_container {
    margin: 14px 0px 19px 0;
  }
  .group_pic_self {
    width: 65px;
  }
  .group_box_container {
    padding: 2.5%;
    width: 30.5%;
    margin: 0 8px 17px 8px;
  }
  .main_connection_contianer {
  }
  .connection_under_main_container {
    width: 1200px;

    margin: 1% auto 0;
  }
  .conn_title_search_bar_contianer {
    width: 1200px;

    padding: 2% 0 1% 0;
  }
  .conn_title {
    letter-spacing: -0.5px;
    margin-bottom: 0px;
    font-size: 24px;
    color: var(--theme-blue);
    font-family: "IBMMed";
    letter-spacing: 0.5px;
  }
  .connec_search_bar_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    width: 30%;
  }

  .input_search_conn {
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
  }
  .btn_conn_search {
    border: 0;
    outline: none;
    background: transparent;
    margin: 0 1% 0 0;
  }
  .search_conn_self {
    font-size: 25px;

    color: rgb(131, 146, 165);
  }
  .search_conn_self:hover {
  }
  .conn_line {
    margin: 0;
  }
  .left_side_connection_contianer {
    width: 70%;
  }
  .right_side_connection_contianer {
    width: 30%;
    margin: 17px auto;
  }
  .filter_container_univercity {
    display: flex;

    width: 1200px;
    margin: -1.5% auto 3% auto;
    padding: 0% 0 0% 0;
    align-items: center;
  }
  .filter_btn_univercity {
    font-size: 17px;

    width: 10%;
    height: 34px;
    border-radius: 4px;
    letter-spacing: 1px;
  }

  .group_title {
    font-size: 18px;
    line-height: 21px;
  }
  .member_count_text {
    font-size: 14px;
    margin: 0;
  }
  .connection_text_mutual {
    font-size: 13.5px;
  }
  .same_btn_group {
    padding: 6px 0px;
    font-size: 17px;
  }
  .avtar_group_img_contianer {
    margin: 0px 11px 5% 0px;
  }
  .avatar_img {
    width: 31px;
    margin: 0 -7% 0 0%;
  }
}

.main_welcome_page_main_container {
}
.welcome_page_under_main_container {
  margin: 0 auto;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.welcome_page_robo_icon_all_content_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.robo_icon_container {
  width: 150px;
}

.robo_icon_container > img {
  width: 100%;
}
.content_text_container_wc {
  text-align: center;
}
.title_for_welcome_text {
  font-family: "IBMBOLD";
  margin: 0px 0 25px;
  font-size: 25px;
  text-align: center;
  color: var(--theme-blue);
  line-height: 32px;
}
.subtitle_for_wc_text {
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: var(--theme-blue);
}

.ready_text {
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--theme-blue);
}
.sure_btn_self {
  border: 0;
  background-color: var(--theme-blue);
  color: #fff;
  font-family: "IBMSemBold";
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 320px;
  height: 60px;
  border-radius: 3px;
  font-size: 18px;
}
.later_btn_self {
  background: transparent;
  border: 0;
  font-family: "IBMSemBold";
  color: var(--theme-blue);
  font-size: 18px;
}
.sure_btn_self:hover {
  background: #fff;
  color: var(--theme-blue);
  border: 1px solid var(--theme-blue);
}
.later_btn_self:hover {
  text-decoration: underline;
}

@media (max-width: 299px) {
  .welcome_page_robo_icon_all_content_container {
    gap: 30px;
  }
  .welcome_page_under_main_container {
    width: 94%;
  }
  .robo_icon_container {
    width: 100px;
  }
  .title_for_welcome_text {
    font-family: "IBMBOLD";
    margin: 0px 0 25px;
    font-size: 14px;
    text-align: center;

    line-height: 18px;
  }
  .subtitle_for_wc_text {
    font-family: IBMMed;
    font-size: 11px;
    line-height: 15px;
  }
  .ready_text {
    font-family: Ibmreg;
    font-size: 11px;
    line-height: 16px;
  }
  .sure_btn_self {
    font-size: 13px;
    width: 94%;
    height: 45px;
    letter-spacing: 1px;
  }
  .later_btn_self {
    font-size: 13px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .welcome_page_robo_icon_all_content_container {
    gap: 30px;
  }
  .welcome_page_under_main_container {
    width: 290px;
  }
  .robo_icon_container {
    width: 120px;
  }
  .title_for_welcome_text {
    font-family: "IBMBOLD";
    margin: 0px 0 19px;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
  }
  .subtitle_for_wc_text {
    font-size: 13px;
    line-height: 16px;
  }
  .ready_text {
    font-family: Ibmreg;
    font-size: 13px;
    line-height: 18px;
  }
  .sure_btn_self {
    font-size: 14px;
    width: 94%;
    height: 50px;
    letter-spacing: 1px;
  }
  .later_btn_self {
    font-size: 13.5px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .welcome_page_robo_icon_all_content_container {
    gap: 30px;
  }
  .welcome_page_under_main_container {
    width: 390px;
  }
  .robo_icon_container {
    width: 130px;
  }
  .title_for_welcome_text {
    font-family: "IBMBOLD";
    margin: 0px 0 19px;
    font-size: 17px;
    text-align: center;
    line-height: 26px;
  }
  .subtitle_for_wc_text {
    font-size: 13px;
    line-height: 16px;
  }
  .ready_text {
    font-family: Ibmreg;
    font-size: 13px;
    line-height: 18px;
  }
  .sure_btn_self {
    font-size: 14px;
    width: 65%;
    height: 50px;
    letter-spacing: 1px;
  }
  .later_btn_self {
    font-size: 13.5px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .welcome_page_robo_icon_all_content_container {
    gap: 30px;
  }
  .welcome_page_under_main_container {
    width: 700px;
  }
  .robo_icon_container {
    width: 115px;
  }
  .title_for_welcome_text {
    margin: 0px 0 20px;
    font-size: 17px;

    line-height: 23px;
  }
  .subtitle_for_wc_text {
    font-size: 12px;
    line-height: 18px;
  }
  .ready_text {
    font-size: 12px;
    line-height: 15px;
  }
  .sure_btn_self {
    width: 280px;
    height: 50px;
    border-radius: 3px;
    font-size: 14px;
  }
  .later_btn_self {
    font-size: 14px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .welcome_page_robo_icon_all_content_container {
    gap: 30px;
  }
  .welcome_page_under_main_container {
    width: 930px;
  }
  .robo_icon_container {
    width: 115px;
  }
  .title_for_welcome_text {
    margin: 0px 0 20px;
    font-size: 18px;
    line-height: 25px;
  }
  .subtitle_for_wc_text {
    font-size: 13px;
    line-height: 21px;
  }
  .ready_text {
    font-size: 12px;
    line-height: 15px;
  }
  .sure_btn_self {
    width: 290px;
    height: 55px;
    border-radius: 3px;
    font-size: 15px;
  }
  .later_btn_self {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .welcome_page_under_main_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .welcome_page_under_main_container {
    width: 1300px;
  }
}

.select_box_ {
  box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -webkit-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -moz-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  outline: none;
  background: rgba(249, 249, 249, 0.82);
  border: 0;
  height: 45px;
  border-radius: 7px;
  padding: 10px 9% 10px 2%;
  margin: 4px 0 0 0;
  font-family: "IBMMed";

  font-size: 18px;
  width: 100%;
  color: rgba(27, 46, 75, 1);
  cursor: pointer;
  /* transition: all 0.3s; */

  /* defalut remove aerrow */

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../assets/down.png");
  background-repeat: no-repeat;

  background-size: 14px;
  padding-right: 20px;
  background-position: center right 12px;
}

.select_input_text_contianer {
  width: 45%;
  margin: 0 0 4% 0;
}
.select_label_text {
  font-size: 19px;
  font-family: "IBMReg";
  color: rgba(27, 46, 75, 1);
}
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}
/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.select_box_:focus {
  background-color: #ffffff;
  border-color: #333;
  border: 2px solid var(--theme-blue);
  border-radius: 8px;
}

@media (max-width: 767px) {
  select option {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
  }
  .select_input_text_contianer {
    width: 100%;
    margin: 0 0 5% 0;
  }
  .select_label_text {
    font-size: 14px;
  }
  .select_box_ {
    height: 42px;

    padding: 10px 9% 10px 2%;
    margin: 2px 0 0 0;
    background-position: center right 12px;
    font-size: 13px;
    width: 100%;
    background-size: 11px;
  }
  .select_box_ > option {
    font-size: 13px;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  select option {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
  }

  .select_box_ {
    height: 42px;
    background-size: 13px;
    padding: 10px 9% 10px 4%;
    margin: 2px 0 0 0;
    background-position: center right 13px;
    font-size: 13px;
    width: 100%;
  }
  .select_box_ > option {
    font-size: 14px;
    cursor: pointer;
  }
  .select_input_text_contianer {
    width: 46%;
    margin: 0 0 4% 0;
  }
  .select_label_text {
    font-size: 14px;
  }
}

@media (min-width: 991px) {
  .select_label_text {
    font-size: 16px;
  }
  .select_box_ {
    height: 50px;
    padding: 10px 9% 10px 4%;
    margin: 2px 0 0 0;
    background-position: center right 13px;
    font-size: 15px;

    width: 100%;
  }
}

@media (min-width: 1200px) {
  .select_box_ > option {
    font-size: 14px;
    cursor: pointer;
  }
}

.logo_dwec_img {
  width: 50px;
}
.navbar {
  position: sticky;
  top: 0;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 5;
  box-shadow: 0px -3px 6px 1px rgb(0 0 0 / 75%);
  /* background: linear-gradient(92deg, #c9d8f3, #e5cacb);
  background-size: 120% 120%;

  animation: gradient-animation 2s ease infinite; */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 767px) {
  .logo_dwec_img {
    width: 50px;
  }
}

@media screen and (min-width: 768px) {
  .logo_dwec_img {
    width: 50px;
  }
}

@media screen and (min-width: 991px) {
  .logo_dwec_img {
    width: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .logo_dwec_img {
    width: 50px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');



@font-face {
  font-family: IBMReg;
  src: url("../../assets/fonts/IBMPlexSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: IBMLight;
  src: url("../../assets/fonts/IBMPlexSans-Light.ttf");
  font-weight: 300;

}

@font-face {
  font-family: IBMThin;
  src: url("../../assets/fonts/IBMPlexSans-Thin.ttf");
  font-weight: 100;

}

@font-face {
  font-family: IBMMed;
  src: url("../../assets/fonts/IBMPlexSans-Medium.ttf");
  font-weight: 500;

}

@font-face {
  font-family: IBMSemBold;
  src: url("../../assets/fonts/IBMPlexSans-SemiBold.ttf");
  font-weight: 600;

}

@font-face {
  font-family: IBMBold;
  src: url("../../assets/fonts/IBMPlexSans-Bold.ttf");
  font-weight: 700;

}

@font-face {
  font-family: InterReg;
  src: url("../../assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: InterLight;
  src: url("../../assets/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: InterThin;
  src: url("../../assets/fonts/Inter-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: InterMed;
  src: url("../../assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: InterSemBold;
  src: url("../../assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: InterBold;
  src: url("../../assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}
.label_input_text_contianer {
  width: 45%;
  margin: 0 0 4% 0;
}
.input_dialog_style {
  box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -webkit-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -moz-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  outline: none;
  background: rgba(249, 249, 249, 0.82);
  border: 0;
  height: 45px;
  border-radius: 7px;
  padding: 10px 9% 10px 9%;
  margin: 4px 0 0 0;
  font-family: "IBMMed";
  font-weight: 400;
  font-size: 18px;
  width: 100%;
  color: rgba(27, 46, 75, 1);
  cursor: pointer;
}
.input_label_text {
  font-size: 19px;
  font-family: "IBMReg";
  color: rgba(27, 46, 75, 1);
}
.input_textarea_container {
  width: 100%;
  margin: 0 auto 4% auto;
}
.input_dialog_style:focus {
  background-color: #ffffff;
  border-color: #333;
  border: 2px solid var(--theme-blue);
}

/* extra eye input pwd */
.pwd_cust_contianer {
  width: 45%;
  margin: 0 0 3% 0;
}
.pwd_btn_eye_container {
  -webkit-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -moz-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  outline: none;
  background: rgba(249, 249, 249, 0.82);
  height: 45px;
  border-radius: 7px;
  padding: 10px 0% 10px 0%;
  margin: 4px 0 0 0;
  font-family: "IBMMed";
  font-weight: 400;
  font-size: 18px;

  color: rgba(27, 46, 75, 1);
  display: flex;
}
.pwd_input_acc {
  outline: none;
  background: transparent;
  border: 0;
  width: 86%;
  margin: 0 0 0 4%;
}
.eye_btn_pwd {
  outline: none;
  border: 0;
  background: transparent;
}
.self_eye_pwd {
  font-size: 18px;
  color: rgba(27, 46, 75, 1);
}

@media (max-width: 767px) {
  .label_input_text_contianer {
    width: 100%;
  }
  .input_label_text {
    font-size: 14px;
    margin: 10px 0px;
  }
  .input_dialog_style {
    font-size: 13px;
    padding: 0px 5%;
    height: 42px;
    margin: 0;
  }
  .pwd_cust_contianer {
    width: 100%;
    margin: 0 0 10% 0;
  }
  .pwd_btn_eye_container {
    height: 42px;
    font-size: 13px;
  }
  .self_eye_pwd {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .label_input_text_contianer {
    width: 46%;
  }
  .input_label_text {
    font-size: 14px;
  }
  .input_dialog_style {
    height: 42px;
    font-size: 13px;
    padding: 5px 4%;
  }
}

@media (min-width: 991px) {
  .input_label_text {
    font-size: 16px;
  }
  .input_dialog_style {
    height: 45px;

    font-size: 15px;
    padding: 5px 4%;
  }
}


.is-invalid {
  border: 1px solid red;
}
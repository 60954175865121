.main_title_couse_text {
  font-family: IBMMed;
  margin: 0 0 1% 0%;
  color: var(--theme-blue);
  padding: 0 2% 0% 2%;
  font-size: 35px;
}
.course_header_contianer {
  display: flex;
  align-items: flex-start;
  margin: 2% 0 0 2%;
  padding: 2% 0 2% 0;
}
.back_aeerow_btn_container {
  background: transparent;
  border: 0;
  outline: none;
  /* margin: 2% 0 0 2%; */
}
.back_aerrow_icon {
  font-size: 35px;
  color: #1b2e48;
}
.view_details_heading_text2 {
  font-family: IBMMed;
  padding: 0% 0 0% 1%;
  color: var(--theme-blue);
  font-size: 30px;
  margin: 0;
}
.view_course_main_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.view_coure_left_contianer {
  width: 60%;
  padding: 0 2% 0 2%;
}

.view_coure_right_contianer {
  width: 40%;
}
.univer_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--theme-blue);
}
.univer_title_course_text {
  font-family: "IBMMed";
  font-size: 20px;
  color: var(--theme-blue);
  margin: 0 0 0 2%;
}

.univer_img_title_text_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 2% 0;
}
.entry_requirement_tetx {
  font-family: IBMMed;
  margin: 2% 0 1% 0%;
  color: var(--theme-blue);
}
.education_title_text {
  font-family: IBMMed;
  margin: 3% 0 1% 0%;
  color: var(--theme-blue);
  font-size: 24px;
}
.entry_requirement_tetx {
  font-family: IBMMed;
  margin: 0 0 0 0%;
  color: var(--theme-blue);
  font-size: 20px;
}
.entry_img_text_contianer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin: 5% 0 0% 0;
}
.true_require_img {
  width: 25px;
  height: 25px;
}
.score_exam_title_tetx {
  font-size: 17px;
  margin: 0 0 0 3%;
  font-family: "IBMReg";
}
.score_text_exam {
  font-size: 18px;
  margin: 0 0 0 0%;
  font-family: "IBMReg";
  font-weight: 600;
}
.entry_right_requirement_container {
  margin: 0 0 0 0%;
  width: 50%;
}
.width_equal_part_container {
  width: 50%;
}
.entry_academeiv_requirement_contianer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.more_about_info_btn {
  font-family: IBMReg;
  margin: 2% 0 0 0%;
  color: var(--theme-blue);
  font-size: 22px;
  outline: none;
  border: 1px solid var(--theme-blue);
  background-color: transparent;
  padding: 1.5% 2% 1.5% 2%;
  border-radius: 4px;
}
.more_about_info_btn:hover {
  background-color: var(--theme-blue);
  color: #fff;
}

@media (max-width: 767px) {
  .main_title_couse_text {
    margin: 0 0 1% 0%;
    padding: 0 5%;
    font-size: 17px;
    line-height: 21px;
  }
  .course_header_contianer {
    margin: 2% 0 0 2%;
    padding: 4% 2% 4% 2%;
  }
  .back_aeerow_btn_container {
    /* margin: 2% 0 0 2%; */
  }
  .back_aerrow_icon {
    font-size: 25px;
  }
  .view_details_heading_text2 {
    padding: 0% 0 0% 3%;

    font-size: 20px;
  }
  .view_course_main_container {
    display: flex;
    flex-direction: column;
  }
  .view_coure_left_contianer {
    width: 100%;
    padding: 0 4%;
  }

  .view_coure_right_contianer {
    width: 100%;
    padding: 0 4%;
  }
  .univer_img {
    width: 20px;
    height: 20px;
  }
  .univer_title_course_text {
    font-size: 13px;
    margin: 0 0 0 3%;
  }

  .univer_img_title_text_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 2% 0;
  }
  .entry_requirement_tetx {
    margin: 2% 0 1% 0%;
  }
  .education_title_text {
    margin: 3% 0;
    font-size: 20px;
  }
  .entry_requirement_tetx {
    margin: 0 0 0 0%;
    font-size: 14px;
  }
  .entry_img_text_contianer {
    margin: 3% 0 0% 0;
  }
  .true_require_img {
    width: 20px;
    height: 20px;
  }
  .score_exam_title_tetx {
    font-size: 12px;
    margin: 0 0 0 3%;
  }
  .score_text_exam {
    font-size: 12px;
    margin: 0 0 0 0%;

    font-weight: 600;
  }
  .entry_right_requirement_container {
    margin: 10px 0 0 0%;
    width: 100%;
  }
  .width_equal_part_container {
    width: 100%;
  }
  .entry_academeiv_requirement_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .more_about_info_btn {
    font-size: 14px;
    width: 100%;
    padding: 2% 2% 2% 2%;
    margin: 5% auto 15px;
  }
  .more_about_info_btn:hover {
  }
  .education_title_text {
    margin: 4% 0 5px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .main_title_couse_text {
    margin: 0 0 1% 0%;
    padding: 0 3%;
    font-size: 18px;
    line-height: 23px;
  }
  .course_header_contianer {
    display: flex;
    align-items: center;
    margin: 2% 0 0 2%;
    padding: 2% 0% 2% 0%;
  }
  .back_aeerow_btn_container {
  }
  .back_aerrow_icon {
    font-size: 25px;
  }
  .view_details_heading_text2 {
    padding: 0% 0 0% 1%;

    font-size: 20px;
  }
  .view_course_main_container {
    display: flex;
    flex-direction: column;
  }
  .view_coure_left_contianer {
    width: 100%;
    padding: 0 2%;
  }

  .view_coure_right_contianer {
    width: 100%;
    padding: 0 2%;
  }
  .univer_img {
    width: 25px;
    height: 25px;
  }
  .univer_title_course_text {
    font-size: 15px;
    margin: 0 0 0 1%;
  }

  .univer_img_title_text_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 1% 0;
  }
  .entry_requirement_tetx {
    margin: 2% 0 1% 0%;
  }
  .education_title_text {
    margin: 3% 0;
    font-size: 20px;
  }
  .entry_requirement_tetx {
    margin: 0 0 0 0%;
    font-size: 16px;
  }
  .entry_img_text_contianer {
    margin: 3% 0 0% 0;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .true_require_img {
    width: 25px;
    height: 25px;
  }
  .score_exam_title_tetx {
    font-size: 15px;
    margin: 0 0 0 3%;
  }
  .score_text_exam {
    font-size: 14px;
    margin: 0 0 0 0%;

    font-weight: 600;
  }
  .entry_right_requirement_container {
    margin: 0px 0 0 0%;
    width: 100%;
  }
  .width_equal_part_container {
    width: 100%;
  }
  .entry_academeiv_requirement_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .more_about_info_btn {
    font-size: 16px;
    width: 100%;
    padding: 1% 2% 1% 2%;
    margin: 1% auto 15px;
  }
  .more_about_info_btn:hover {
  }
  .education_title_text {
    margin: 2% 0 12px;
    font-size: 17px;
  }
}

@media (min-width: 991px) {
  .main_title_couse_text {
    margin: 0 0 1% 0%;

    padding: 0 2% 0% 2%;
    font-size: 23px;
    line-height: 33px;
  }
  .course_header_contianer {
    display: flex;
    align-items: flex-start;
    margin: 2% 0 0 2%;
    padding: 2% 0 2% 0;
  }
  .back_aeerow_btn_container {
    background: transparent;
    border: 0;
    outline: none;
    /* margin: 2% 0 0 2%; */
  }
  .back_aerrow_icon {
    font-size: 35px;
  }
  .view_details_heading_text2 {
    padding: 0% 0 0% 1%;

    font-size: 27px;
    margin: 0;
  }
  .view_course_main_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 25px 0 0;
  }
  .view_coure_left_contianer {
    width: 60%;
    padding: 0 2% 0 2%;
  }

  .view_coure_right_contianer {
    width: 40%;
  }
  .univer_img {
    width: 30px;
    height: 30px;
  }
  .univer_title_course_text {
    font-size: 19px;

    margin: 0 0 0 2%;
  }

  .univer_img_title_text_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 2% 0;
  }
  .entry_requirement_tetx {
    margin: 2% 0 1% 0%;
  }
  .education_title_text {
    margin: 4% 0 4% 0%;
    font-size: 21px;
  }
  .entry_requirement_tetx {
    margin: 0 0 0 0%;

    font-size: 20px;
  }
  .entry_img_text_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 5% 0 0% 0;
  }
  .true_require_img {
    width: 25px;
    height: 25px;
  }
  .score_exam_title_tetx {
    font-size: 17px;
    margin: 0 0 0 3%;
  }
  .score_text_exam {
    font-size: 17px;
    margin: 0 0 0 0%;
  }
  .entry_right_requirement_container {
    margin: 0 0 0 0%;
    width: 50%;
  }
  .width_equal_part_container {
    width: 50%;
  }
  .entry_academeiv_requirement_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .more_about_info_btn {
    margin: 2% 0 0 0%;
    font-size: 20px;
    padding: 1% 2% 1% 2%;
  }
}

@media (min-width: 1400px) {
  .main_title_couse_text {
    margin: 0 0 1% 0%;

    padding: 0 2% 0% 2%;

    font-size: 27px;
    line-height: 36px;
  }
  .course_header_contianer {
    display: flex;
    align-items: flex-start;
    margin: 2% 0 0 2%;
    padding: 2% 0 2% 0;
  }
  .back_aeerow_btn_container {
    background: transparent;
    border: 0;
    outline: none;
    /* margin: 2% 0 0 2%; */
  }
  .back_aerrow_icon {
    font-size: 35px;
  }
  .view_details_heading_text2 {
    padding: 0% 0 0% 1%;

    font-size: 30px;
    margin: 0;
  }
  .view_course_main_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 25px 0 0;
  }
  .view_coure_left_contianer {
    width: 60%;
    padding: 0 2% 0 2%;
  }

  .view_coure_right_contianer {
    width: 40%;
  }
  .univer_img {
    width: 40px;
    height: 40px;
  }
  .univer_title_course_text {
    font-size: 22px;
    margin: 0 0 0 2%;
  }

  .univer_img_title_text_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 2% 0;
  }
  .entry_requirement_tetx {
    margin: 2% 0 1% 0%;
  }
  .education_title_text {
    margin: 3% 0 3% 0%;
    font-size: 26px;
  }
  .entry_requirement_tetx {
    margin: 0 0 0 0%;

    font-size: 23px;
  }
  .entry_img_text_contianer {
    display: flex;
    align-items: center;

    flex-direction: row;
    margin: 5% 0 0% 0;
  }
  .true_require_img {
    width: 35px;
    height: 35px;
  }
  .score_exam_title_tetx {
    font-size: 17px;
    margin: 0 0 0 3%;
  }
  .score_text_exam {
    font-size: 17px;
    margin: 0 0 0 0%;
  }
  .entry_right_requirement_container {
    margin: 0 0 0 0%;
    width: 50%;
  }
  .width_equal_part_container {
    width: 50%;
  }
  .entry_academeiv_requirement_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .more_about_info_btn {
    margin: 2% 0 0 0%;
    font-size: 20px;
    padding: 1% 2% 1% 2%;
  }
}

span.carousel-control-prev-icon {
  width: 1rem;
}
span.carousel-control-next-icon {
  width: 1rem;
}
.carousel-indicators [data-bs-target] {
  width: 23px;
}
.left_silder_main_contianer {
  margin: 0 0 10% 0;
}
.score_text1 {
  font-size: 13px;
  text-transform: uppercase;
  color: var(--theme-blue);

  margin-bottom: 20px;
  font-family: InterSemBold;
}

@media screen and (max-width: 767px) {
  .score_text1 {
    font-size: 13px;
    margin-bottom: 16px;
    margin-top: 17px;
  }
}

@media screen and (min-width: 768px) {
  .score_text1 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--theme-blue);

    margin-bottom: 20px;
  }
  .w-100 {
    width: 300px !important;
    margin: auto;
  }
}

@media screen and (min-width: 991px) {
  .score_text1 {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .w-100 {
    width: 100% !important;
    margin: auto;
  }
  .left_silder_main_contianer {
    margin: 10px 0 10% 0;
  }
}

@media screen and (min-width: 1200px) {
  .left_silder_main_contianer {
    margin: 10px 0 10% 0;
  }
}

.checkbox_label_container3 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50%;
}

/* Hide the browser's default radio button */
.checkbox_label_container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid var(--theme-blue);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkbox_label_container3:hover input ~ .checkmark {
  border: 1px solid var(--theme-blue);
}

/* When the radio button is checked, add a blue background */
.checkbox_label_container3 input:checked ~ .checkmark {
  background-color: var(--theme-blue);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkbox_label_container3 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkbox_label_container3 .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.row_contianer_budget {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: IBMMed;
  font-size: 20px;
  color: var(--theme-blue);
  cursor: pointer;
  margin: -3px 0 0 0;
}

@media (max-width: 767px) {
  .checkbox_label_container3 {
    padding-left: 21px;
    margin-bottom: 12px;
    width: 100%;
  }
  .row_contianer_budget {
    font-size: 13px;
    color: var(--theme-blue);
  }
  .checkmark {
    height: 15px;
    width: 15px;
  }
  .checkbox_label_container3 .checkmark:after {
    top: 4px;
    left: 4px;
    width: 5px;
    height: 5px;
  }
}

@media (min-width: 1200px) {
  .row_contianer_budget {
    font-size: 18px;
    margin: -5px 0 0 -6px;
  }
  .checkmark {
    height: 20px;
    width: 20px;
  }
  .checkbox_label_container3 .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
  }
}

.profile_show_container {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 8%;
}
.profile_pic_menu {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.round_circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.title_profile_menu_text {
  margin: 11px 0 0 0;
  font-size: 15px;
  font-family: "IBMMed";
}

.sub_profile_menu_text {
  margin: 0 0 5% 0;
  font-size: 13px;
  font-family: "IBMReg";
  color: rgb(131, 146, 165);
}

.edit_profile_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0% 0 0 0;
}

.profile_menu_text_one {
  margin: 0 0 0 9%;
  font-size: 15px;
  font-family: "IBMReg";

  /* transition: all 0.2s ease-in-out 0s; */
}

.edit_profile_icon {
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .profile_show_container {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3% 0 0 10%;
  }
  .profile_pic_menu {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  .title_profile_menu_text {
    margin: 11px 0 0 0;
    font-size: 12px;
    font-family: "IBMMed";
  }
  .sub_profile_menu_text {
    margin: 0 0 5% 0;
    font-size: 10px;
    font-family: "IBMReg";
    color: rgb(131, 146, 165);
  }
  .edit_profile_icon {
    font-size: 14px;
  }
  .profile_menu_text_one {
    margin: 0 0 0 6%;
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 991px) {
}

@media screen and (min-width: 1200px) {
}

.left_side_under_container {
}
.profile_details_container {
  background-color: #fff;
  width: 90%;
  margin: 0 auto 9% auto;
  padding: 4% 5% 4% 5%;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  -webkit-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  -moz-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  border-radius: 6px;
}
.heading_edit_cotainer {
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.heading_text {
  color: var(--theme-blue);
  font-family: IBMSemBold;
  font-size: 17px;
  margin: 0;
}
.edit_icon_self {
  outline: none;
  border: 0;
  background-color: transparent;
}
.edit_icon_style {
}
.edit_prfile_photo_container {
  display: flex;
  justify-content: center;
  margin: 6% auto 7% auto;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid #00000063;
}
.profile_photo_edit {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.name_username_about_text_contianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 85%;
  margin: 0 auto 9% auto;
}

.title_text_edit {
  font-size: 16px;
  font-family: IBMSemBold;
  color: var(--theme-blue);
  margin: 0;
  text-transform: capitalize;
}

.user_name_edit_text {
  font-family: IBMReg;
  color: rgba(131, 146, 165, 1);
  margin: 0;
}

.extra_edit_text {
  font-family: IBMReg;
  margin: 0;
  line-height: 14px;
  font-size: 13px;
  color: rgba(0, 23, 55, 0.7);
  margin: 0;
}
.two_icon_text_two_contianer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin: 0 0 2% 0;
}
.edit_icon_style {
  font-size: 22px;
  color: rgb(0 23 55 / 88%);
  margin: 0 4% 0 0;
}
.number_edit_text {
  font-family: IBMMed;
  font-size: 15px;
  margin: 0;
  width: 90%;
  color: rgb(0 23 55 / 88%);
 
}

.two_under_container {
  background-color: #fff;
  width: 90%;
  margin: 4% auto 9% auto;
  padding: 4% 5% 4% 5%;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  -webkit-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  -moz-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
  border-radius: 6px;
}
.edit_icon_style1 {
  font-size: 20px;
  color: rgb(0 23 55 / 88%);
}
.two_icon_text_two_contianer1 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 2% 0;
}
.two_text_two_login_contianer {
  margin: 3% 0 0 0;
}

@media (max-width: 299px) {
  .edit_prfile_photo_container {
    display: flex;
    justify-content: center;
    margin: 6% auto 7% auto;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .edit_prfile_photo_container {
    width: 100px;
    height: 100px;
  }
  .profile_details_container {
    width: 95%;
  }
  .heading_text {
    font-size: 15px;
  }
  .edit_icon_style {
    font-size: 17px;

    margin: 0 2% 0 0;
  }
  .name_username_about_text_contianer {
    width: 89%;
  }
  .title_text_edit {
    font-size: 14px;
  }
  .user_name_edit_text {
    font-size: 14px;
    margin: 5px 0;
  }
  .extra_edit_text {
    font-size: 11px;
  }
  .number_edit_text {
    font-size: 13px;
  }
  .two_under_container {
    width: 94%;
  }
  .heading_edit_cotainer {
    margin: 5px 0;
  }
}

@media (min-width: 768px) {
  .profile_details_container {
    padding: 3% 6%;
  }
  .heading_text {
    font-size: 16px;
  }
  .edit_prfile_photo_container {
    margin: 20px auto 15px;
    width: 80px;
    height: 80px;
  }

  .heading_text {
    font-size: 15px;
  }
  .edit_icon_style {
    font-size: 17px;

    margin: 0 2% 0 0;
  }
  .name_username_about_text_contianer {
    width: 89%;
  }
  .title_text_edit {
    font-size: 14px;
  }
  .user_name_edit_text {
    font-size: 14px;
    margin: 5px 0;
  }
  .extra_edit_text {
    font-size: 11px;
  }
  .number_edit_text {
    font-size: 13px;
  }
  .two_under_container {
    width: 90%;
  }
  .heading_edit_cotainer {
    margin: 5px 0;
  }
  .two_icon_text_two_contianer {
    margin: 0 0 4% 0;
  }
}

@media (min-width: 991px) {
  .profile_details_container {
    padding: 2% 6%;
  }
  .heading_text {
    font-size: 16px;
  }
  .edit_prfile_photo_container {
    margin: 20px auto 15px;
    width: 120px;
    height: 120px;
  }

  .heading_text {
    font-size: 17px;
  }
  .edit_icon_style {
    font-size: 18px;

    margin: 0 2% 0 0;
  }
  .name_username_about_text_contianer {
    width: 89%;
  }
  .title_text_edit {
    font-size: 15px;
  }
  .user_name_edit_text {
    font-size: 15px;
    margin: 5px 0;
  }
  .extra_edit_text {
    font-size: 13px;
  }
  .number_edit_text {
    font-size: 15px;
  }
  .two_under_container {
    padding: 2% 6%;
    width: 90%;
  }
  .heading_edit_cotainer {
    margin: 5px 0;
  }
  .two_icon_text_two_contianer {
    margin: 0 0 4% 0;
  }
}

@media (min-width: 1200px) {
  .profile_details_container {
    padding: 2% 6%;
    margin: 0 auto 25px auto;
  }
  .heading_text {
    font-size: 16px;
  }
  .edit_prfile_photo_container {
    margin: 20px auto 15px;
    width: 140px;
    height: 140px;
  }

  .heading_text {
    font-size: 18px;
  }
  .edit_icon_style {
    font-size: 20px;
    margin: 0 3% 0 0;
  }
  .name_username_about_text_contianer {
    width: 89%;
    margin: 0 auto 5% auto;
  }
  .title_text_edit {
    font-size: 16px;
  }
  .user_name_edit_text {
    font-size: 16px;
    margin: 5px 0;
  }
  .extra_edit_text {
    font-size: 15px;
    line-height: 20px;
  }
  .number_edit_text {
    font-size: 15px;
  }
  .two_under_container {
    padding: 2% 6%;
    width: 90%;
  }
  .heading_edit_cotainer {
    margin: 5px 0;
  }
  .two_icon_text_two_contianer {
    margin: 0 0 2% 0;
  }
}

@media (min-width: 1400px) {
  .profile_details_container {
    padding: 3% 4%;
  }
  .two_under_container {
    padding: 3% 4%;
    width: 90%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 35px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 50px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--theme-blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--theme-blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(58px);
  -ms-transform: translateX(58px);
  transform: translateX(58px);
}
.text_no {
  font-size: 20px;
  font-family: "IBMMed";
  color: var(--theme-blue);
  margin: 0 10px 0 10px;
}
.univerciti_main_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 20px 20px;
  width: 92%;
}
.exam_toggle_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 20px 20px;
  width: 45%;
}
.dob_exam_container {
  width: 100%;
  margin: 0 0 0 0;
}

@media (max-width: 767px) {
  .exam_toggle_contianer {
    margin: 10px 0;
    width: 100%;
  }
  input:checked + .slider:before {
    transform: translateX(45px);
  }
  .univerciti_main_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 20px 0;
    width: 100%;
  }
  .text_no {
    font-size: 13.5px;
  }
  .switch {
    width: 90px;
    height: 26px;
  }
  .slider:before {
    height: 18px;
    width: 37px;
    left: 4px;
    bottom: 4px;
  }
}

@media (min-width: 768px) {
  .exam_toggle_contianer {
    margin: 10px 0;
    width: 50%;
  }
  input:checked + .slider:before {
    transform: translateX(45px);
  }
  .univerciti_main_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 20px 0;
    width: 100%;
  }
  .text_no {
    font-size: 13.5px;
  }
  .switch {
    width: 90px;
    height: 26px;
  }
  .slider:before {
    height: 18px;
    width: 37px;
    left: 4px;
    bottom: 4px;
  }
}

@media (min-width: 991px) {
  .text_no {
    font-size: 19.5px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  background: #f8f8f8;
}

.video_iframe {
  width: 100%;
  height: 200px;
}
.slider_post {
  width: 60% !important;
  height: 220px !important;
  margin: 0 auto !important;
  object-fit: contain !important;
}

.swiper-button-next {
  background-image: url("../../../assets/right-aerrow-swipe.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("../../../assets/left-aerrow-swipe.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.overlay_swiper {
  position: fixed;
  z-index: 100;
  background: #000000d4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
/* .overlay_under_content {
  width: 100%;
  height: 100%;

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
/* background: red; */
.img_container_swiper {
  background: transparent !important;
}
.img_container_swiper > img {
  max-width: 60%;
  max-height: 60%;
  object-fit: contain;
  box-shadow: 0px 3px 16px 6px rgb(0 0 0 / 75%);
  border: 1px solid #ffffff63;
  background: #fff !important;
}
.dismiss_overlay {
  border: 0;
  outline: none;
  cursor: pointer;
  background: var(--theme-blue);
  width: 30px;
  height: 30px;
  color: #fff !important;
  position: absolute;
  left: 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  font-size: 23px;
  top: 4%;
  padding: 0 0 6px;
  z-index: 101;
}
.gallery_video1 {
  position: relative;
  cursor: pointer;
  width: 24%;
  margin: 0.5%;
  border-radius: 5px;
  border: 2px solid #00000061;
  background: transparent;
  transition: 1s ease-in-out;
}
.gallery_video1:hover {
  cursor: pointer;
  /* transform: scale(1.1); */
  box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
}
.gallery_likes_count_container {
  position: absolute;
  background-color: rgb(0 0 0 / 59%);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* border-radius: 10px 10px 0 0; */
  border: 1px solid #00000038;
  transition: 1s;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 75%);
}
.gallery_likes_count_container1 {
  display: none;
  background-color: rgba(255, 255, 255, 0.59);
}

.gallery_likes_container {
  display: flex;
  align-items: center;
  transition: 1s;
}
.gallery_likes_container1 {
  display: flex;
  gap: 20px;
  transition: 1s;
}
.gallery_likes_container > svg,
.gallery_icon_count_container > svg {
  color: #fff;
}
.gallery_likes_container > strong,
.gallery_icon_count_container > strong {
  margin: 0 6px;
  font-family: IBMReg;
  color: #fff;
}
.gallery_vdo_likes_count_container {
  position: absolute;
  background-color: rgba(46, 45, 45, 0.863);
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #00000038;
  transition: 1s;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 75%);
}
.swiper_vdo_internal {
  width: 77%;
  height: 65%;
}
.play_btn_count_container {
  position: absolute;
  background-color: rgba(46, 45, 45, 0.863);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play_btn_container {
  background: transparent;
  outline: none;
  border: 0;
}
.play_btn_container > svg {
  font-size: 60px;
  color: #fff;
}
.play_btn_container > svg:hover {
  color: rgb(167, 1, 1);
}

.ply_pause_btn_container1 {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;

  height: 30px;

  flex-direction: column;
  background: rgb(0 0 0 / 55%);
  height: 100%;
  /* position: absolute; */
  /* bottom: 7%; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.play_vdo_btn_container1 {
  background: transparent;
  color: #fff;
  width: 50px;
  height: 50px;
  border: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.play_vdo_btn_container1 > svg {
  font-size: 34px;
}
.play_vdo_btn_container1:hover,
.full_screen_icon1 > svg:hover {
  color: rgb(255, 0, 0);
}

.play_vdo_btn_container1 > svg:hover {
  animation: rotate-in-center 1s 0.1s both;
}
@keyframes rotate-in-center {
  0% {
    transform: rotate(-360deg);
    opacity: 0.5;
    font-size: 34px;
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
    font-size: 40px;
  }
}

.full_screen_icon1 {
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  position: absolute;
  right: 2%;
  bottom: 7%;
}
.full_screen_icon1 > svg {
  color: #fff;
  font-size: 21px;
}

@media (max-width: 767px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    max-height: 220px;
  }
  .overlay_swiper {
    flex-direction: column;
  }
  .slider_post {
    width: 80% !important;
    height: 150px !important;
  }
  .video_iframe {
    width: 100%;
    height: auto;
    margin: 0 auto !important;
  }
  .gallery_video1 {
    width: 49%;
    margin: 0.5%;
  }
  .gallery_likes_container > svg,
  .gallery_icon_count_container > svg {
    font-size: 12px;
  }
  .gallery_likes_container > strong,
  .gallery_icon_count_container > strong {
    font-size: 12px;
  }
  .play_btn_container > svg {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    max-height: 250px;
  }
  .gallery_video1 {
    width: 32.33%;
    margin: 0.5%;
  }
  .gallery_likes_container > svg,
  .gallery_icon_count_container > svg {
    font-size: 18px;
  }
  .gallery_likes_container > strong,
  .gallery_icon_count_container > strong {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .play_vdo_btn_container1 > svg {
    font-size: 40px;
  }
}

.well_done_text {
  font-size: 24px;
  color: var(--theme-blue);
  font-family: "IBMSemBold";
}
.extra_text_of_study {
  font-size: 19px;
  line-height: 25px;
  margin: 16px 0;
}
.text_input_ps1 {
  border-bottom: 1px solid var(--theme-blue);
  border-top: 0;
  border-left: 0;
  border-right: 0;

  font-family: "IBMMed";
  font-size: 17px;
  outline: none;
  width: 70%;
  margin: 16px 0 33px;
}
.select_btn_intro1 {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  border: 0;
  border-radius: 3px;
}

.select_btn_intro1_selected {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  border: 0;
  background-color: var(--theme-blue);
  outline: none;
  border-radius: 3px;
}

@media (max-width: 299px) {
  .select_btn_intro1 {
    padding: 11px 16px;
    font-size: 13px;
    line-height: 13px;
    border: 0;
    border-radius: 3px;
  }
  .select_btn_intro1_selected {
    padding: 11px 16px;
    font-size: 13px;
    line-height: 13px;
    border: 1px solid var(--theme-blue);
    border-radius: 3px;
  }
  .well_done_text {
    font-size: 19px;
  }
  .extra_text_of_study {
    font-size: 14px;
    line-height: 18px;
    margin: 16px 0;
  }
  .text_input_ps1 {
    font-size: 11px;

    width: 100%;
    margin: 5px 0 15px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .select_btn_intro1 {
    padding: 11px 16px;
    font-size: 15px;
    line-height: 16px;
    border: 0;
    border-radius: 3px;
  }
  .select_btn_intro1_selected {
    padding: 11px 16px;
    font-size: 15px;
    line-height: 16px;
    border: 1px solid var(--theme-blue);
    border-radius: 3px;
  }
  .well_done_text {
    font-size: 19px;
  }
  .extra_text_of_study {
    font-size: 14px;
    line-height: 18px;
    margin: 16px 0;
  }
  .text_input_ps1 {
    font-size: 14px;
    width: 100%;
    margin: 9px 0 15px;
    line-height: 19px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .select_btn_intro1 {
    padding: 11px 16px;
    font-size: 17px;
    line-height: 24px;
    border: 0;
    border-radius: 3px;
  }
  .select_btn_intro1_selected {
    padding: 11px 16px;
    font-size: 17px;
    line-height: 24px;
    border: 1px solid var(--theme-blue);
    border-radius: 3px;
  }
  .well_done_text {
    font-size: 21px;
  }
  .extra_text_of_study {
    font-size: 17px;
    line-height: 25px;
    margin: 16px 0;
  }
  .text_input_ps1 {
    font-size: 16px;
    width: 100%;
    margin: 11px 0 15px;
    line-height: 19px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .text_input_ps1 {
    font-size: 19px;
    outline: none;
    width: 98%;
    margin: 0px 0 0px;
  }
  .title_btn_text_intro {
    font-size: 19px;
    line-height: 19px;

    margin: 0;
  }
  .select_btn_intro1 {
    padding: 11px 33px;

    font-size: 18px;
    line-height: 27px;
  }
  .select_btn_intro1_selected {
    padding: 11px 33px;

    font-size: 18px;
    line-height: 27px;
  }
}

.header_notification_main_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 96%;

  justify-content: space-between;
  padding: 3% 0% 0 5%;
}
.menu_contaier {
  max-height: 400px;
  overflow-y: auto;
}
.notification_title {
  font-size: 17px;
  font-family: "IBMMED";
}
.mark_as_container {
  background-color: transparent;
  border: 0;
  outline: none;
}
.mark_as_container:hover {
  color: rgba(0, 23, 55, 0.85);
}
.mark_all_read_text {
  font-size: 14px;
  font-family: IBMReg;
}
.item_notfication_container {
  width: 400px;
  max-width: 400px;
  transition: none 0s ease 0s;
  border-radius: 0.25rem;
  white-space: normal;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.avtar_contianer {
  position: relative;
  width: 28%;
  border-radius: 50%;
}
.notifi_avtar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.title_subtitle_container {
  margin: 0 0 10px 10px;
}

.title_notifiy_text {
  font-family: IBMMed;
  color: rgb(27, 46, 75);
  font-size: 14.5px;
  margin-bottom: 0;
}
.sub_tiite_notify_text {
  font-family: IBMReg;
  color: rgba(0, 23, 55, 0.85);
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}
.time_notify_text {
  font-family: IBMReg;
  color: rgb(131, 146, 165);
  font-size: 11px;
  margin: 5px 0 0 0;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .header_notification_main_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 2% 0% 0 4%;
  }
  .mark_all_read_text {
    font-size: 12px;
    font-family: IBMReg;
    text-decoration: none;
  }
  .notification_title {
    font-size: 17px;
    font-family: "IBMMED";
  }
  .item_notfication_container {
    width: 220px;
    max-width: 230px;
    transition: none 0s ease 0s;
    border-radius: 0.25rem;
    white-space: normal;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .line_notifcation {
    margin: 3px 0 6px 0;
  }
  .notifi_avtar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
  .title_subtitle_container {
    margin: 0 0 0px 10px;
  }
  .title_notifiy_text {
    font-size: 11.5px;
  }
  .sub_tiite_notify_text {
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 0;
  }
  .time_notify_text {
    font-size: 10px;
    margin: 2px 0 0 0;
  }
}

@media screen and (min-width: 768px) {
  .header_notification_main_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 2% 0% 0 4%;
  }
  .mark_all_read_text {
    font-size: 12px;
    font-family: IBMReg;
    text-decoration: none;
  }
  .notification_title {
    font-size: 17px;
    font-family: "IBMMED";
  }
  .item_notfication_container {
    width: 220px;
    max-width: 230px;
    transition: none 0s ease 0s;
    border-radius: 0.25rem;
    white-space: normal;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .line_notifcation {
    margin: 3px 0 6px 0;
  }
  .notifi_avtar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
  .title_subtitle_container {
    margin: 0 0 0px 10px;
  }
  .title_notifiy_text {
    font-size: 11.5px;
  }
  .sub_tiite_notify_text {
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 0;
  }
  .time_notify_text {
    font-size: 10px;
    margin: 2px 0 0 0;
  }
}

@media screen and (min-width: 991px) {
  .item_notfication_container {
    width: 260px;
    max-width: 270px;
  }
  .notifi_avtar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .title_notifiy_text {
    font-size: 14.5px;
  }
  .sub_tiite_notify_text {
    font-size: 11px;
    line-height: 11px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .mark_all_read_text {
    font-size: 13px;
    font-family: IBMReg;
    text-decoration: none;
  }
  .item_notfication_container {
    width: 300px;
    max-width: 300px;
  }
  .sub_tiite_notify_text {
    font-size: 12px;
    line-height: 12px;
    margin: 3px 0 0 0px;
  }
}

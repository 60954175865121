.alert_box_content_container{
    display: flex;
    flex-direction: column;
    margin: 0 0 25px 0;
}
.alert_box_content_container > p{
    font-family: "IBMMed";
    font-size: 18px;
}
.two_btn_container{
    display: flex;
    flex-direction: row;
    
    
    justify-content: flex-end;
    gap: 26px;
}

.two_btn_container > button{
    font-family: "IBMMed";
   
    padding: 10px;
    border: 0;
    background: rgba(217, 217, 217, 1);
    height: 45px;
    
    font-size: 14px;
    
    color: var(--theme-blue);
    
    text-transform: uppercase;
}
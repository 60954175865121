.conectin_under_right_side_contianer {
  margin: 4% 0 0 0;
}
.conectin_txt_contianer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 76%;
  align-items: center;
  margin: 0% 0 4% 0;
}
div.conectin_txt_contianer > a {
  font-family: IBMMed;
  font-size: 13.5px;
}
.people_main_title_text {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: IBMReg;
  color: var(--theme-blue);
}
.dicover_by_postion_container {
  margin: 4% 0 0 0;
}

.all_popular_univercity_container {
  display: flex;
  flex-direction: column;

  margin: 0 0 0 0%;
}

@media (max-width: 767px) {
  .people_main_title_text {
    font-size: 13px;
    line-height: 28px;
  }
  .dicover_by_postion_container {
    margin: 0% 0 0 0;
  }
  .conectin_txt_contianer {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .people_main_title_text {
    font-size: 17px;
    margin: 0% 0 2% 0;
  }
  .conectin_txt_contianer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 92%;
    align-items: center;
    margin: 0% 0 1% 0;
  }
  .dicover_by_postion_container {
    margin: 2% 0 0 0;
  }
}

@media (min-width: 991px) {
  .conectin_under_right_side_contianer {
    margin: 4% 0 0 0;
  }
  .conectin_txt_contianer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;

    align-items: center;
  }
  div.conectin_txt_contianer > a {
    margin: 0% 0 4% 0;
    font-family: IBMMed;
    font-size: 13.5px;
  }
  .people_main_title_text {
    font-size: 14px;
    line-height: 20px;

    margin: 0px 0 5% 0;
  }
  .dicover_by_postion_container {
    margin: 4% 0 0 0;
  }

  .all_popular_univercity_container {
    display: flex;
    flex-direction: column;

    margin: 0 0 0 0%;
  }
}

@media (min-width: 1200px) {
  .conectin_under_right_side_contianer {
    margin: 4% 0 0 0;
    padding: 1%;
    background: #fff;
  }
  .conectin_txt_contianer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  div.conectin_txt_contianer > a {
    margin: 0% 0 4% 0;
    font-family: IBMMed;
    font-size: 13.5px;
  }
  .people_main_title_text {
    font-size: 14px;
    line-height: 17px;
    margin: 0px 0 4% 0;
  }
  .dicover_by_postion_container {
    margin: 4% 0 0 0;
  }

  .all_popular_univercity_container {
    display: flex;
    flex-direction: column;

    margin: 0 0 0 0%;
  }
}

.step-wizard {
  /* background-color: var(--theme-blue);
  background-image: linear-gradient(19deg, var(--theme-blue) 0%, #b721ff 100%); */

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-wizard-list {
  background: #fff;
  box-shadow: 0 15px 25px rgb(0 0 0 / 10%);
  color: #333;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 20px 10px 5px;
  position: relative;
  z-index: 0;
  margin: 2% auto 3%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.step-wizard-item {
  padding: 0 20px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
}
.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: var(--theme-blue);
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}
.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}
.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: var(--theme-blue);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}
.progress-count:before {
  content: "";
  height: 9px;
  width: 15px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}
.progress-label {
  font-size: 14px;
  color: var(--theme-blue);
  font-family: IBMSemBold;
  margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  display: none;
}
.current-item ~ .step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}
.current-item ~ .step-wizard-item .progress-label {
  opacity: 0.5;
}
.current-item .progress-count:after {
  background: #fff;
  border: 2px solid var(--theme-blue);
}
.current-item .progress-count {
  color: var(--theme-blue);
}

/* description text */
.step_main_container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}
.description_container_stepper {
  background-color: #fff;
  box-shadow: 0 15px 25px rgb(0 0 0 / 10%);
  margin: 0% 0 4%;
  padding: 2% 0 0;
  border: 1px solid rgb(0 0 0 / 14%);
  border-radius: 8px;
}
.content1 {
  color: var(--theme-blue);
  /* background-color: red; */
}

.application_visa_container {
  width: 100%;
  background: #f3f3f3;
  margin: 0 auto;
}
.active-tabs1 {
  color: var(--theme-blue);
  font-family: IBMMed;
  border-bottom: 3px solid var(--theme-blue);
}
.bloc-tabs1 {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #0000001f;
}
.application_content_box_container {
  min-height: 200px;
  position: relative;
  margin: 2% 0 5%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 1px 1px rgb(0 0 0 / 10%);
}

.tabs1 {
  padding: 0px;
  margin: 0 3% 0 0;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  border: none;
  color: rgba(27, 46, 75, 0.32);
  background: #f3f3f3;
  font-family: IBMMed;
}

.active-tabs1 {
  border-bottom: 2px solid var(--theme-blue);
  color: var(--theme-blue);
}
@media (max-width: 767px) {
  .tabs1 {
    font-size: 13px;
  }
  .active-tabs1 {
    border-bottom: 2px solid var(--theme-blue);
  }
  .bloc-tabs1 {
    margin: 3% auto 0 auto;

    /* flex-wrap: wrap; */
  }
  .application_visa_container,
  .step_main_container {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .application_visa_container,
  .step_main_container {
    width: 700px;
  }
  .tabs1 {
    font-size: 12px;
    line-height: 14px;
  }
  .bloc-tabs1 {
    margin: 1% auto 0 auto;
  }
  .progress-count:after {
    height: 30px;
    width: 30px;
  }
  .progress-label {
    font-size: 14px;

    margin: 3px 0 0;
  }
}

@media (min-width: 991px) {
  .tabs1 {
    font-size: 13px;
    line-height: 14px;
  }
  .application_visa_container,
  .step_main_container {
    width: 930px;
  }
  .progress-label {
    font-size: 14px;

    margin: 3px 0 0;
  }
}

@media (min-width: 1200px) {
  .application_visa_container,
  .step_main_container {
    width: 1100px;
  }
  .tabs1 {
    font-size: 16px;
  }
  .progress-label {
    font-size: 14px;

    margin: 3px 0 0;
  }
}

@media (min-width: 1400px) {
  .application_visa_container,
  .step_main_container {
    width: 1200px;
  }
  .tabs1 {
    font-size: 17px;
  }
}

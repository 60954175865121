.main_error_page_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0;
}
.error_img {
  width: 30%;
}
.page_not_found_txt {
  font-family: IBMMed;
  margin: 3% auto 1%;
  font-size: 20px;
}
.home_page_btn {
  border: 1px solid #01b4ff;
  background-color: transparent;
  height: 40px;
  width: 10%;
  font-size: 16px;
  color: #01b4ff;
  font-family: IBMMed;
}
.home_page_btn:hover,
.home_page_btn:visited,
.home_page_btn:active {
  background-color: #01b4ff;
  color: aliceblue;
  transition: all 0.5s linear;
}
@media (max-width: 767px) {
  .main_error_page_container {
    padding: 80px 0;
  }
  .error_img {
    width: 50%;
  }
  .page_not_found_txt {
    font-family: IBMMed;
    margin: 10% auto 8%;
    font-size: 18px;
  }
  .home_page_btn {
    border: 1px solid #01b4ff;
    background-color: transparent;
    height: 35px;
    width: 40%;
    font-size: 15px;
    color: #01b4ff;
    font-family: IBMMed;
  }
}

@media (min-width: 768px) {
  .main_error_page_container {
    padding: 80px 0;
  }
  .error_img {
    width: 50%;
  }
  .page_not_found_txt {
    font-family: IBMMed;
    margin: 5% auto 3%;
    font-size: 18px;
  }
  .home_page_btn {
    border: 1px solid #01b4ff;
    background-color: transparent;
    height: 35px;
    width: 30%;
    font-size: 15px;
    color: #01b4ff;
    font-family: IBMMed;
  }
}

@media (min-width: 1200px) {
  .main_error_page_container {
    width: 1200px;
    margin: auto;
  }
  .error_img {
    width: 30%;
  }
  .page_not_found_txt {
    font-family: IBMMed;
    margin: 3% auto 1.5%;
    font-size: 20px;
  }
  .home_page_btn {
    border: 1px solid #01b4ff;
    background-color: transparent;
    height: 40px;
    width: 10%;
    font-size: 16px;
    color: #01b4ff;
    font-family: IBMMed;
  }
}

@media (min-width: 1400px) {
  .main_error_page_container {
    width: 1400px;
    margin: auto;
  }
}

.main_container_sign_in {
  display: flex;
  flex-direction: row;
  margin: 4% 5% 3% 5%;
}

/* left-container */
.left_container_sign {
  width: 65%;

  display: flex;
  justify-content: center;
}
.left_img_sign_in {
  width: 60%;
}

/* right-contianer */
.right_under_contianer {
  width: 340px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}
.right_container_sign {
  width: 35%;

  display: flex;
  flex-direction: column;
}
.sign_in_tex {
  font-family: IBMMed;
  color: var(--theme-blue);
  margin-bottom: 5px;
}
.welcome_text {
  font-size: 16px;
  color: rgb(131, 146, 165);
  margin-bottom: 40px;
  font-family: IBMReg;
}
.email_text {
  font-family: IBMReg;
}
.email_imput {
  outline: none;
  font-family: IBMReg;
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.pwd_container {
  margin: 20px 0 20px 0;
}
.btn_sign_in {
  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);
  border: none;
}

.btn_google_fb_contianer {
  display: flex;
  flex-direction: column;
}
.btn_fb {
  background: transparent;
  color: #3b5998;
  border: 1px solid #3b5998;
}
.btn_fb:focus {
  background: #3b5998;
  color: #fff;
}
.btn_google {
  margin: 0% 0 5% 0;
  background: transparent;
  border: 1px solid rgb(211, 72, 54);
  color: rgb(211, 72, 54);
}
.btn_google:focus {
  background: rgb(211, 72, 54);
  color: #fff;
}

.or_horizontal_line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-transform: uppercase;
  color: rgb(131, 146, 165);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 15px 0px;
}
.or_text_sign_in {
  margin: 0% 2% 0 2%;
  font-size: 10px;
  font-family: IBMReg;
}
.line_horizontal {
  width: 100%;
  border: 1px solid #3333333d;
}
.same_btn_sign_in {
  font-family: IBMReg;
  outline: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;

  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
}
.forgot_text {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;
  font-family: IBMReg;
  text-decoration: none;
  color: rgb(1, 104, 250);
}
.forgot_text:hover {
  color: darkblue;
}
.dont_have_text {
  text-decoration: none;
  text-align: center;
  margin: 5% 0 0 0;
  color: #000;
}
.create_text {
  color: rgb(1, 104, 250);
}
.pwd_btn_eye_container1 {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.pwd_btn_eye_container1 > input {
  border: 0;
  outline: none;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .dont_have_text {
    text-decoration: none;
    text-align: center;
    margin: 5% 0 0 0;
    color: #000;
    font-size: 14px;
  }
  .create_text {
    color: rgb(1, 104, 250);
    font-size: 14px;
  }
  .main_container_sign_in {
    display: flex;
    margin: 5% 2% 2% 2%;
    flex-direction: column;
  }

  /* left-container */
  .left_container_sign {
    width: 100%;
  }
  .left_img_sign_in {
    width: 0%;
  }

  /* right-contianer */
  .right_under_contianer {
    width: 270px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }
  .right_container_sign {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .sign_in_tex {
    font-family: IBMMed;
    color: var(--theme-blue);
    margin-bottom: 5px;
  }
  .welcome_text {
    font-size: 14px;

    color: rgb(131, 146, 165);
    margin-bottom: 30px;
    font-family: IBMReg;
  }
  .email_text {
    font-family: IBMReg;
  }
  .email_imput {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .pwd_container {
    margin: 20px 0 20px 0;
  }
  .btn_sign_in {
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    border: none;
  }

  .btn_google_fb_contianer {
    margin: 0 0 5% 0;
  }
  .btn_fb {
    background: transparent;
    color: #3b5998;
    border: 1px solid #3b5998;
  }

  .btn_google {
    margin: 0% 0 5% 0;
    background: transparent;
    border: 1px solid rgb(211, 72, 54);
    color: rgb(211, 72, 54);
  }

  .or_horizontal_line {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    color: rgb(131, 146, 165);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 15px 0px;
  }
  .or_text_sign_in {
    margin: 0% 2% 0 2%;
    font-size: 10px;
    font-family: IBMReg;
  }
  .line_horizontal {
    width: 100%;
    border: 1px solid #3333333d;
  }
  .same_btn_sign_in {
    font-family: IBMReg;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;

    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;

    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
  .forgot_text {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0 0 0;
    font-family: IBMReg;
    text-decoration: none;
    color: rgb(1, 104, 250);
  }
}

@media screen and (min-width: 768px) {
  .main_container_sign_in {
    display: flex;
    margin: 5% 2% 2% 2%;
    flex-direction: column;
  }

  /* left-container */
  .left_container_sign {
    width: 100%;
  }
  .left_img_sign_in {
    width: 0%;
  }

  /* right-contianer */
  .right_under_contianer {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }
  .right_container_sign {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .sign_in_tex {
    font-family: IBMMed;
    color: var(--theme-blue);
    margin-bottom: 5px;
  }
  .welcome_text {
    font-size: 14px;

    color: rgb(131, 146, 165);
    margin-bottom: 30px;
    font-family: IBMReg;
  }
  .email_text {
    font-family: IBMReg;
  }
  .email_imput {
    width: 100%;
    height: 38px;
  }
  .pwd_container {
    margin: 20px 0 20px 0;
  }
  .btn_sign_in {
  }

  .btn_google_fb_contianer {
    margin: 0 0 5% 0;
  }
  .btn_fb {
  }

  .btn_google {
    margin: 0% 0 5% 0;
  }

  .or_horizontal_line {
    font-size: 10px;
  }
  .or_text_sign_in {
    font-size: 10px;
  }
  .line_horizontal {
  }
  .same_btn_sign_in {
  }
  .forgot_text {
  }
}

@media screen and (min-width: 991px) {
  .main_container_sign_in {
    display: flex;
    flex-direction: row;
    margin: 4% 4% 3% 4%;
    align-items: center;
  }

  /* left-container */
  .left_container_sign {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left_img_sign_in {
    width: 85%;
  }

  /* right-contianer */
  .right_under_contianer {
    width: 340px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
  }
  .right_container_sign {
    width: 35%;

    display: flex;
    flex-direction: column;
  }
  .sign_in_tex {
    font-family: IBMMed;
    color: var(--theme-blue);
    margin-bottom: 5px;
  }
  .welcome_text {
    font-size: 16px;
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
  }
  .email_text {
    font-family: IBMReg;
  }
  .email_imput {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .pwd_container {
    margin: 20px 0 20px 0;
  }
  .btn_sign_in {
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    border: none;
  }

  .btn_google_fb_contianer {
    display: flex;
    flex-direction: column;
  }
  .btn_fb {
    background: transparent;
    color: #3b5998;
    border: 1px solid #3b5998;
  }
  .btn_fb:focus {
    background: #3b5998;
    color: #fff;
  }
  .btn_google {
    margin: 0% 0 5% 0;
    background: transparent;
    border: 1px solid rgb(211, 72, 54);
    color: rgb(211, 72, 54);
  }
  .btn_google:focus {
    background: rgb(211, 72, 54);
    color: #fff;
  }

  .or_horizontal_line {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    color: rgb(131, 146, 165);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 15px 0px;
  }
  .or_text_sign_in {
    margin: 0% 2% 0 2%;
    font-size: 10px;
    font-family: IBMReg;
  }
  .line_horizontal {
    width: 100%;
    border: 1px solid #3333333d;
  }
  .same_btn_sign_in {
    font-family: IBMReg;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;

    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;

    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
  .forgot_text {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0 0 0;
    font-family: IBMReg;
    text-decoration: none;
    color: rgb(1, 104, 250);
  }
  .forgot_text:hover {
    color: darkblue;
  }
}

@media screen and (min-width: 1200px) {
  .main_container_sign_in {
    display: flex;
    flex-direction: row;
    margin: 4% 4% 3% 4%;
    align-items: center;
  }

  /* left-container */
  .left_container_sign {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left_img_sign_in {
    width: 85%;
  }

  /* right-contianer */
  .right_under_contianer {
    width: 340px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
  }
  .right_container_sign {
    width: 35%;

    display: flex;
    flex-direction: column;
  }
  .sign_in_tex {
    font-family: IBMMed;
    color: var(--theme-blue);
    margin-bottom: 5px;
  }
  .welcome_text {
    font-size: 16px;
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
  }
  .email_text {
    font-family: IBMReg;
  }
  .email_imput {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .pwd_container {
    margin: 20px 0 20px 0;
  }
  .btn_sign_in {
    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);
    border: none;
  }

  .btn_google_fb_contianer {
    display: flex;
    flex-direction: column;
  }
  .btn_fb {
    background: transparent;
    color: #3b5998;
    border: 1px solid #3b5998;
  }
  .btn_fb:focus {
    background: #3b5998;
    color: #fff;
  }
  .btn_google {
    margin: 0% 0 5% 0;
    background: transparent;
    border: 1px solid rgb(211, 72, 54);
    color: rgb(211, 72, 54);
  }
  .btn_google:focus {
    background: rgb(211, 72, 54);
    color: #fff;
  }

  .or_horizontal_line {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    color: rgb(131, 146, 165);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 15px 0px;
  }
  .or_text_sign_in {
    margin: 0% 2% 0 2%;
    font-size: 10px;
    font-family: IBMReg;
  }
  .line_horizontal {
    width: 100%;
    border: 1px solid #3333333d;
  }
  .same_btn_sign_in {
    font-family: IBMReg;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;

    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;

    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
  }
  .forgot_text {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0 0 0;
    font-family: IBMReg;
    text-decoration: none;
    color: rgb(1, 104, 250);
  }
  .forgot_text:hover {
    color: darkblue;
  }
}

.sign_up_input.is-invalid {
  border-color: red;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

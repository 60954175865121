.heading_container_visa {
  width: 100%;
}
.heading_container_width {
  text-align: center;
  font-family: IBMBold;
}
.heading_container_width1 {
  text-align: center;
}

.data_table_content {
  border-bottom: 1px solid #00000029;
  margin: 0 0 5px;
}

@media (max-width: 767px) {
  .heading_container_width,
  .heading_container_width1 {
    font-size: 13.5px;
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .heading_container_width,
  .heading_container_width1 {
    font-size: 12px;
    width: auto;
  }
  .heading_container_visa {
    width: 1000px;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .heading_container_width,
  .heading_container_width1 {
    font-size: 13px;
    width: auto;
  }
  .heading_container_visa {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .heading_container_visa {
    width: 1100px;
  }
  .heading_container_width {
    width: 220px;
  }
  .heading_container_width1 {
    width: 130px;
  }
}

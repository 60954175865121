.view_detail_title_sub_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3% 0 4% 0;
}
.view_detail_box_img_contianer {
}
.view_detail_img {
}
.view_detail_fact_text_subtitle_contianer {
  margin: 0 0 0 3%;
}
.view_detail_fact_title_text {
  font-size: 20px;
  margin: 0;
  font-family: IBMMed;
  color: var(--theme-blue);
}
.view_detail_fact_sub_title_text {
  font-size: 19px;
  margin: 0;
  font-family: IBMReg;
  color: var(--theme-blue);
}

@media (max-width: 767px) {
  .view_detail_img {
    width: 100%;
  }
  .view_detail_box_img_contianer {
    width: 30px;
  }
  .view_detail_fact_text_subtitle_contianer {
    margin: 0 0 0 4%;
  }
  .view_detail_fact_title_text {
    font-size: 12px;
  }
  .view_detail_fact_sub_title_text {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .view_detail_title_sub_title_container {
    margin: 2% 0 2% 0;
  }
  .view_detail_fact_text_subtitle_contianer {
    margin: 0 0 0 1.5%;
  }
  .view_detail_fact_title_text {
    font-size: 15px;
  }
  .view_detail_fact_sub_title_text {
    font-size: 15px;
  }
  .view_detail_box_img_contianer {
    width: 40px;
  }
}

@media (min-width: 991px) {
  .view_detail_title_sub_title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3% 0 4% 0;
  }
  .view_detail_box_img_contianer {
  }
  .view_detail_img {
  }
  .view_detail_fact_text_subtitle_contianer {
    margin: 0 0 0 3%;
  }
  .view_detail_fact_title_text {
    font-size: 18px;
  }
  .view_detail_fact_sub_title_text {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .view_detail_fact_sub_title_text {
    font-size: 15px;
}
}

@media (min-width: 1400px) {
  .view_detail_fact_title_text {
    font-size: 20px;
  }
  .view_detail_box_img_contianer {
    width: 50px;
  }
  .view_detail_fact_sub_title_text {
    font-size: 18px;
  }
}

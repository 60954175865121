.main_edit_profile_contianer {
  background-color: #f3f3f3;
}
.edit_profile_main_container {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 2% auto 0 auto;
  padding: 0 0 10%;
}
.left_side_edit_profile_contianer {
  width: 35%;
}
.right_side_edit_profile_contianer {
  /* background-color: #94e275; */
  width: 65%;
}

/* left side */

@media screen and (max-width: 767px) {
  .edit_profile_main_container {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 10% auto 0 auto;
  }
  .left_side_edit_profile_contianer {
    width: 100%;
  }
  .right_side_edit_profile_contianer {
    /* background-color: #94e275; */
    width: 95%;
    margin: 0 auto;
  }
}



@media (min-width: 768px) {
  .edit_profile_main_container {
    width: 700px;
    margin: 38px auto 0;
  }
  .left_side_edit_profile_contianer {
    width: 35%;
  }
  .right_side_edit_profile_contianer {
    
    width: 65%;
  }
  .right_side_under_edit_profile_{
    
    margin: 0 0 0 10px;
  }
  
}



@media (min-width: 991px) {
  .edit_profile_main_container {
    width: 900px;
    margin: 38px auto 0;
  }
  .left_side_edit_profile_contianer {
    width: 35%;
  }
  .right_side_edit_profile_contianer {
    
    width: 65%;
  }
  .right_side_under_edit_profile_{
    
    margin: 0 0 0 10px;
  }
  
}

@media (min-width: 1200px) {
  
  .edit_profile_main_container {
    width: 1100px;
    margin: 38px auto 0;
  }
  .right_side_under_edit_profile_ {
    margin: 0 0 0 10px;
}
}




@media (min-width: 1400px) {
  
  .edit_profile_main_container {
    width: 1200px;
    margin: 38px auto 0;
  }
  .right_side_under_edit_profile_ {
    margin: 0 0 0 10px;
}
}

.right_side_poster_profile_container {
  /* background: rgb(169, 17, 17);
  background: linear-gradient(
  90deg,
  rgba(169, 17, 17, 0.15) 0%,
  rgba(0, 101, 255, 0.15) 49%,
  rgba(5, 13, 26, 0.12) 100%
  ); */
  background: linear-gradient(
    270deg,
    rgb(169 17 17 / 18%) 0%,
    rgb(0 97 255 / 18%) 100%
  );
  background: -moz-linear-gradient(
    270deg,
    rgb(169 17 17 / 18%) 0%,
    rgb(0 97 255 / 18%) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgb(169 17 17 / 18%) 0%,
    rgb(0 97 255 / 18%) 100%
  );
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
}
.poster_editpro_text {
  font-size: 24px;
  font-family: "IBMBold";
  color: var(--theme-blue);
}
.poster_extra_editpro_text {
  font-size: 18px;
  font-family: "IBMReg";
  color: var(--theme-blue);
}
.right_poster_extra_text {
}
.poster_container_img {
  width: 35%;
}
.title_time_contianer11{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.title_time_contianer11 > h6{
  width: 49%;
}
.title_time_contianer11 h6:first-child{
 text-transform: capitalize;
}
.title_time_contianer11 h6:last-child{
  margin: 0 auto 0 0 !important;
  font-size: 11.5px;
  display: flex;
  justify-content: flex-end;
}
.pooster_img_self {
  width: 100%;
  margin-top: -16%;
}
.institute_name_container {
  width: 50%;
}
.tab_main_contianer {
  border-radius: 6px;
  background: #fff;
  margin: 3% 0 0 0;
  padding: 3% 5% 0% 5%;
  -webkit-box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 66%);
}
.content_main_one_container {
}

/* tab under */
.heading_content_two_text {
  font-size: 15px;
  margin: 13px 0 -12px;
  color: var(--theme-blue);
  font-family: IBMSemBold;
}
.heading_content_two_text1 {
  font-size: 15px;
  margin: 13px 0 -18px;
  color: var(--theme-blue);
  font-family: IBMSemBold;
}
.basic_info_address_contaier {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.basic_info_container {
  width: 40%;
  background-color: #fff;
  padding: 2% 3% 2% 3%;
  border-radius: 6px;
}
.date_of_title_text {
  font-family: "IBMMed";

  color: rgba(27, 46, 75, 1);
  font-size: 15px;
  margin: 2% 0 1% 0;
}
.sub_text_date {
  font-family: IBMMed;
  text-transform: capitalize;
  font-size: 15.5px;
  color: rgba(27, 46, 75, 0.42);
}

/* address */

.address_info_container {
  background-color: #fff;
  padding: 2% 3% 2% 3%;
  margin: 0 0 0 2%;
  width: 60%;
  border-radius: 6px;
}
.address_under_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.address_title_subtitle_contianer {
  width: 50%;
}

/* preffered */

.prefferd_container {
  background-color: #fff;
  padding: 2% 3% 2% 3%;
  margin: 3% 0 2% 0;
  width: 60%;
  border-radius: 6px;
}

/* education info */

.education_info_contaier {
  width: 100%;
  background-color: #fff;
  padding: 2% 3% 2% 3%;
  border-radius: 6px;
}
.edu_under_info_contianer {
  width: 33.33%;
  display: flex;
  margin: 1% 0 0 0;
  padding: 0 2% 0 0;
}
.wrap_content_container {
  display: flex;
  flex-wrap: wrap;
}

/* work info */

.work_experience_container {
  margin: 2% 0 0 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.work_container {
  background-color: #fff;
  padding: 2% 3% 2% 3%;
  margin: 0 0 3% 0;
  width: 49%;
  border-radius: 6px;
}
.work_under_things_container {
  display: flex;
  flex-direction: row;
  margin: 4% 0 4% 0;
}
.work_img_self {
  width: 26%;
  height: 80px;
}
.title_subtitle_location_time_contianer {
  width: 100%;
  margin: 0 0 0 3%;
}
.title_time_contianer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.job_title_text {
  font-family: IBMMed;
  color: rgba(27, 46, 75, 1);
  margin: 0 0 0.5% 0;
  width: 70%;
  font-size: 15px;
}

.job_time_text {
  text-transform: capitalize;
  font-family: IBMReg;
  color: rgba(27, 46, 75, 1);
  margin: 0 0 0.5% 0;
  font-size: 15px;
}
.period_light_text {
  font-family: IBMReg;
  color: rgba(27, 46, 75, 0.32);
  width: 70%;
  font-size: 15px;
  margin: 0 0 2px ;
}
.point_job_content {
  font-family: IBMReg;
  color: rgba(27, 46, 75, 1);
  line-height: 19px;
  font-size: 14px;
}

.plus_contianer {
  width: 49%;
}
.squre_contianer {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  border: 0;
}
.plus_icon {
  font-size: 18px;
  color: rgba(27, 46, 75, 1);
}
.work_experience_container_border {
  background-color: transparent;
  padding: 2% 3% 2% 3%;
  margin: 0 0 3% 0;
  width: 49%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed;
}

/* profile eligibilty */

.profile_eligibility_under_main_container {
  display: flex;
  width: 100%;
}
.study_aspirint_container {
  /* display: flex; */
}
.study_aspirint_under_container {
  background-color: #fff;
  padding: 4% 3% 3% 4%;
  border-radius: 6px;
  margin: 0 0 4% 0;
}
.stydy_wrap_container {
  display: flex;
  flex-wrap: wrap;
}
.aspiriant_container {
  width: 47%;
  margin: 0 2% 0 0;
}
.desired_destination_contianer {
  /* width: 39%; */
  background-color: #fff;
  padding: 4% 3% 3% 4%;

  border-radius: 6px;
  margin: 0 0 4% 2%;
}
.left_container {
  width: 55%;
  margin: 0 1% 0 0;
}
.right_container {
  width: 44%;
}

/* exam content */

.exam_main_contaier {
}
.exam_main_under_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.exam_one_container {
  background-color: #fff;
  padding: 3% 3% 2% 4%;
  width: 49%;
  border-radius: 6px;
  margin: 0 0 4% 0;
}
.wrap_container_exam {
  display: flex;
  flex-wrap: wrap;
}
.width_container {
  width: 50%;
  margin: 2% 0 0 0;
}
.exam_one_container_border {
  border: 1px dashed var(--theme-blue);
  padding: 3% 3% 2% 4%;
  width: 49%;
  border-radius: 6px;
  margin: 0 0 4% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exam_main_contaier {
}
.two_text_input_container {
  width: 50%;
}

.title_subtitle_header_container {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.work_experience_container_border > span {
  font-size: 15px;
  margin: 0 0 0 10px;
  font-family: "IBMSemBold";
}

@media screen and (max-width: 767px) {
  .exam_one_container_border {
    padding: 3% 3% 2% 4%;
    width: 100%;
    height: 150px;

    margin: 0 0 4% 0;
  }
  .exam_one_container {
    width: 100%;

    margin: 0 0 30px 0;
  }
  .exam_main_contaier {
    margin: 25px 0;
  }
  .right_container {
    width: 100%;
  }
  .desired_destination_contianer {
    margin: 0 0 30px;
  }
  .study_aspirint_under_container {
    margin: 0 0 30px;
  }
  .aspiriant_container {
    width: 100%;
    margin: 0 2% 0 0;
  }
  .stydy_wrap_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .left_container {
    width: 100%;
    margin: 0 1% 0px;
  }
  .profile_eligibility_under_main_container {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
  }
  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 4% 0 1%;
  }
  .work_experience_container_border {
    padding: 2% 3% 2% 3%;
    margin: 20px auto 3%;
    width: 99%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work_img_self {
    width: 70px;
    height: 70px;
  }
  .point_job_content {
    line-height: 16px;
    font-size: 13px;
    text-align: left;
  }
  .period_light_text {
    color: rgb(27 46 75 / 72%);
    width: 100%;
    font-size: 13px;
  }
  .job_title_text {
    margin: 0 0 0.5% 0;
    width: 100%;
    font-size: 13.5px;
  }
  .job_time_text {
    margin: 0 0 0.5% 0;
    font-size: 13px;
  }
  .title_time_contianer {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .work_container {
    padding: 4% 6%;
    margin: 0 0 3% 0;
    width: 100%;
  }
  .work_experience_container {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .education_info_contaier {
    width: 100%;
    margin: 25px 0 0;
    padding: 3% 5%;
  }
  .sub_text_date {
    font-size: 14px;
  }
  .date_of_title_text {
    font-size: 12.5px;
    margin: 2% 0 1% 0;
  }
  .poster_container_img {
    width: 35%;
    display: none;
  }
  .poster_editpro_text {
    font-size: 15px;
    margin: 0;
    line-height: 22px;
  }
  .poster_extra_editpro_text {
    font-size: 12px;
    line-height: 21px;
    margin: 0;
  }
  .right_poster_extra_text {
    margin: 0 0 0 0%;
  }
  .right_side_poster_profile_container {
    width: 100%;

    margin: 0 auto 26px;
    display: flex;
    padding: 3% 0 4% 14px;
    justify-content: flex-start;
  }
  .pooster_img_self {
    display: none;
  }
  .basic_info_address_contaier {
    flex-direction: column;
  }
  .basic_info_container {
    width: 100%;
    padding: 4% 5% 4% 5%;
    margin: 20px 0px 30px;
  }
  .address_info_container {
    padding: 2% 3% 2% 3%;
    margin: 0 0 0 0%;
    width: 100%;

    padding: 4% 5% 4% 5%;
  }
  .prefferd_container {
    padding: 4% 5% 4% 5%;

    margin: 30px 0;
    width: 100%;
  }
  .address_under_contianer {
    align-items: flex-start;
  }
  .edu_under_info_contianer {
    width: 100%;
    display: flex;
    margin: 1% auto;
    padding: 0 2% 0 0;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading_content_two_text {
    margin: 13px 0 -18px;
    font-size: 14px;
  }
  .heading_content_two_text1 {
    margin: 13px 0 -22px;
    font-size: 14px;
  }
  .tab_main_contianer {
    margin: 3% 0 0 0;
    padding: 3% 3% 0%;
  }
  .exam_one_container_border {
    padding: 3% 3% 2% 4%;
    width: 100%;
    height: 150px;

    margin: 0 0 4% 0;
  }
  .exam_one_container {
    width: 100%;
    padding: 2% 3% 4%;
    margin: 0 0 15px 0;
  }
  .exam_main_contaier {
    margin: 25px 0;
  }
  .right_container {
    width: 100%;
  }
  .desired_destination_contianer {
    padding: 2% 3% 4%;

    margin: 0 0 30px;
  }
  .study_aspirint_under_container {
    margin: 0 0 30px;
    padding: 2% 3% 4%;
  }
  .aspiriant_container {
    width: 100%;
    margin: 0 2% 0 0;
  }
  .stydy_wrap_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .left_container {
    width: 100%;
    margin: 0 1% 0px;
  }
  .profile_eligibility_under_main_container {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
  }
  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 2% 0 1%;
  }
  .work_experience_container_border {
    padding: 2% 3% 2% 3%;
    margin: 20px auto 3%;
    width: 99%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work_img_self {
    width: 70px;
    height: 70px;
  }
  .point_job_content {
    line-height: 16px;
    font-size: 13px;
    text-align: left;
  }
  .period_light_text {
    color: rgb(27 46 75 / 72%);
    width: 100%;
    font-size: 13px;
  }
  .job_title_text {
    margin: 0 0 0.5% 0;
    width: 70%;
    font-size: 13.5px;
  }
  .job_time_text {
    margin: 0 0 0.5% 0;
    font-size: 13px;
  }
  .title_time_contianer {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  .work_container {
    padding: 2% 3% 4%;
    margin: 0 0 3% 0;
    width: 100%;
  }
  .work_experience_container {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .education_info_contaier {
    width: 100%;
    margin: 25px 0 0;

    padding: 2% 3% 3%;
  }
  .sub_text_date {
    margin: 0;

    font-size: 12px;
  }
  .date_of_title_text {
    font-size: 12.5px;
    margin: 0.5% 0 0% 0;
  }
  .poster_container_img {
    width: 35%;
    display: none;
  }
  .poster_editpro_text {
    font-size: 17px;
    margin: 0;
    line-height: 25px;
  }
  .poster_extra_editpro_text {
    font-size: 13px;
    line-height: 21px;
    margin: 0;
  }
  .right_poster_extra_text {
    margin: 0 0 0 0%;
  }
  .right_side_poster_profile_container {
    width: 100%;

    margin: 0 auto 26px;
    display: flex;
    padding: 3% 0 4% 14px;
    justify-content: flex-start;
  }
  .pooster_img_self {
    display: none;
  }
  .basic_info_address_contaier {
    flex-direction: column;
  }
  .basic_info_container {
    width: 100%;
    padding: 2% 3% 2%;
    margin: 20px 0px 30px;
  }
  .address_info_container {
    padding: 2% 3% 4%;
    margin: 0 0 0 0%;
    width: 100%;
  }
  .prefferd_container {
    padding: 2% 3% 4%;

    margin: 30px 0;
    width: 100%;
  }
  .address_under_contianer {
    align-items: flex-start;
  }
  .edu_under_info_contianer {
    width: 100%;
    display: flex;
    margin: 0% auto;
    padding: 0%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .width_container {
    width: 50%;
    display: flex;
    margin: 2% 0 0 0;
    flex-direction: column;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .heading_content_two_text {
    margin: 13px 0 -10px;
    font-size: 14px;
  }
  .heading_content_two_text1 {
    margin: 13px 0 -20px;
    font-size: 14px;
  }
  .tab_main_contianer {
    margin: 3% 0 0 0;
    padding: 3% 3% 0%;
  }
  .exam_one_container_border {
    padding: 3% 3% 2% 4%;
    width: 100%;
    height: 150px;

    margin: 0 0 4% 0;
  }
  .exam_one_container {
    width: 100%;
    padding: 2% 3% 4%;
    margin: 0 0 30px 0;
  }
  .exam_main_contaier {
    margin: 25px 0;
  }
  .right_container {
    width: 100%;
  }
  .desired_destination_contianer {
    padding: 2% 3% 4%;

    margin: 0 0 30px;
  }
  .study_aspirint_under_container {
    margin: 0 0 30px;
    padding: 2% 3% 4%;
  }
  .aspiriant_container {
    width: 48%;
    margin: 0 2% 0 0;
  }
  .stydy_wrap_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left_container {
    width: 100%;
    margin: 0 1% 0px;
  }
  .profile_eligibility_under_main_container {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
  }
  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 2% 0 1%;
  }
  .work_experience_container_border {
    padding: 2% 3% 2% 3%;
    margin: 21px auto 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work_img_self {
    width: 60px;
    height: 60px;
  }
  .point_job_content {
    line-height: 16px;
    font-size: 13px;
    text-align: left;
  }
  .period_light_text {
    color: rgb(27 46 75 / 72%);
    width: 100%;
    font-size: 13px;
  }
  .job_title_text {
    margin: 0 0 0.5% 0;
    width: 70%;
    font-size: 13.5px;
  }
  .job_time_text {
    margin: 0 0 0.5% 0;
    font-size: 13px;
  }
  .title_time_contianer {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  .work_container {
    padding: 2% 3% 4%;
    margin: 0 0 3% 0;
    width: 48%;
  }
  .work_experience_container {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    align-items: stretch;
  }
  .education_info_contaier {
    width: 100%;
    margin: 20px 0 0;
    padding: 2% 3% 4%;
  }
  .sub_text_date {
    margin: 0 3px;

    font-size: 14px;
  }
  .date_of_title_text {
    font-size: 15px;
    margin: 1.5% 0 1% 0;
  }
  .poster_container_img {
    width: 200px;
    display: block;
  }
  .poster_editpro_text {
    font-size: 23px;
    margin: 0;
    line-height: 31px;
  }
  .poster_extra_editpro_text {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }
  .right_poster_extra_text {
    margin: 0 0 0 0%;
  }
  .right_side_poster_profile_container {
    width: 100%;
    margin: 0 auto 26px;
    display: flex;
    padding: 3% 30px 0%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .pooster_img_self {
    display: inline-block;
    margin: -60px 0 0;
  }
  .basic_info_address_contaier {
    flex-direction: column;
  }
  .basic_info_container {
    width: 100%;
    padding: 2% 3% 4%;
    margin: 20px 0px 30px;
  }
  .address_info_container {
    padding: 2% 3% 4%;
    margin: 0 0 0 0%;
    width: 100%;
  }
  .prefferd_container {
    padding: 2% 3% 4%;

    margin: 30px 0;
    width: 100%;
  }
  .address_under_contianer {
    align-items: flex-start;
  }
  .edu_under_info_contianer {
    width: 100%;
    display: flex;
    margin: 1% auto;
    padding: 0%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .title_time_contianer11 h6:last-child{
   
    font-size: 11.5px;

  }
  .heading_content_two_text {
    font-size: 18px;
  }
  .heading_content_two_text1 {
    font-size: 18px;
    margin: 6px 0 -19px;
  }
  .tab_main_contianer {
    margin: 25px 0 0 0;
    padding: 2% 2% 0%;
  }
  .exam_one_container_border {
    padding: 3% 3% 2% 4%;
    width: 100%;
    height: 150px;

    margin: 0 0 4% 0;
  }
  .exam_one_container {
    width: 100%;
    padding: 2%;
    margin: 0 0 30px 0;
  }
  .exam_main_contaier {
    margin: 25px 0;
  }
  .right_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .desired_destination_contianer {
    width: 48%;
    padding: 2%;

    margin: 0 0 30px;
  }
  .study_aspirint_under_container {
    margin: 0 0 30px;
    padding: 2%;
  }
  .aspiriant_container {
    width: 48%;
    margin: 0 2% 0 0;
  }
  .stydy_wrap_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left_container {
    width: 100%;
    margin: 0px;
  }
  .profile_eligibility_under_main_container {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
  }
  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 2% 0 1%;
  }
  .work_experience_container_border {
    padding: 2% 3% 2% 3%;
    margin: 20px auto 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work_img_self {
    width: 60px;
    height: 60px;
  }
  .point_job_content {
    line-height: 16px;
    font-size: 13px;
    text-align: left;
  }
  .period_light_text {
    color: rgb(27 46 75 / 72%);
    width: 100%;
    font-size: 13px;
  }
  .job_title_text {
    margin: 0 0 0.5% 0;
    width: 70%;
    font-size: 13.5px;
  }
  .job_time_text {
    margin: 0 0 0.5% 0;
    font-size: 13px;
  }
  .title_time_contianer {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  .work_container {
    padding: 2%;
    margin: 0 0 20px 0;
    width: 49%;
  }
  .work_experience_container {
    margin: 30px auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    align-items: stretch;
  }
  .education_info_contaier {
    width: 100%;
    margin: 20px 0 0;
    padding: 2%;
  }
  .sub_text_date {
    margin: 0 1px;
    font-size: 14px;
  }
  .date_of_title_text {
    font-size: 15.5px;
    margin: 1% 0 0% 0;
  }
  .poster_container_img {
    width: 200px;
    display: block;
  }
  .poster_editpro_text {
    font-size: 25px;

    margin: 0;
    line-height: 31px;
  }
  .poster_extra_editpro_text {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
  .right_poster_extra_text {
    margin: 0 0 0 0%;
  }
  .right_side_poster_profile_container {
    width: 100%;
    margin: 0 auto 26px;
    display: flex;
    padding: 3% 30px 0%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .pooster_img_self {
    display: inline-block;
    margin: -60px 0 0;
  }
  .basic_info_address_contaier {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
  .basic_info_container {
    width: 48%;
    padding: 2%;
    margin: 15px 0px 0px;
  }
  .address_info_container {
    padding: 2%;
    margin: 15px 0 0 0%;
    width: 48%;
  }
  .prefferd_container {
    padding: 2%;

    margin: 25px 0;
    width: 100%;
  }
  .address_under_contianer {
    align-items: flex-start;
  }
  .edu_under_info_contianer {
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0%;
    flex-wrap: wrap;
    flex-direction: row;

    align-items: flex-start;
  }
}

@media (min-width: 1400px) {
  .tab_main_contianer {
    margin: 25px 0 0 0;
    padding: 2% 2% 0%;
  }
  .exam_one_container_border {
    padding: 3% 3% 2% 4%;
    width: 100%;
    height: 150px;

    margin: 0 0 4% 0;
  }
  .exam_one_container {
    width: 100%;
    padding: 2%;
    margin: 0 0 30px 0;
  }
  .exam_main_contaier {
    margin: 25px 0;
  }
  .right_container {
    width: 100%;
  }
  .desired_destination_contianer {
    padding: 2%;

    margin: 0 0 30px;
  }
  .study_aspirint_under_container {
    margin: 0 0 30px;
    padding: 2%;
  }
  .aspiriant_container {
    width: 48%;
    margin: 0 2% 0 0;
  }
  .stydy_wrap_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left_container {
    width: 100%;
    margin: 0px;
  }
  .profile_eligibility_under_main_container {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
  }
  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 2% 0 1%;
  }
  .work_experience_container_border {
    padding: 2% 3% 2% 3%;
    margin: 18px auto 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work_img_self {
    width: 60px;
    height: 60px;
  }
  .point_job_content {
    line-height: 16px;
    font-size: 13px;
    text-align: left;
  }
  .period_light_text {
    color: rgb(27 46 75 / 72%);
    width: 100%;
    font-size: 13px;
  }
  .job_title_text {
    margin: 0 0 0.5% 0;
    width: 70%;
    font-size: 13.5px;
  }
  .job_time_text {
    margin: 0 0 0.5% 0;
    font-size: 13px;
  }
  .title_time_contianer {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  .work_container {
    padding: 2%;
    margin: 0 0 3% 0;
    width: 48%;
  }
  .work_experience_container {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    align-items: stretch;
  }
  .education_info_contaier {
    width: 100%;
    margin: 20px 0 0;
    padding: 2%;
  }
  .sub_text_date {
    margin: 0;
    font-size: 15px;
  }
  .date_of_title_text {
    font-size: 17px;
    margin: 1% 0 0% 0;
  }
  .poster_container_img {
    width: 200px;
    display: block;
  }
  .poster_editpro_text {
    font-size: 25px;

    margin: 0;
    line-height: 31px;
  }
  .poster_extra_editpro_text {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
  .right_poster_extra_text {
    margin: 0 0 0 0%;
  }
  .right_side_poster_profile_container {
    width: 100%;
    margin: 0 auto 26px;
    display: flex;
    padding: 3% 30px 0%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .pooster_img_self {
    display: inline-block;
    margin: -60px 0 0;
  }
  .basic_info_address_contaier {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
  .basic_info_container {
    width: 48%;
    padding: 2%;
    margin: 15px 0px 0px;
  }
  .address_info_container {
    padding: 2%;
    margin: 15px 0 0 0%;
    width: 48%;
  }
  .prefferd_container {
    padding: 2%;

    margin: 25px 0;
    width: 100%;
  }
  .address_under_contianer {
    align-items: flex-start;
  }
  .edu_under_info_contianer {
    width: 100%;
    display: flex;
    margin: 1% auto;
    padding: 0%;
    flex-wrap: wrap;

    align-items: flex-start;
  }
}

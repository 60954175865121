.share_main_dialog_box_container {
}
.share_container {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 20px auto 60px;
  justify-content: space-evenly;
}
.share_link_tab {
  width: 64px;
}
.share_link_tab > img {
  width: 100%;
}

@media (max-width: 767px) {
  .share_link_tab {
    width: 30px;
  }
  .share_container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto;
    justify-content: space-evenly;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .share_link_tab {
    width: 39px;
  }
  .share_container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto;
    justify-content: space-evenly;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .share_link_tab {
    width: 50px;
  }
  .share_container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto;
    justify-content: space-evenly;
  }
}

@media (min-width: 1400px) {
  .share_link_tab {
    width: 62x;
  }
  .share_container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 30px auto 60px;
    justify-content: space-evenly;
  }
}

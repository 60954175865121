.main_ps_container {
}
.profile_two_btn_container {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 2% auto;
  justify-content: center;
}
.edit_profile_upload_pic {
  /* width: 35%; */
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #bebebe12;
  border: 1px solid #0000004f;
}
.two_btn_upload_rmove_container {
  display: flex;
  margin: auto 0 0 15%;
  flex-direction: column;
  justify-content: center;
}
.upload_remove_btn1 {
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  font-size: 20px;
  padding: 5px 0.9375rem;
  font-family: "IBMMed";
  border-radius: 0.25rem;
  font-family: IBMMed;
  /* display: inline-flex; */
  /* align-items: center; */
  /* justify-content: center; */
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  margin: 0 0 25% 0;
  border: 1px solid var(--theme-blue);
  background: transparent;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  /* /* width: 40%; */
}
.upload_remove_btn1:hover {
  background: var(--theme-blue);
  color: #fff;
}
.submit_btn_dialog_box {
  font-size: 21px;
  border-radius: 17px;
  padding: 10px 37px;
  border-radius: 4px;
  font-family: IBMMed;

  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  margin: 2% 0 5% 0;
  background: var(--theme-blue);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: 1px;
  outline: none;
  border: 0;
}
.two_input_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin: 6% auto 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.textarea_about_style {
  color: var(--theme-blue);
  padding: 1% 3% 1% 3%;
  height: 90px;
  border: 0;
  outline: none;
  width: 100%;
  font-family: "IBMMed";
  font-weight: 400;
  border-radius: 8px;
  font-size: 18px;
  background: rgba(249, 249, 249, 0.82);
  box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -webkit-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
  -moz-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
}
.textarea_about_style:focus {
  background-color: #ffffff;
  border-color: #333;
  border: 2px solid var(--theme-blue);
  border-radius: 8px;
}
.btn_submit_cntainer {
  display: flex;
  justify-content: flex-end;
  width: 93%;
  flex-direction: row;
}
.submit_btn_dialog_box:hover {
  background: #fff;
  outline: none;
  border: 1px solid var(--theme-blue);
  color: var(--theme-blue);
}
.acc_details_main_contianer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.acc_details_main_contianer1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92%;
  margin: 0 auto;
  justify-content: flex-start;
}

/* multi box */
.main_preffered_contianer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 90%;
  margin: 6% auto 0 auto;
}
.left_time_container {
  width: 50%;
}
.right_time_container {
  width: 50%;
}
.multibox_time_of_contact_contianer {
  width: 100%;
}
.checkbox_label_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0 0 4% 0;
}
.checkbox_self {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid var(--theme-blue);
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.checkbox_label {
  font-size: 18px;
  font-family: "IBMReg";
  color: var(--theme-blue);
  padding: 0 0 0 3%;
  cursor: pointer;
}
.checkbox_label_container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
  margin: 0 0 4% 0;
}
.wrap_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.heading_text_time {
  font-size: 20px;
  font-family: "IBMMed";
  color: var(--theme-blue);
  letter-spacing: 0.5px;
}

/* desired destination */

.main_destination_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 3% 0 0 0;
}
.checkbox_label_container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 25%;
  margin: 0 0 4% 0;
}
.edit_profile_uploaded_pic_container {
  width: 100px;
  display: flex;
  border-radius: 50%;
  flex-direction: row;
  align-items: center;
  margin: auto;
  position: relative;
}
.cancel_uploaded_pic_btn {
  position: absolute;
  right: 0;
  outline: none;
  border: 0;
  width: 20px;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 50%;
}

.edit_profile_uploaded_pic {
  width: 100%;
  border-radius: 50%;
}

.aspiration_dialogbox_container {
}
.delete_edit_btn_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.text_length_text {
  margin: 0;
  font-family: IBMMed;
  text-align: right;
}
.error_text {
  margin: 0;
  font-family: IBMMed;
  color: #a90000;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
}
/* acc dialog */

@media (max-width: 767px) {
  .text_length_text {
    margin: 4px 0 10px;

    font-size: 10px;
  }
  .error_text {
    font-size: 11px;
    line-height: 14px;
  }
  .delete_edit_btn_container {
    gap: 5px;
  }
  .aspiration_dialogbox_container {
    margin: 13px 0 0;
  }
  .main_destination_contianer {
    margin: 10px 0;
  }
  .checkbox_label_container2 {
    width: 50%;
  }

  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 6% 0 5% 0;
  }

  .right_time_container {
    width: 100%;
  }
  .checkbox_label {
    font-size: 13px;

    padding: 0 0 0 3%;
  }
  .checkbox_self {
    width: 13px;
    height: 13px;
  }
  .heading_text_time {
    font-size: 14px;
  }
  .left_time_container {
    width: 100%;
  }
  .main_preffered_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 95%;
    margin: 6% auto 0 auto;
  }
  .edit_profile_uploaded_pic_container {
    width: 100px;
    border: 1px solid #0017392e;
    margin: auto;
    position: relative;
    height: 100px;
  }
  .cancel_uploaded_pic_btn {
    top: 5px;
    right: 5px;

    position: absolute;

    outline: none;
    border: 0;
    width: 20px;
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;
    border-radius: 50%;
  }

  .edit_profile_uploaded_pic {
    width: 100%;
  }
  .two_input_container {
    margin: 0% auto;
    width: 95%;
  }
  .textarea_about_style {
    font-size: 13px;
  }
  .edit_profile_upload_pic {
    width: 85px;
    height: 85px;
    margin: auto;
  }
  .two_btn_upload_rmove_container {
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  .profile_two_btn_container {
    width: 100%;
    margin: 3% 0;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: normal;
  }
  .acc_details_main_contianer {
    width: 100%;
    margin: 20px auto 10px;
  }
  .submit_btn_dialog_box {
    font-size: 15px;
    padding: 8px 35px;
    outline: none;
    border: 0;
    border-radius: 4px;
  }
  .upload_remove_btn1 {
    font-size: 12px;
    padding: 5px;

    margin: 0;
    width: 40%;
  }
  .btn_submit_cntainer {
    display: flex;
    justify-content: flex-end;
    width: 98%;
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .text_length_text {
    margin: 4px 0 10px;

    font-size: 12px;
  }
  .error_text {
    font-size: 13px;
    line-height: 15px;
  }
  .checkbox_label_container1 {
    margin: 0 0 0% 0;
  }

  .aspiration_dialogbox_container {
    margin: 13px 0 0;
  }
  .main_destination_contianer {
    margin: 10px 0;
  }
  .checkbox_label_container2 {
    margin: 0 0 1% 0;

    width: 50%;
  }

  .work_under_things_container {
    display: flex;
    flex-direction: row;
    margin: 6% 0 5% 0;
  }

  .right_time_container {
    width: 100%;
  }
  .checkbox_label {
    font-size: 13px;

    padding: 0 0 0 3%;
  }
  .checkbox_self {
    width: 13px;
    height: 13px;
  }
  .heading_text_time {
    font-size: 14px;
  }
  .left_time_container {
    width: 100%;
  }
  .checkbox_label_container {
    margin: 0 0 1% 0;
  }
  .main_preffered_contianer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 95%;
    margin: 2% auto 0 auto;
  }
  .edit_profile_uploaded_pic_container {
    width: 100px;
    height: 100px;
    border: 1px solid #0017392e;
    margin: auto;
    position: relative;
  }
  .cancel_uploaded_pic_btn {
    top: 5px;
    right: 5px;

    position: absolute;

    outline: none;
    border: 0;
    width: 20px;
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;
    border-radius: 50%;
  }

  .edit_profile_uploaded_pic {
    width: 100%;
  }
  .two_input_container {
    margin: 0% auto;
    width: 95%;
  }
  .textarea_about_style {
    font-size: 14px;
    line-height: 20px;
  }
  .edit_profile_upload_pic {
    width: 100px;
    margin: auto;
  }
  .two_btn_upload_rmove_container {
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  .profile_two_btn_container {
    width: 100%;
    margin: 3% 0;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: normal;
  }
  .acc_details_main_contianer {
    width: 95%;
    margin: 20px auto 0px;
  }

  .upload_remove_btn1 {
    font-size: 12px;
    padding: 5px;

    margin: 0;
    width: 40%;
  }
  .submit_btn_dialog_box {
    font-size: 15px;
    padding: 8px 35px;
    outline: none;
    border: 0;
    border-radius: 4px;
  }
  .btn_submit_cntainer {
    width: 100%;
  }
}

@media (min-width: 991px) {
  .cancel_uploaded_pic_btn {
    top: 5px;
    right: 10px;
  }
  .text_length_text {
    margin: 4px 0 10px;

    font-size: 14px;
  }
  .error_text {
    font-size: 15px;
    line-height: 16px;
    margin: 0 0 0 5px;
  }
  .edit_profile_uploaded_pic_container {
    width: 130px;
    height: 130px;
  }
  .upload_remove_btn1 {
    font-size: 19px;
    padding: 8px;
    margin: 10px 0;
    width: 100%;
  }
  .two_btn_upload_rmove_container {
    display: flex;
    margin: 10px 60px;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .edit_profile_upload_pic {
    width: 160px;
    margin: 0;
  }
  .profile_two_btn_container {
    width: 70%;
    margin: 3% auto;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .right_time_container {
    width: 90%;
  }
  .submit_btn_dialog_box {
    font-size: 17px;
    padding: 5px 25px;
    outline: none;
    border: 0;
    border-radius: 4px;
  }
  .heading_text_time {
    font-size: 19px;
  }
  .checkbox_self {
    width: 16px;
    height: 16px;
  }
  .checkbox_label {
    font-size: 15px;
    padding: 0 0 0 2%;
  }
}

@media (min-width: 1200px) {
  .edit_profile_uploaded_pic_container {
    width: 140px;
    height: 140px;
}
  .error_text {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 0 9px;
  }

  .aspiration_dialogbox_container {
    margin: 10px 10px 0;
  }
}


@media (min-width: 1400px) {
  .edit_profile_uploaded_pic_container {
    width: 160px;
    height: 160px;
}
}

/*  */
.error {
  color: red;
  font-size: 0.875em;
  margin-top: 0.25em;
}

 .is-invalid {
  border: 1px solid red;
}
.conectin_under_left_side_contianer {
  margin: 0 0 0 auto;
  width: 93%;
}

.pagination_event_tab_container {
  margin: 20px auto 2rem auto;
  width: 80%;

  /* display: flex; */
  display: grid;
  place-items: center;
  /* margin:0 51px 5rem 0;
  background:red; */
}
.event_main_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
}
.event_box_container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
  width: 45%;
  margin: 0 0 2% 2%;
}
.event_img_contianer {
}
.event_img_self {
  /* width: 100%; */
  height: 150px;
  object-fit: cover;
  width: 100%;
}
.event_content_container {
  font-size: 13px;
  padding: 20px;
  flex: 1 1 auto;
  min-height: 1px;
  border: 1px solid rgba(72, 94, 144, 0.16);
}
.title_event_text {
  font-family: IBMMed;
  margin: 0;
  font-size: 15px;
}
.subtitle_event_text {
  font-family: IBMReg;
  margin: 0;
  font-size: 13px;
  color: rgb(131, 146, 165);
}
.address_event_text {
  font-family: IBMReg;
  margin: 0;
  font-size: 13px;
  color: rgb(131, 146, 165);
}
.interested_contianer {
  border-radius: 0px 0px calc(0.25rem - 1px) calc(0.25rem - 1px px);
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: rgba(72, 94, 144, 0.16);
  border-image: initial;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  padding: 3%;
}
.total_event_interest_text {
  color: rgb(131, 146, 165);
  font-family: IBMReg;
  font-size: 13px;
  margin: 0;
}
.same_interest_btn {
  font-family: IBMReg;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  outline: none;

  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  padding: 5px 0.9375rem;
  border-radius: 0.25rem;
}
.uninterested_btn {
  background-color: var(--theme-blue);
  color: #fff;
  font-size: 12px;
  border: 0;
}
.interested_btn {
  font-size: 12px;
  background: transparent;
  border: 1px solid var(--theme-blue);
}
.interested_btn:hover,
.interested_btn:active {
  background-color: var(--theme-blue);
  color: #fff;
  border: 1px solid var(--theme-blue);
}
.uninterested_btn:hover,
.uninterested_btn:active {
  color: var(--theme-blue);
  background-color: #fff;
  border: 1px solid var(--theme-blue);
}

@media (max-width: 767px) {
  .event_main_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .event_box_container {
    margin: 2% 1px 2% 1%;
    width: 100%;
  }
  .event_img_self {
    width: 100%;
    height: 150px;
    object-fit: fill;
  }
  .event_content_container {
    padding: 10px;
  }
  .title_event_text {
    font-size: 14px;
  }
  .address_event_text {
    font-size: 12px;
  }
  .subtitle_event_text {
    font-size: 11px;
  }
  .total_event_interest_text {
    font-size: 12px;
  }
  .interested_btn,
  .uninterested_btn {
    padding: 3px 13px;
  }
}

@media (min-width: 768px) {
  .event_main_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .event_box_container {
    margin: 0% auto 15px 0;
    width: 48%;
  }
  .event_img_self {
    width: 100%;
    height: 150px;
    object-fit: fill;
  }
  .event_content_container {
    padding: 10px;
  }
  .title_event_text {
    font-size: 14px;
  }
  .address_event_text {
    font-size: 12px;
  }
  .subtitle_event_text {
    font-size: 11px;
  }
  .total_event_interest_text {
    font-size: 12px;
  }
  .interested_btn,
  .uninterested_btn {
    padding: 3px 13px;
  }
}

@media (min-width: 1200px) {
  .event_content_container {
    padding: 10px;
  }
  .title_event_text {
    font-size: 16px;
  }
  .address_event_text {
    font-size: 15px;
  }
  .subtitle_event_text {
    font-size: 13px;
  }
  .total_event_interest_text {
    font-size: 15px;
  }
  .interested_btn,
  .uninterested_btn {
    padding: 5px 18px;
    font-size: 16px;
  }
}

.document_main_container {
}
.link_close_btn_container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 1px 4px;
  border-radius: 5px;
}
.close_btn_doc_cancel{
  background: transparent;
  border: 0;
  outline: none;
}
.close_btn_doc_cancel:hover{
 color:var(--theme-red);
}
.tooltip1 {
  /* display:inline-block;
  position:relative;
  border-bottom:1px dotted #666;
  text-align:left; */
  font-size: 14px;
  display: flex;
    position: relative;
    text-align: left;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
}

.tooltip1 h3 {margin:12px 0;}

.tooltip1 .bottom1 {
  width: auto;

  /*max-width:400px;*/
  top:40px;
  left:50%;
  transform:translate(-50%, 0);
  padding:5px 10px;
  color:#fff;
  background-color:#000000c5;
  font-weight:normal;
  font-size:13px;
  border-radius:8px;
  position:absolute;
  z-index:1;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  display:none;
}

.bottom1 > span{
  font-size: 13px;
  line-height: 12px;
}
.tooltip1:hover .bottom1 {
  display:block;
}

.tooltip1 .bottom1 img {
  width:400px;
}

.tooltip1 .bottom1 i {
  position:absolute;
  bottom:100%;
  left:50%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.tooltip1 .bottom1 i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,50%) rotate(45deg);
;
  background-color:#000000c5;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}


.content_doc_main_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.document_box_container {
  width: 48%;
  background: #fff;
  padding: 20px;
  display: flex;
  border: 6px;
  margin: 15px 0px;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
}
.title_of_document_box {
  margin: 0;
  color: var(--theme-blue);
  font-family: "IBMBold";
  font-size: 18px;
  letter-spacing: 1px;
}
.subtitle_of_document_text {
  margin: 0px 0px 17px;
  color: rgba(27, 46, 75, 0.56);
  font-size: 14px;
  line-height: 18px;
}
.content_grey_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(27, 46, 75, 0.14);
  padding: 9px 20px;
  border-radius: 4px;
  color: var(--theme-blue);
  font-family: "IBMMed";
  margin: 0 0 28px;
}
.content_grey_container > p {
  margin: 0;
  font-size: 13px;
}
.md_clear_icon {
  font-size: 18px;
}
.upload_item_btn_container > button {
  font-family: "IBMMed";
  width: 48%;
  border: 0;
  background: rgba(217, 217, 217, 1);
  height: 45px;
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;

  align-items: center;
  color: var(--theme-blue);
  justify-content: center;
  text-transform: uppercase;
}

.upload_item_btn_container > button:hover {
  background: var(--theme-blue);
  color: #fff;
}
.upload_item_btn_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.upload_item_btn_container > select{
  font-size: 15px;
}


@media (max-width: 299px) {
  .title_of_document_box {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .document_box_container {
    width: 100%;
    background: #fff;
    padding: 16px;
    display: flex;
    border: 6px;
    margin: 12px 0px;
    flex-direction: column;
    gap: 4px;
  }
  .content_grey_container {
    padding: 9px 7px;

    margin: 0 0 23px;
  }
  .content_grey_container > p {
    margin: 0;
    font-size: 10px;
  }
  .upload_item_btn_container > button {
    width: 54%;
    border: 0;

    height: 45px;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 12px;

    justify-content: center;
    text-transform: uppercase;
  }
  .subtitle_of_document_text {
    margin: 0px 0px 17px;

    font-size: 13px;
    line-height: 16px;
  }
  .md_clear_icon {
    font-size: 15px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .title_of_document_box {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .document_box_container {
    width: 100%;
    background: #fff;
    padding: 16px;
    display: flex;
    border: 6px;
    margin: 12px 0px;
    flex-direction: column;
    gap: 4px;
  }
  .content_grey_container {
    padding: 9px 7px;

    margin: 0 0 23px;
  }
  .content_grey_container > p {
    margin: 0;
    font-size: 12px;
  }
  .upload_item_btn_container > button {
    width: 38%;
    border: 0;
    height: 39px;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 12px;
    justify-content: center;
    text-transform: uppercase;
  }
  .subtitle_of_document_text {
    margin: 0px 0px 17px;
    font-size: 14px;
    line-height: 18px;
  }
  .md_clear_icon {
    font-size: 15px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .title_of_document_box {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .document_box_container {
    width: 100%;
    background: #fff;
    padding: 16px;
    display: flex;
    border: 6px;
    margin: 12px 0px;
    flex-direction: column;
    gap: 4px;
  }
  .content_grey_container {
    padding: 9px 7px;

    margin: 0 0 23px;
  }
  .content_grey_container > p {
    margin: 0;
    font-size: 12px;
  }
  .upload_item_btn_container > button {
    width: 38%;
    border: 0;
    height: 39px;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 12px;
    justify-content: center;
    text-transform: uppercase;
  }
  .subtitle_of_document_text {
    margin: 0px 0px 17px;
    font-size: 14px;
    line-height: 18px;
  }
  .md_clear_icon {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .document_box_container {
    width: 100%;
  }
  .upload_item_btn_container > button {
    font-family: "IBMMed";
    width: 30%;
  }
}

@media (min-width: 991px) {
  .subtitle_of_document_text {
    margin: 0px 0px 8px;
  }
  .document_box_container {
    width: 48%;
    background: #fff;
    padding: 16px;
    display: flex;
    border: 6px;
    margin: 15px 0px;
    flex-direction: column;
    gap: 8px;
  }
  .upload_item_btn_container > button {
    font-family: "IBMMed";
    width: 54%;
    height: 40px;
    font-size: 13px;
  }
  .content_grey_container {
    padding: 12px 11px;
    border-radius: 4px;

    margin: 0 0 28px;
  }
}

@media (min-width: 1200px) {
  .upload_item_btn_container > button {
    width: 38%;
    height: 40px;
    font-size: 13px;
  }
}

.search_result_expansion_container {
  position: absolute;
  background-color: rgb(255 255 255);
  width: 100%;
  top: 45px;
  left: 0;
  right: 0;
  border-radius: 17px 0 0 17px;
  min-height: 70px;
  z-index: 1;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 6px -3px rgb(0 0 0 / 48%);
  -webkit-box-shadow: 0px 8px 6px -3px rgb(0 0 0 / 48%);
  -moz-box-shadow: 0px 8px 6px -3px rgb(0 0 0 / 48%);
}
.search_result_one_item_container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 10px 0;
  border: 0;
  background-color: transparent;
  width: 100%;
}
.search_icon1_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--theme-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_icon1_container > svg {
  color: #fff;
  padding: 1px;
}
.item_text_self {
  font-family: IBMMed;
  font-size: 15px;
  color: var(--theme-blue);
}

@media (max-width: 767px) {
  .search_result_expansion_container {
    border-radius: 17px;
    min-height: 70px;
    padding: 0 0 10px;
  }
  .search_icon1_container {
    width: 20px;
    height: 20px;
  }
  .search_icon1_container > svg {
    padding: 0px;
    font-size: 10px;
  }
  .item_text_self {
    font-size: 12px;
    text-align: left;
    line-height: 14px;
    width: 82%;
  }
}

@media (min-width: 768px) {
  .search_result_expansion_container {
    padding: 0 0 10px;
  }
  .item_text_self {
    font-size: 13px;
  }
  .search_icon1_container {
    width: 25px;
    height: 25px;
  }
  .search_icon1_container > svg {
    padding: 1px;
    font-size: 13px;
  }
}

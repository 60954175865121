.right_under_container {
  margin-left: auto;
  width: 90%;
}
.top_university_container {
  width: 100%;
  margin-bottom: 15%;
}
.top_university_text_content_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}
.top_university_view_container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.top_univer_text {
  font-size: 13px;
  text-transform: uppercase;
  color: var(--theme-blue);
  font-family: IBMSemBold;
}
.view_univer_text {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: IBMReg;
  color: rgb(131, 146, 165);
  background: transparent;
  border: 0;
  transition: var(--transition);
}
.view_univer_text:hover {
  font-family: IBMMed;
  color: var(--theme-blue);
}
.university_img {
  width: 30px;
  height: 30px;

  border-radius: 50%;
  border: 1px solid #00000052;

  /* border-radius: 50%; */
}
.uni_location_content {
  margin-left: 10px;
  outline: none;
  border: 0;
  background: transparent;
}
.uniersity_name {
  margin-bottom: 3px;
  font-family: IBMMed;
  text-align: left;
  font-size: 13px;
  color: var(--theme-blue);
}
.location_name {
  display: block;
  color: rgb(131, 146, 165);
  font-size: 12px;
  font-family: IBMReg;
  text-align: left;
}
.top_university_text_content_contianer1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}
.university_img1 {
  width: 40px;
  height: 40px;
}
.location_name1 {
  display: block;
  color: rgb(131, 146, 165);
  font-size: 12px;
  font-family: IBMReg;
  text-align: left;
  width: 90%;
}
.photos_gallery_container {
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 100px 0;
}
.per_photo_contianer {
  width: 33.33%;
  border: 1px solid #00000061;
  margin: 0;
  border-radius: 5px;
}
.photo_click_contaier {
  display: block;
  position: relative;
}
.photo_gallery {
  width: 100%;
  border: 1px solid #fff;
  object-fit: cover;
  background: rgb(207 207 207 / 5%);
}
.location_name {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .right_under_container {
    margin: 0 auto 0 auto;
    width: 90%;
  }
  .top_univer_text {
    font-size: 12px;
  }

  .view_univer_text {
    font-size: 12px;
  }
  .top_university_text_content_contianer {
    margin-bottom: 9px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .university_img {
    width: 25px;
    height: 25px;
  }
  .uniersity_name {
    margin-bottom: 3px;

    font-size: 11px;
  }
  .location_name {
    font-size: 10px;
  }
  .top_university_container {
    width: 100%;
    margin-bottom: 4%;
  }
  .top_university_view_container {
    margin-bottom: 7px;
  }
  .location_name1 {
    font-size: 10px;

    line-height: 12px;
  }
  .top_university_text_content_contianer1 {
    justify-content: flex-start;
  }
  .photo_gallery {
    width: 100%;
    border: 1px solid #fff;

    height: 85px;
  }
}

@media screen and (min-width: 768px) {
  .right_under_container {
    margin-left: auto;
    width: 97%;
    margin-right: auto;
    margin-top: 3%;
    /* background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
    padding: 2%;
  }
  .top_university_view_container {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .top_university_container {
    width: 100%;
    margin-bottom: 2%;
  }
  .top_university_text_content_contianer1 {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
  }
  .location_name1 {
    display: block;
    color: rgb(131, 146, 165);
    font-size: 12px;
    font-family: IBMReg;
    text-align: left;
    width: 100%;
  }
  .photo_gallery {
    width: 100%;
    height: 118px;
    border: 1px solid #fff;
  }
}

@media screen and (min-width: 991px) {
  .uniersity_name {
    font-size: 11px;
  }
  .view_univer_text {
    font-size: 12px;
  }
  .photo_gallery {
    width: 100%;
    height: 78px;
    border: 1px solid #fff;
  }
  .right_under_container {
    margin: 3% auto 0 0;
    position: fixed;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    padding: 0px 1% 15px;
    bottom: 5px;
    /* right: 8px; */
    width: 260px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #0000002b;
  }
}

@media screen and (min-width: 1200px) {
  .top_university_text_content_contianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 11px;
  }
  .uniersity_name {
    margin-bottom: 3px;
    text-align: left;
    font-size: 12px;
    line-height: 13px;
  }
  .university_img {
    width: 33px;
    height: 33px;
  }
  .location_name {
    display: block;
    color: rgb(131, 146, 165);
    font-size: 12px;

    font-family: IBMReg;
    text-align: left;
  }
  .top_university_container {
    width: 100%;
    margin-bottom: 5%;
  }
  .location_name1 {
    display: block;
    color: rgb(131, 146, 165);
    font-size: 13px;
    font-family: IBMReg;
    text-align: left;
    width: 100%;
  }
  .photo_gallery {
    width: 100%;
    height: 89px;
    border: 1px solid #fff;
  }
  .right_under_container {
    position: fixed;
    border: 1px solid #0000002b;
    border-radius: 5px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    bottom: 5px;
    width: 300px;

    overflow: hidden;
    overflow-y: auto;
    margin: 3% auto 0 0;
    /* background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
    padding: 0 1% 0;
  }
}

@media screen and (min-width: 1400px) {
  .right_under_container {
    width: 362px;
  }
}

.text_input_container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.resend_btn_self {
  border: 1px solid rgb(4, 40, 147);

  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);

  border-radius: 0.25rem;
}
.contact_btn_self {
  border: 1px solid rgb(192, 204, 218);

  color: rgba(27, 46, 75, 0.7);
  background-color: rgb(255, 255, 255);

  margin-left: 5px;
}
.same_btn_style {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

@media screen and (max-width: 767px) {
  .text_input_container1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .resend_btn_self {
    width: 90%;
  }
  .contact_btn_self {
    width: 90%;
    margin: 3% 0 0 0;
  }
}

@media screen and (min-width: 768px) {
  .text_input_container1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .resend_btn_self {
    width: 90%;
  }
  .contact_btn_self {
    width: 90%;
    margin: 3% 0 5% 0;
  }
}

@media screen and (min-width: 991px) {
  .text_input_container1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .resend_btn_self {
    border: 1px solid rgb(4, 40, 147);

    color: rgb(255, 255, 255);
    background-color: rgb(4, 40, 147);

    border-radius: 0.25rem;
  }
  .contact_btn_self {
    border: 1px solid rgb(192, 204, 218);

    color: rgba(27, 46, 75, 0.7);
    background-color: rgb(255, 255, 255);
    margin: 3% 0 3% 0;
    margin-left: 5px;
  }
  .same_btn_style {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.5;
    user-select: none;
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }
}

.collapse_container {
}

.card_body {
}

.profile_txtinput_emoiji_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* background: red; */
  width: 94%;
  margin: 2% auto 3% auto;
  position: relative;
}

.profile_avtar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #00000054;
}

.input_happy_container {
  background: #c9bebe47;
  border-radius: 40px;
  width: 95%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 1% 0 3%;
  margin-left: 3%;
}

.emoji_btn {
  background: transparent;
  border: 0;
  outline: none;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji_btn:hover {
  background: #c9bebe47;
}

.comment_text_input {
  background: transparent;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  font-family: IBMReg;
}

.emoji_icon {
  font-size: 20px;
  color: var(--theme-blue);
}

.emoiji_contianer {
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  z-index: 5;
}
.profile_and_comment_container {
  display: flex;
  padding: 0 10px;
}
.pro_pic_container {
  width: 12%;
}
.profile_container {
}
.profile_container > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #00000054;
}
.text_content_container {
  width: 87.5%;
  color: rgb(0 23 55);
  background: #80808017;
  padding: 6px 12px 10px;
  border-radius: 5px;
  margin: 0 0 15px;
}
.text_content_container > strong {
  font-size: 14px;
  font-family: "IBMBold";
  line-height: 15px;
}
.text_content_container > p {
  font-family: "IBMReg";
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .profile_container > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .profile_and_comment_container {
    padding: 0 10px;
    gap: 3px;
  }
  .pro_pic_container {
    width: 12%;
  }
  .text_content_container {
    width: 88%;
    color: rgb(0 23 55);
    background: #80808017;
    padding: 4px 12px 11px;
    border-radius: 5px;
    margin: 0 0 9px;
  }
  .text_content_container > strong {
    font-size: 11px;

    line-height: 14px;
  }
  .text_content_container > p {
    font-size: 10px;
    line-height: 13px;

    margin: 0;
  }
  .profile_avtar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .input_happy_container {
    width: 95%;
    height: 35px;
    padding: 0 1% 0 3%;
    margin-left: 2%;
  }
  .comment_text_input {
    font-size: 11px;
  }
  .emoji_icon {
    font-size: 17px;
  }
}

@media screen and (min-width: 768px) {
  .pro_pic_container {
    width: 10%;
  }
  .profile_container > img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .text_content_container {
    width: 90%;
    padding: 9px 14px 10px;
  }
}

@media screen and (min-width: 991px) {
  .profile_and_comment_container {
    display: flex;
    padding: 0 10px;
    gap: 6px;
  }
  .text_content_container {
    width: 89%;
    padding: 8px 11px 10px;
  }
  .pro_pic_container {

    width: 11%;

  }
  .profile_container > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .profile_avtar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .input_happy_container {
    padding: 0 1% 0 6%;
    height: 40px;
  }
  .emoji_icon {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .profile_container > img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
}
  .pro_pic_container {

    width: 11%;

  }
  .text_content_container {
    width: 89%;
    padding: 8px 11px 10px;
  }
  .profile_and_comment_container {
    display: flex;
    padding: 0 10px;
    gap: 0px;
  }
  .profile_avtar {
    width: 35px;
    height: 35px;
  }
  .emoji_icon {
    font-size: 19px;
  }
}

@media (min-width: 1400px) {
  .text_content_container {
    width: 90%;
    padding: 8px 11px 10px;
}
  .profile_and_comment_container {
    display: flex;
    padding: 0 10px;
    gap: 2px;
  }
  .pro_pic_container {
    width: 10%;
  } 
}

.conn_following_box_contianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px px solid #000;
  border: 1px solid rgba(72, 94, 144, 0.16);
  /* border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; */
  border-radius: 0.25rem;
  padding: 2%;
  width: 23%;
  margin: 0 10px 20px 0;
}
.following_img {
  object-fit: cover;
  border: 1px solid #0000004a;
  width: 36%;
  border-radius: 50%;
  margin: 0px 0 15px 0;
}
.conn_img_title_subtitle_container{

}
.title_follow {
  text-transform: capitalize;
}
.sub_title_follow_text {
  color: rgb(131, 146, 165);
  font-size: 13px;
  margin: 0;
}
.follow_btn {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(192, 204, 218);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgba(27, 46, 75, 1);
  background-color: rgb(255, 255, 255);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  outline: none;
}
.follow_btn1 {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(192, 204, 218);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: #fff;
  background-color: rgba(27, 46, 75, 1);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  outline: none;
}
.follow_btn:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(27, 46, 75, 1);
  border: 0;
}
.title_follow {
  font-family: "IBMMED";
  margin: 0;
}

@media (max-width: 767px) {
  .conn_following_box_contianer {
    justify-content: space-between;
    padding: 3%;
    width: 46%;
    margin: 10px 5px 0px 5px;
   
    min-height: 130px;
    max-height: 160px;

  }
  .following_img {
   width: 40px;
   height: 40px;
   margin: 0px 0 8px 0;
  }
  .title_follow {
    font-size: 11px;
    line-height: 13px;
  }
  .sub_title_follow_text {
    font-size: 10px;
    line-height: 18px;
  }
  .follow_btn,
  .follow_btn1 {
    padding: 4px 0px;
    font-size: 10px;
    margin: 6px 0 0;
  }
}

@media (min-width: 768px) {
  .conn_following_box_contianer {
    justify-content: space-between;
    padding: 2%;
    width: 23%;
    margin: 10px 5px 0px 5px;

    min-height: 150px;
    max-height: 160px;
  }
  .following_img {
    width: 38px;
    height: 38px;
    margin: 3px 0 8px 0;
  }
  .title_follow {
    font-size: 12px;
   
    line-height: 18px;
  }
  .sub_title_follow_text {
    font-size: 12px;
    line-height: 18px;
  }
  .follow_btn,
  .follow_btn1 {
    padding: 5px 0px;
    font-size: 12px;
    font-family: "IBMMED";
    margin: 9px 0 0;
  }
}

@media (min-width: 991px) {
  .following_img {
    width: 45px;
    height: 45px;
    margin: 3px 0 5px 0;
  }
  .sub_title_follow_text {
    font-size: 13px;
    line-height: 18px;
}
  .title_follow {
    font-size: 14px;
    font-family: "IBMMED";
    line-height: 17px;
  }
  .conn_following_box_contianer {
    padding: 3%;
    width: 31%;
    margin: 10px 6px 0px 6px;
    min-height: 160px;
    max-height: 165px;
  }
  .follow_btn,
  .follow_btn1 {
    padding: 5px 0px;
    font-size: 13px;
    font-family: "IBMMED";
    margin: 9px 0 0;
  }
}

@media (min-width: 1200px) {
  .following_img {
    width: 50px;
    height: 50px;
 
  }
  .title_follow {
    font-size: 14px;
 
    line-height: 20px;
  }
  .sub_title_follow_text {
    font-size: 12px;
    line-height: 18px;
  }
  .conn_following_box_contianer {
      /* padding: 3%; */
      width: 31%;
      margin: 19px 8px 0px 8px;
      /* min-height: 155px; */
      max-height: 200px;
  }
  .follow_btn,
  .follow_btn1 {
    padding: 5px 0px;
    font-size: 14px;


    margin: 9px 0 0;
  }
}

.course_main_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.course_box_container {
  width: 31.33%;
  margin: 0 2% 2% 0;
  background: #fff;
  border-radius: 6px;
  padding: 2% 2% 2% 2%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
  outline: none;
  color: rgba(27, 46, 75, 1);
  border: 1px solid rgba(27, 46, 75, 0.342);
}
.course_box_container:hover {
  /* border: 0;
  color: #fff;
  background-color: rgba(27, 46, 75, 1); */
}

.start_date_text {
  font-size: 13px;
  font-family: IBMSemBold;
  margin: 0;
}
.sub_start_text {
  font-size: 12px;
  font-family: IBMReg;
  margin: 0;
}
.course_text_title {
  font-size: 15px;
  font-family: IBMSemBold;
  margin: 4% 0 1% 0;
  letter-spacing: -0.5px;
}
.course_univer_txt {
  font-size: 14.5px;
  font-family: "IBMMED";
  margin: 0;
}
.course_country_text {
  color: rgba(175, 169, 169, 1);
  font-size: 13.5px;
  font-family: IBMMed;
  margin: 0;
}
.course_like_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
  width: 100%;
}
.apply_now_btn {
  border: 1px solid rgb(27 46 75 / 82%);
  color: var(--theme-blue);
  font-family: "IBMMED";
  background: #fff;
  padding: 5px 0;
  margin: 10px 0 0;
  font-size: 16px;
  border-radius: 4px;
  transition: var(--transition);
}
.apply_now_btn:hover {
  background-color: var(--theme-blue);
  color: #fff;
}

@media (max-width: 299px) {
  .course_like_btn {
  
    flex-wrap: wrap;
}
  .apply_now_btn {
    padding: 5px 0;
    margin: 10px 0 0;
    font-size: 12px;
    border-radius: 4px;
  }
  .course_box_container {
    width: 100%;
    margin: 0 10px 10px 0;
    padding: 3%;
  }
  .course_text_title {
    font-size: 13px;
    line-height: 16px;
    margin: 8px 0 6px 0;
    letter-spacing: 0px;
  }
  .course_univer_txt {
    font-size: 13.5px;
    margin: 0;
    line-height: 16px;
  }
  .course_country_text {
    font-size: 13.5px;
    margin: 0;
    line-height: 16px;
    margin: 0 0 9px 0;
  }
  .start_date_text {
    font-size: 11px;
  }
  .sub_start_text {
    font-size: 10px;

    margin: 0;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .apply_now_btn {
    padding: 5px 0;
    margin: 10px 0 0;
    font-size: 12px;
    border-radius: 4px;
  }
  .view_details_heading_text1 {
    font-size: 17px;
    margin: 0 0 3% 2%;
  }
  .course_box_container {
    width: 100%;
    margin: 0 10px 10px 0;
    padding: 3%;
  }
  .course_text_title {
    font-size: 14px;
    line-height: 16px;
    margin: 1px 0 6px 0;
    letter-spacing: 0px;
  }
  .course_univer_txt {
    font-size: 13.5px;
    margin: 0;
    line-height: 16px;
  }
  .course_country_text {
    font-size: 13.5px;
    margin: 0;
    line-height: 16px;
    margin: 0 0 9px 0;
  }
  .start_date_text {
    font-size: 12px;
  }
  .sub_start_text {
    font-size: 11px;

    margin: 0;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .apply_now_btn {
    padding: 6px 0;
    margin: 10px 0 0;
    font-size: 12px;
    border-radius: 4px;
  }
  .course_box_container {
    width: 100%;
    margin: 0 10px 10px 0;
    padding: 4%;
  }
  .course_text_title {
    font-size: 15px;
    margin: 3px 0 10px 0;
    letter-spacing: 0px;
  }
  .course_univer_txt {
    font-size: 12.5px;

    margin: 0;
    line-height: 14px;
  }
  .sub_start_text {
    font-size: 13px;
  }
  .course_country_text {
    font-size: 11.5px;

    margin: 0;
  }
  .start_date_text {
    font-size: 14px;

    margin: 0;
  }
}

@media (min-width: 768px) {
  .apply_now_btn {
    font-size: 12px;
  }
  .course_like_btn {
   
    margin: 10px 0 0 0;
    width: 100%;
    gap: 5px;
}
}

@media (min-width: 991px) {
  .apply_now_btn {
    font-size: 12px;
  }
  .course_box_container {
    width: 31.33%;
    margin: 0 10px 10px 0;
    padding: 2%;
  }
  .course_text_title {
    font-size: 16px;
    margin: 2px 0 10px 0;
    letter-spacing: 0px;
  }
  .course_univer_txt {
    font-size: 12.5px;

    margin: 0;
    line-height: 14px;
  }
  .sub_start_text {
  
    font-size: 13px;
  
    margin: 3px 0;

  }
  .start_date_text {
    font-size: 14px;

    margin: 0;
  }
  .course_country_text {
    font-size: 11.5px;

    margin: 0;
  }
}

@media (min-width: 1200px) {
  .apply_now_btn {
    font-size: 16px;
  }
  .start_date_text {
    font-size: 15px;
    margin: 10px 0 2px;
    line-height: 19px;
  }
  .sub_start_text {
    font-size: 14px;
    margin: 0;
  }
  .course_box_container {
    width: 31.33%;
    margin: 0 10px 10px 0;
    padding: 2%;
  }
  .course_text_title {
    font-size: 17px;
    line-height: 21px;
    margin: 0px 0 1px 0;
    letter-spacing: 0px;
  }
  .course_univer_txt {
    font-size: 15.5px;
    margin: 10px 0 0;
    line-height: 17px;
  }
  .course_country_text {
    font-size: 14.5px;
    margin: 0;
    line-height: 20px;
  }
}

@media (min-width: 1400px) {
  .course_text_title {
    font-size: 17.5px;
    line-height: 19px;
  }
  .course_univer_txt {
    font-size: 16.5px;

    line-height: 17px;
  }
  .course_country_text {
    font-size: 15.5px;

    line-height: 20px;
  }
  .start_date_text {
    font-size: 15px;
    margin: 9px 0 2px;
    line-height: 18px;
  }
  .sub_start_text {
    font-size: 14px;

    line-height: 16px;
  }
}

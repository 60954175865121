.intro_page_main_container {
}
.intro_under_page_container {
  margin: 0 auto;
}
.intro_all_content_container {
}
.name_text_ps_ {
  font-family: IBMMed;
  margin: 5px 0;
  font-size: 18px;
  line-height: 37px;
}
.lightname_text_ps_ {
  font-family: IBMReg;
  margin: 0px 0 10px;
  font-size: 18px;
  line-height: 60px;
}
.ps_all_input_container {
}
.gender__btn_container {
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.gender_btn_self {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  border: 0;
  border-radius: 3px;
}
.gender_btn_self_selected {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  border: 0;
  border-radius: 3px;
  color: #fff;
  background-color: var(--theme-blue);
}
.gender_btn_self:hover {
  border: 1px solid var(--theme-blue);
}
.input_text_ps_container {
  font-family: "IBMMED";
  margin: 5px 0;
  font-size: 18px;
  line-height: 35px;
  color: var(--theme-blue);
}
.text_input_ps {
  border-bottom: 1px solid var(--theme-blue);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin: 0 15px;
  font-family: "IBMMed";
  font-size: 17px;
  outline: none;
}
.next_btn_container_ps {
  margin: 40px 0 0;
}

@media (max-width: 299px) {
  .gender_btn_self {
    padding: 11px 8px;

    font-size: 12px;
    line-height: 15px;
  }
  .gender_btn_self_selected {
    padding: 11px 8px;

    font-size: 12px;
    line-height: 15px;
  }
  .intro_under_page_container {
    width: 94%;
  }
  .name_text_ps_ {
    margin: 5px 0;
    font-size: 15px;
    line-height: 22px;
  }
  .lightname_text_ps_ {
    margin: 0px 0 0px;
    font-size: 13px;
    line-height: 17px;
  }
  .input_text_ps_container {
    margin: 25px 0;
    font-size: 14px;
    line-height: 31px;
  }
  .text_input_ps {
    margin: 0 10px;
    font-size: 14px;
  }
  .gender__btn_container {
    gap: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0;
    overflow-y: hidden;
    gap: 0 15px;
    overflow-x: auto;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .gender_btn_self {
    padding: 10px 11px;
    font-size: 13px;
    line-height: 16px;
  }
  .gender_btn_self_selected {
    padding: 10px 11px;
    font-size: 13px;
    line-height: 16px;
  }
  .intro_under_page_container {
    width: 290px;
  }
  .name_text_ps_ {
    margin: 5px 0 26px;
    font-size: 17px;
    line-height: 20px;
  }
  .lightname_text_ps_ {
    margin: 0px 0 0px;
    font-size: 16px;
    line-height: 22px;
  }
  .input_text_ps_container {
    margin: 17px 0;
    font-size: 17px;
    line-height: 57px;
  }
  .text_input_ps {
    margin: 0 9px;
    font-size: 16px;
  }
  .gender__btn_container {
    gap: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0;
    overflow-y: hidden;
    gap: 0 15px;
    overflow-x: auto;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .gender_btn_self {
    padding: 17px 14px;
    font-size: 16px;
    line-height: 19px;
  }
  .gender_btn_self_selected {
    padding: 17px 14px;
    font-size: 16px;
    line-height: 19px;
  }
  .intro_under_page_container {
    width: 390px;
  }
  .name_text_ps_ {
    margin: 5px 0 26px;
    font-size: 17px;
    line-height: 20px;
  }
  .lightname_text_ps_ {
    margin: 0px 0 0px;
    font-size: 16px;
    line-height: 22px;
  }
  .input_text_ps_container {
    margin: 17px 0;
    font-size: 17px;
    line-height: 57px;
  }
  .text_input_ps {
    margin: 0 9px;
    font-size: 16px;
  }
  .gender__btn_container {
    gap: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0;
    overflow-y: hidden;
    gap: 0 15px;
    overflow-x: auto;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .intro_under_page_container {
    width: 700px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .intro_under_page_container {
    width: 930px;
  }
  .name_text_ps_ {
    font-size: 18px;
    line-height: 31px;
  }
  .lightname_text_ps_ {
    font-size: 16px;
    line-height: 22px;
  }
  .input_text_ps_container {
    margin: 5px 0;
    font-size: 16px;
    line-height: 40px;
  }
}

@media (min-width: 1200px) {
  .intro_under_page_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .name_text_ps_ {
    font-size: 24px;
    line-height: 37px;
  }
}

.conn_content_main_contianer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.load_more_btn {
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid rgb(192, 204, 218);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
  border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgba(27, 46, 75, 0.7);
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  padding: 0.391rem 15px;
  display: block;
  width: 96%;
  border-radius: 0.25rem;
  margin: 0 0 3% 0;
  font-family: IBMMed;
}

.load_more_btn:hover {
  border-color: rgb(131, 146, 165);
  color: rgb(27, 46, 75);
  font-family: IBMMed;
}


@media (max-width: 767px) {
  .conn_content_main_contianer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .load_more_btn {
    margin: 20px 0;
  }
  
}

@media (min-width: 768px) {
  .conn_content_main_contianer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .load_more_btn {
    margin: 20px 0;
  }
  
}
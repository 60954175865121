.search_main_container {
  margin: 0 auto;
}
.search_result_title_content_container {
}
.search_box_title_container > p {
  color: var(--theme-blue);
  font-family: InterSemBold;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 20px 0;
}
.search_content_box_button_container {
  display: flex;
  margin: 0 0 15px;
  align-items: center;
  flex-direction: row;
}
.search_result_box_container {
  margin: 20px auto;
  border: 1px solid rgba(61, 61, 61, 0.3);
  display: flex;
  flex-direction: row;
  width: 60%;

  border-radius: 5px;
  padding: 20px 20px;

  background: -moz-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(230, 230, 230, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(230, 230, 230, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(230, 230, 230, 1) 100%
  );
}
.search_result_avtar_description_container {
  display: flex;
  flex-direction: row;
  width: 80%;
  gap: 5px;

  align-items: center;
}
.avtar_self_search {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.search_result_avtar_container {
  width: 20%;
}
.search_result_description_container {
  width: 80%;
}
.title_of_search {
  color: var(--theme-blue);
  font-family: IBMMed;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
  /* text-transform: capitalize; */
  margin: 0 0 4px 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.title_of_search:hover {
  color: rgb(29 51 86);
  text-decoration: underline;
}
.subtitle_of_search {
  font-size: 15px;
  line-height: 18px;

  font-family: IBMReg;
  color: rgb(0 0 0 / 55%);
  margin: 0;
}
.button_of_result_container {
  width: 20%;
  margin: auto;
}
.button_of_result_container > button {
  width: 100%;
  height: 40px;
  font-family: "IBMMed";

  border-radius: 4px;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s ease-in-out;
}
.following_btn_search {
  background-color: var(--theme-blue);
  color: #fff;
  border: none;
}
.follow_btn_search {
  background-color: rgba(255, 255, 255, 0.555);
  color: var(--theme-blue);
  border: 1px solid var(--theme-blue);
}
.follow_btn_activity {
  font-family: "IBMMed";
  letter-spacing: 1px;
  color: rgba(27, 46, 75, 0.8);
  border: 1px solid rgba(27, 46, 75, 0.404);
}
.follow_btn_search:hover {
  background-color: var(--theme-blue);
  color: rgba(255, 255, 255, 0.9);
}

.button_of_result_container > button:hover {
  background-color: var(--theme-blue);
  color: #fff;
}
@media (max-width: 767px) {
  .search_main_container {
    width: 94%;
  }
  .search_result_box_container {
    width: 100%;
    padding: 11px 11px;
  }
  .search_box_title_container > p {
    line-height: 17px;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 0 0 17px 0;
  }
  .avtar_self_search {
    width: 40px;
    height: 40px;
  }
  .title_of_search {
    font-size: 11px;
    line-height: 14px;
    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 11px;
    line-height: 13px;
  }
  .button_of_result_container > button {
    font-size: 10px;
    height: 27px;
  }
  .search_result_avtar_container {
    width: 15%;
  }
  .search_content_box_button_container {
    justify-content: space-between;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .search_main_container {
    width: 390px;
  }
  .search_result_box_container {
    width: 100%;
    padding: 11px 11px;
  }
  .search_box_title_container > p {
    line-height: 17px;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 0 0 17px 0;
  }
  .avtar_self_search {
    width: 40px;
    height: 40px;
  }
  .title_of_search {
    font-size: 11px;
    line-height: 14px;
    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 11px;
    line-height: 13px;
  }
  .button_of_result_container > button {
    font-size: 10px;
    height: 27px;
  }
  .search_result_avtar_container {
    width: 15%;
  }
  .search_content_box_button_container {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .search_main_container {
    width: 700px;
  }
  .search_box_title_container > p {
    font-size: 17px;
    line-height: 17px;

    margin: 0 0 14px 0;
  }
  .avtar_self_search {
    width: 50px;
    height: 50px;
  }
  .title_of_search {
    font-size: 13px;
    line-height: 15px;

    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 11px;
    line-height: 12px;
  }
  .button_of_result_container > button {
    height: 35px;

    font-size: 12px;
  }
  .search_result_box_container {
    width: 70%;

    padding: 14px;
  }
  .search_result_title_content_container {
    width: 100%;
  }
  .search_result_avtar_container {
    width: 15%;
  }
  .search_result_description_container {
    width: 85%;
  }
}

@media (min-width: 991px) {
  .search_main_container {
    width: 930px;
  }
  .search_box_title_container > p {
    font-size: 16px;
    line-height: 17px;

    margin: 0 0 14px 0;
  }
  .avtar_self_search {
    width: 55px;
    height: 55px;
  }
  .title_of_search {
    font-size: 15px;
    line-height: 17px;
    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 14px;
    line-height: 17px;
  }
  .button_of_result_container > button {
    height: 35px;
    font-size: 12px;
    width: 80%;
    margin: 0 0 0 14px;
  }
  .search_result_box_container {
    width: 65%;

    padding: 18px;
  }
  .search_result_title_content_container {
    width: 100%;
  }
  .search_result_avtar_container {
    width: 14%;
  }
  .search_result_description_container {
    width: 86%;
  }
}

@media (min-width: 1200px) {
  .search_box_title_container > p {
    font-size: 19px;
    line-height: 17px;
    margin: 0 0 21px 0;
  }
  .search_main_container {
    width: 1100px;
  }
  .search_result_box_container {
    width: 58%;

    padding: 18px;
  }
  .title_of_search {
    font-size: 15px;
    line-height: 18px;

    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 14px;
    line-height: 17px;
  }
  .button_of_result_container > button {
    height: 40px;
    font-size: 13px;
    width: 90%;
    margin: 0 0 0 11px;
  }
}

@media (min-width: 1400px) {
  .search_box_title_container > p {
    font-size: 19px;
    line-height: 17px;
    margin: 0 0 21px 0;
  }
  .search_main_container {
    width: 1300px;
  }
  .search_result_box_container {
    width: 58%;

    padding: 18px;
  }
  .title_of_search {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 3px 0;
  }
  .subtitle_of_search {
    font-size: 14px;
    line-height: 17px;
  }
  .button_of_result_container > button {
    font-size: 13px;
    width: 86%;
    margin: 0 0 0 14px;
  }
}

.title_number_conn_contianer {
  border: 0;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 80%;
  margin: 0px 0 7px 0;
  font-family: 'IBMMed';
}
.title_conn_text {
  color: rgb(27, 46, 75);
  font-size: 14px;
  font-family: IBMReg;
}
.subtitle_conn_text {
  font-size: 11px;
  font-family: IBMReg;
  color: rgb(192, 204, 218);
}




@media (max-width: 767px) {
  .title_number_conn_contianer{
    width: 90%;
    margin: 5px 0 5px 0;
  }
  .title_conn_text {
    
    font-size: 13px;
  }
  .subtitle_conn_text {
    font-size: 12px;
  }
}


@media (min-width: 768px) {
  .title_conn_text {
    
    font-size: 16px;
    
  }
  .subtitle_conn_text {
    font-size: 15px;
  }
  .title_number_conn_contianer{
    width: 100%;
  }
  
}


@media (min-width: 991px) {
  .title_conn_text {
    
    font-size: 17px;
    
  }
  .title_number_conn_contianer {
    width: 85%;
  }
  .subtitle_conn_text {
    font-size: 16px;
}
}

@media (min-width:1200px) {
  .title_number_conn_contianer {
    width: 100%;
}
}

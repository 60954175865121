.main_pagination_tab_container {
  float: right;
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagination_box_container {
  display: inline-flex;
    align-content: center;

    padding: 5px 4px;
    width: auto;
    margin: 0 auto;

 
  flex-direction: row;
  align-items: center;

 
  padding: 8px 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
}

.pagination_box_container a {
  color: var(--theme-blue);
  float: left;
  font-family: IBMReg;
  font-size: 14px;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
  border-radius: 50%;
}

.pagination_box_container a.active {
  background-color: var(--theme-blue);
  color: white;
  font-family: IBMMed;
  margin: 1%;
  border-radius: 50%;
}
.pagination_box_container a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 50%;
}
.center_pagination {
  overflow-x: auto;
    overflow-y: hidden;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  margin: 0 auto;
}

.icon_pagination {
  font-size: 15px;
}
@media (max-width: 767px) {
  .pagination_box_container a.active {
    padding: 14px;
  }
  .pagination_box_container {
    width: 100%;

    margin: 0 auto;
    padding: 6px 5px;
  }

  .pagination_box_container a {
    font-size: 15px;
    padding: 6px 5px;
    margin: 6px 5px;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .pagination_box_container a {
    font-size: 13px;
    padding: 3px 8px;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination_box_container {
    display: inline-flex;
    align-content: center;
    align-items: center;
    padding: 5px 4px;
    width: auto;
    margin: 0 auto;
  }
  .people_main_title_text {
    font-size: 17px;

    margin: 0% 0 3% 0;
  }
}

@media (min-width: 991px) {
  .pagination_box_container a {
    font-size: 15px;
    padding: 4px 13px;
  }
}

.main_connection_contianer {
}
.connection_under_main_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2% 0 0 0;
}
.gallery_view_container {
  margin: 0 auto;
}
.conn_title_search_bar_contianer {
  display: flex;
  justify-content: space-between;
  width: 86%;
  margin: 0 auto 0 auto;
  padding: 2% 0 2% 0;
  align-items: center;
}
.conn_title {
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  font-size: 21px;
  color: var(--theme-blue);
  font-family: "IBMMed";
  letter-spacing: 0.5px;
}
.connec_search_bar_contianer {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
}
.input_search_conn {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 0;
  outline: none;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.btn_conn_search {
  border: 0;
  outline: none;
  background: transparent;
  margin: 0 1% 0 0;
}
.search_conn_self {
  font-size: 25px;

  color: rgb(131, 146, 165);
}
.search_conn_self:hover {
  color: rgb(27, 46, 75);
}
.conn_line {
  margin: 0;
}
.left_side_connection_contianer {
  width: 70%;
  /* background-color: red; */
}
.right_side_connection_contianer {
  width: 30%;
  /* background-color: green; */
}
.animated-button1 > svg {
  font-size: 25px;
  margin: 0 5px 0 0;
}
.req_rel_main_container {
margin: 10px;
}
.req_rel_main_container > p{
  font-size: 14px;
}
.select_box_two_container{
  display: flex;
  justify-content: space-between;
}
.req_rel_main_container > button{
    display: flex;
    padding: 3px 10px;
    width: 100px;
    margin: 0 0 0 auto;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 1px solid var(--theme-blue);
    font-family: 'IBMMed';
    font-size: 18px;
    color: var(--theme-blue);
    background: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    transition: .1s all ease-in-out;
    -webkit-transition: .1s all ease-in-out;
    -moz-transition: .1s all ease-in-out;
    -ms-transition: .1s all ease-in-out;
    -o-transition: .1s all ease-in-out;
}
.req_rel_main_container > button:hover{
  background: var(--theme-blue);
  color: #fff;
}

.animated-button1 {
  outline: none;
  padding: 10px 12px;
 margin: 12px;
  display: inline-block;
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #1B2E4B;
  font-size: 20px;
  letter-spacing: .5px;
  font-family: 'IBMMed';
  text-align: center;
 
  text-transform: capitalize;
  text-decoration: none;
  border:0px solid #1B2E4B;
  box-shadow: 0 0px 5px rgb(0 0 0 / 50%);

}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}


.animated-button1:hover {
background-color: #1B2E4B;
color: #fff;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#1B2E4B));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #1B2E4B);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#1B2E4B));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #1B2E4B);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#1B2E4B));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #1B2E4B);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#1B2E4B));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #1B2E4B);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@media (max-width:767px) {
  .req_rel_main_container > p {
    font-size: 13px;
}
.select_box_two_container {
  flex-direction: column;
  margin: 0 0 18px;
}
.req_rel_main_container {
  margin: 18px 10px 20px;
}
.req_rel_main_container > button {
 
  padding: 4px 10px;
  width: 100%;
 margin: 0;
 

  font-size: 14px;
}
}



@media (max-width: 299px) {
  .gallery_view_container {
    width: 94%;
  }
  .animated-button1 {
   
    font-size: 15px;
 
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .gallery_view_container {
    width: 94%;
  }
  .animated-button1 {
   
    font-size: 15px;
   
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .gallery_view_container {
    width: 390px;
  }
  .animated-button1 {
   
    font-size: 15px;
  
  }
}

@media (min-width: 768px) {
  .req_rel_main_container > p {
    font-size: 14px;
}
.req_rel_main_container {
  margin: 20px 20px 25px;
}
.select_box_two_container {
 
  margin: 19px 0 15px 0;
}
.req_rel_main_container > button {
  display: flex;
  padding: 3px 10px;
  width: 100px;
  margin: 0 0 0 auto;
  align-items: center;
  justify-content: center;
 
  font-size: 15px;
}
  .gallery_view_container {
    width: 700px;
  }
  .animated-button1 span:nth-child(1),
 
  .animated-button1 span:nth-child(3)
  {
   
    height: 3px;
  }
  
  .animated-button1 span:nth-child(2),

  .animated-button1 span:nth-child(4) {
   
    width: 3px;
  }
  /* .animated-button1 {
   
    font-size: 15px;
    border: 1px solid #1B2E4B;
  } */
}

@media (min-width: 991px) {
  .req_rel_main_container > p {
    font-size: 18px;
}
.req_rel_main_container {
  margin: 20px 20px 25px;
}
.select_box_two_container {
 
  margin: 19px 0 15px 0;
}
.req_rel_main_container > button {
  display: flex;
  padding: 6px 10px;
  width: 130px;
  /* letter-spacing: 0px; */
  margin: 0 0 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
  .gallery_view_container {
    width: 930px;
  }
  /* .animated-button1 {
   
    font-size: 18px;
    border: 2px solid #1B2E4B;
  } */
}

@media (min-width: 1200px) {
  .gallery_view_container {
    width: 1100px;
  }
  .animated-button1 {
    font-size: 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
}

@media (min-width: 1400px) {
  .gallery_view_container {
    width: 1200px;
  }
  .req_rel_main_container > p {
    font-size: 21px;
}
 
  /* .animated-button1 {
   
    font-size: 18px;
    border: 3px solid #1B2E4B;
  } */
}



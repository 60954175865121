.title_ty_page {
  font-size: 25px;
  line-height: 35px;
  color: var(--theme-blue);
  font-family: "IBMSemBold";
}
.extra_text_ty {
  font-size: 19px;
  line-height: 29px;
  font-family: "IBMReg";
  color: var(--theme-blue);
}

@media (max-width: 299px) {
  .title_ty_page {
    font-size: 16px;
    line-height: 19px;
  }
  .extra_text_ty {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .title_ty_page {
    font-size: 17px;
    line-height: 22px;
  }
  .extra_text_ty {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .title_ty_page {
    font-size: 21px;
    line-height: 31px;
  }
  .extra_text_ty {
    font-size: 18px;
    line-height: 27px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .title_ty_page {
    font-size: 18px;
    line-height: 21px;
  }
  .extra_text_ty {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (min-width: 1200px) {
  .title_ty_page {
    font-size: 21px;
    line-height: 26px;
  }
  .extra_text_ty {
    font-size: 18px;
    line-height: 31px;
  }
}

:root {
  --transition: all 0.3s ease-in-out;
}
* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;

  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
  font-family: IBMReg;

  /* color */

  --theme-blue: #1b2e4b;
  --theme-red: #a92626;
  --theme-grey:  #b4b4b442;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.3s ease-in-out;
  color: var(--theme-blue);
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

p {
}

button {
  outline: none;
}
.skeleton_margin {
  margin: 10px 5px 5px !important;
}
.skeleton_remove_border {
  border: 0 !important;
}
.skeleton_width {
  width: 90%;
}
.skeleton_width_short {
  width: 30%;
}
.skeleton_text {
  height: 10px;
  margin-bottom: 5px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton_padding_content_height_univercity {
  padding: 5px 0px 0 5px !important;
}
.skeleton_button {
  height: 10px;
}
.skeleton_img {
  width: 98%;
  margin: 20px auto;
  height: 200px;
}
.skeleton_boxshadow{
  box-shadow: -1px 0px 2px 0px rgb(0 0 0 /100%);
}
.conn_following_box_contianer_skeleton{
  max-height: 220px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px px solid #000;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.25rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}


@media (max-width:767px) {

  .conn_following_box_contianer_skeleton{
    justify-content: space-between;
  }
}

@media (min-width:767px) {
  .conn_following_box_contianer_skeleton{
        justify-content: space-between;
  }
}

@media (min-width:990px) {
  .conn_following_box_contianer_skeleton{
    min-height: 155px;
    padding: 3%;
  }
}


@media (min-width:1199px) {
  .conn_following_box_contianer_skeleton{
    width: 31%;
    margin: 19px 8px 0px 8px;
  }
}
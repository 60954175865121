.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
  transition: 0.2s;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
  transition: 0.25s;
}

.search_profile_notification_container {
  display: flex;
  flex-direction: row;
}

.search_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;

  outline: none;
  border: 0;
  background-color: transparent;
  margin-right: 10px;
}

.search_btn1 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #7b7b7b8c;
  outline: none;

  background-color: transparent;
  margin-right: 10px;
}

.icon_search_self {
  font-size: 18px;
}

.history_clear_all_btn_container {
  display: flex;
  justify-content: space-between;
}

.profile_pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.modal_under_search_close_btn_contianer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search_input_clear_container {
  border-radius: 40px;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.search_input {
  background: #c9bebe47;
  outline: none;
  width: 100%;
  font-size: 18px;
  border: 0;
  height: 45px;
  border-radius: 17px 0 0 17px;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  padding-left: 2%;
  font-family: IBMReg;
}

.clear_btn {
  outline: none;
  font-size: 18px;
  border: 0;
  width: 45px;
  height: 45px;
  background: #c9bebe47;
  border-top-right-radius: 40;
  border-bottom-right-radius: 40;
}

.modal_search_container {
  width: 100%;
  height: 400px;
  max-height: 400px;
}

.recent_search_container {
  padding: 25px 25px 0 30px;
}

.title_text_recent {
  font-family: IBMReg;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: rgb(131, 146, 165);
  margin-bottom: 10px;
}

.recent_search_result_container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  list-style: none;

  flex-direction: row;
  margin-bottom: 0px;
}

.result_li_content {
  margin-right: 12px;
  margin-bottom: 10px;
}

.result_one_text {
  font-family: IBMReg;
  padding: 5px 10px;
  display: block;
  color: rgb(27, 46, 75);
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: 13px;
  border: 1px solid rgba(72, 94, 144, 0.16);
}

.btn_search_icon_text_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  border: 0;
  padding: 1% 0px 1% 1%;
  background: transparent;
}

.recent_search_container1 {}

.text_search {}

.search_result_icon {
  margin-right: 10px;
}

.cancel_history_item {
  margin-left: auto;
  background: transparent;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel_history_item:hover {
  background: #c9bebe47;
}

.logo_dwec_img1 {
  width: 70px;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
  padding: 1px 3px;
  border: 0;
  outline: none;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

.nav-link>span {
  color: var(--theme-blue);
  font-family: IBMMed;
}

.nav_text {
  color: var(--theme-blue) !important;
}

.nav-item:hover {
  background-color: #9999991a;
  border-radius: 5px;
}

/* .active {
  color: #0168fa;
  font-family: IBMSemBold;
  animation: nav_animation 0.5s linear;
  padding-bottom: 1.5px;
  border-radius: 5px;
  
  border-bottom: 3px solid var(--theme-blue);
 
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */

@keyframes nav_animation {
  0% {
    border-bottom: 0;
  }

  100% {
    border-bottom: 3px solid var(--theme-blue);
  }
}

.search_heading {
  font-family: IBMMed;
  font-size: 20px;
  margin: 0;
}

.search_btn_self {
  border: 1px solid var(--theme-blue);
  background: transparent;
  color: var(--theme-blue);
  padding: 6px 19px;
  border-radius: 0 20px 20px 0;
  font-family: IBMMed;
}

.after_clicked {
  color: #0168fa;
  font-family: IBMMed;
}

.before_clicked {
  color: var(--theme-blue);
}

.all_navlinks_title {
  display: flex;
  padding: 0;
  flex-direction: row;
  margin: 10px 0;
  gap: 2px;
}

.navs_text.active {
 
  font-family: IBMSemBold;
  color: var(--theme-blue);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 3px solid var(--theme-blue);
}

.navs_text:hover {
  color: var(--theme-blue);
  background-color: #f0f0f0;
  padding: 2px 5px;
  /* margin: 0; */
  border-radius: 4px;
}

/* a.active_class:hover{
  color: var(--theme-blue) !important;
} */
.navs_text {
  position: relative;
  color: var(--theme-blue);
  font-family: IBMReg;
  padding: 2px 5px;
}

.navs_text::after {
  content: "";
  position: absolute;
  background-color: var(--theme-blue);
  border: 2px solid var(--theme-blue);
  bottom: 8px;
  left: 0;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  -webkit-transition: opacity 300ms, transform 300ms;
  -moz-transition: opacity 300ms, transform 300ms;
  -ms-transition: opacity 300ms, transform 300ms;
  -o-transition: opacity 300ms, transform 300ms;

}

.navs_text:is(:focus)::after {
  opacity: 1;
  transform: translateY(8px);
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  -ms-transform: translateY(8px);
  -o-transform: translateY(8px);
}

@media (min-width: 576px) and (max-width: 767px) {
  .nav-link>span {
    /* color: var(--theme-blue); */
    font-family: IBMMed;
    font-size: 8px;
  }
  .all_navlinks_title {
    display: flex;
    padding: 0;
    flex-direction: row !important;
    margin: 10px 0;
  }
  .navs_text {
   
    font-size: 12px;
}
.navs_text.active {
 
  font-family: IBMSemBold;
  color: var(--theme-blue);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 3px solid var(--theme-blue);
}
}

@media screen and (max-width: 767px) {
  .navs_text:hover {
    color: var(--theme-blue);
    background-color: #f0f0f000;
  }
  .navs_text.active {
 
    font-family: IBMSemBold;
    color: var(--theme-blue);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0px solid var(--theme-blue);
  }
  .all_navlinks_title {
    display: flex;
    padding: 0;
    flex-direction: column;
    margin: 10px 0;
  }

  .navs_text:is(:hover, :focus)::after {

    width: auto;
  }

  .active {
    color: #0168fa;
    font-family: IBMSemBold;
    animation: nav_animation 0 linear;
    padding-bottom: 1.5px;
    border-radius: 0px;
    border-bottom: 0px solid var(--theme-blue);

    /* transition: all 0.5s ease-in-out; */
  }

  .search_btn_self {
    padding: 5px 23px;
    border-radius: 17px;

    font-size: 13px;
  }

  .search_heading {
    font-size: 17px;
  }

  .close_btn_contianer {
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border: 0;
    margin: 0px 0 0 8px;
  }

  .close_self2,
  .close_self {
    font-size: 19px;
  }

  .search_profile_notification_container {
    display: flex;
    flex-direction: row;
  }

  .cancel_history_icon {
    font-size: 10px;
  }

  .search_btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: transparent;
    margin-right: 10px;
  }

  .icon_search_self {
    font-size: 14px;
  }

  .profile_pic {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  .modal_under_search_close_btn_contianer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .search_input_clear_container {
    border-radius: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 5px;
    gap: 9px;
  }

  .search_input {
    background: #c9bebe47;
    outline: none;
    width: 100%;
    font-size: 13px;
    border: 0;
    height: 38px;
    border-radius: 40px;
    /* border-top-right-radius: 0;
    border-bottom-right-radius: 0; */
    padding-left: 6%;
    font-family: IBMReg;
  }

  .clear_btn {
    outline: none;
    font-size: 18px;
    border: 0;
    width: 38px;
    height: 38px;
    background: #c9bebe47;
    border-top-right-radius: 40;
    border-bottom-right-radius: 40;
  }

  .modal_search_container {
    width: 100%;
    height: 380px;
    max-height: 390px;
  }

  .recent_search_container {
    padding: 9px 0px 0 0px;
  }

  .title_text_recent {
    font-size: 7px;
    text-decoration: none;
  }

  .recent_search_result_container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
    list-style: none;

    flex-direction: row;
    margin-bottom: 0px;
  }

  .result_li_content {
    margin-right: 9px;
    margin-bottom: 7px;
  }

  .result_one_text {
    padding: 1px 5px;

    text-decoration: none;
    font-size: 10px;
  }

  .btn_search_icon_text_btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border: 0;
    padding: 1% 0px 1% 1%;
    background: transparent;
  }

  .recent_search_container1 {}

  .text_search {
    font-size: 11px;
  }

  .search_result_icon {
    margin-right: 7px;
    font-size: 10px;
  }

  .cancel_history_item {
    margin-left: auto;
    background: transparent;
    border: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel_history_item:hover {
    background: #c9bebe47;
  }

  .logo_dwec_img1 {
    width: 80px;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler {
    padding: 1px 3px;
    border: 0;
    outline: none;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out;
  }
}

@media screen and (min-width: 768px) {
  .navs_text {
   
    font-size: 13px;
}
  .nav-link>span {
    font-size: 13px;
  }

  .search_btn_self {
    padding: 8px 19px;
    border-radius: 0 20px 20px 0;

    font-size: 12px;
  }

  .close_btn_contianer {
    outline: none;
    width: 42px;
    height: 33px;
    border-radius: 50%;
    background: transparent;
    border: 0;
    margin: 0px 0 0 8px;
  }

  .close_self,
  .close_self2 {
    font-size: 24px;
  }

  .search_profile_notification_container {
    display: flex;
    flex-direction: row;
  }

  .cancel_history_icon {
    font-size: 10px;
  }

  .search_btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: transparent;
    margin-right: 10px;
  }

  .icon_search_self {
    font-size: 14px;
  }

  .profile_pic {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  .modal_under_search_close_btn_contianer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .search_input_clear_container {
    margin: 0px 0 10px;
    border-radius: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .search_input {
    font-size: 12px;
    border: 0;
    height: 40px;

    padding-left: 3%;
  }

  .clear_btn {
    outline: none;
    font-size: 18px;
    border: 0;
    width: 38px;
    height: 38px;
    background: #c9bebe47;
    border-top-right-radius: 40;
    border-bottom-right-radius: 40;
  }

  .modal_search_container {
    width: 100%;
    height: 380px;
    max-height: 390px;
  }

  .recent_search_container {
    padding: 9px 0px 0 0px;
  }

  .title_text_recent {
    font-size: 8px;
    text-decoration: none;
  }

  .recent_search_result_container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
    list-style: none;

    flex-direction: row;
    margin-bottom: 0px;
  }

  .result_li_content {
    margin-right: 9px;
    margin-bottom: 7px;
  }

  .result_one_text {
    padding: 1px 5px;

    text-decoration: none;
    font-size: 9px;
  }

  .btn_search_icon_text_btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border: 0;
    padding: 0% 0px 0% 1%;
    background: transparent;
  }

  .search_modal_line {
    margin: 1% 0 3% 0;
  }

  .recent_search_container1 {}

  .text_search {
    font-size: 11px;
  }

  .search_result_icon {
    margin-right: 7px;
    font-size: 10px;
  }

  .cancel_history_item {
    margin-left: auto;
    background: transparent;
    border: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel_history_item:hover {
    background: #c9bebe47;
  }

  .logo_dwec_img1 {
    width: 80px;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler {
    padding: 1px 3px;
    border: 0;
    outline: none;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out;
  }

  .nav-link {
    font-size: 13px;
  }

  .search_heading {
    font-size: 18px;
  }
}

@media screen and (min-width: 991px) {
  .search_input_clear_container {
    margin: 10px 0 0px;
    gap: 6px;
  }

  .logo_dwec_img1 {
    width: 50px;
  }
  .navs_text {
   
    font-size: 15px;
}

  .nav-link {
    font-size: 16px;
  }

  .modal_search_container {
    width: 100%;
    height: 308px;
    max-height: 390px;
  }

  .search_input {
    font-size: 14px;
    padding-left: 2%;
  }

  .title_text_recent {
    font-size: 11px;
    text-decoration: none;
    margin: 10px 0 8px 0;
  }

  .result_one_text {
    padding: 2px 8px;
    text-decoration: none;
    font-size: 12px;
  }

  .search_modal_line {
    margin: 1% 0 1% 0;
  }

  .search_result_icon {
    margin-right: 7px;
    font-size: 13px;
  }

  .text_search {
    font-size: 12px;
  }

  .cancel_history_icon {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {

  .search_input_clear_container {
    margin: 15px 0 5px;
  }

  .logo_dwec_img1 {
    width: 50px;
  }
  .navs_text {
   
    font-size: 18px;
}
  .nav-link>span {
    font-size: 18px;
  }

  .icon_search_self {
    font-size: 18px;
  }
}
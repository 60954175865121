.univer_tabs_container {
  width: 100%;
  margin: 0 0 0 auto;
}
.content_tabs_univer_container {
  width: 88%;
  position: relative;
  margin: 0% 3% 0 auto;
}
.univer_wrap_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* background-color: red; */
}
/* univecity_box */
.univercity_box_contianer {
  /* background-color: red; */
  width: 31.33%;
  border-radius: 4px;
  margin: 0 2% 2% 0;
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.univer_contianer {
  border-bottom: 1px solid rgb(0 23 55 / 21%);
  display: flex;
  flex-direction: row;
}
.univercity_img {
  width: 100%;

  object-fit: cover;
}
.title_of_unicercity {
  font-size: 15px;
  font-family: "IBMSemBold";
  color: var(--theme-blue);
  margin: 3% 0 0 3%;
  text-transform: capitalize;
}
.sub_title_of_univervity {
  font-size: 14px;
  font-family: "IBMMED";
  color: rgb(106 102 102 / 63%);
  text-transform: capitalize;

  margin: 1% 0 0 3%;
}
.view_details_contianer {
  margin: 8% 4% 6% 0;
  display: flex;
  justify-content: space-between;
}
.view_detail_btn_univercity {
  border: 1px solid rgb(27, 46, 75);
  outline: none;
  color: rgb(27, 46, 75);
  font-size: 14px;
  font-family: IBMMed;
  background-color: transparent;
  height: 33px;
  text-transform: capitalize;
  padding: 1% 4% 1% 4%;
  border-radius: 4px;
  letter-spacing: 1px;
}
.view_detail_btn_univercity:hover {
  background-color: rgb(27, 46, 75);
  color: #fff;
}
.like_icon_container {
  background-color: transparent;
  border: 0;
  outline: none;
}
.icon_block_text_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: IBMSemBold;
  color: rgb(27, 46, 75);
  font-size: 13px;
}
.icon_block_text_container:hover {
  text-decoration: underline;
}
.red_color {
  color: red;
}
.blue_color {
  color: rgb(27, 46, 75);
}

@media (max-width: 299px) {
  .univer_wrap_container {
    display: flex;
    flex-wrap: wrap;
    margin: 4% 0 0 0;
    flex-direction: column;
  }

  .content_tabs_univer_container {
    width: 88%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 100%;
    margin: 0 10px 10px 0;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 12px;
    margin: 3% 0 0 3%;
  }
  .sub_title_of_univervity {
    font-size: 13px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 30px 4% 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .view_detail_btn_univercity {
    height: 30px;
    padding: 0;
    width: 51%;
    font-size: 11.5px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;
    max-height: 100px;
    height: 100px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .univer_wrap_container {
    display: flex;
    flex-wrap: wrap;
    margin: 4% 0 0 0;
    flex-direction: column;
  }

  .content_tabs_univer_container {
    width: 88%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 100%;
    margin: 0 10px 10px 0;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 13px;
    margin: 3% 0 0 3%;
  }
  .sub_title_of_univervity {
    font-size: 13px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 30px 4% 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .view_detail_btn_univercity {
    height: 27px;
    padding: 0;
    width: 27%;
    font-size: 11.5px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;
    max-height: 120px;
    height: 120px;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .univer_wrap_container {
    display: flex;
    flex-wrap: wrap;
    margin: 4% 0 0 0;
    flex-direction: column;
  }

  .content_tabs_univer_container {
    width: 88%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 100%;
    margin: 0 10px 10px 0;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 13px;
    margin: 3% 0 0 3%;
  }
  .sub_title_of_univervity {
    font-size: 13px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 30px 4% 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .view_detail_btn_univercity {
    height: 30px;
    padding: 0;
    width: 40%;
    font-size: 11.5px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;
    max-height: 120px;
    height: 120px;
  }
}

@media (min-width: 768px) {
  .icon_block_text_container {
    gap: 1px;

    font-size: 11px;
  }
  .univer_wrap_container {
    margin: 3% 0 0 0;
  }
  .content_tabs_univer_container {
    width: 88%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 31.33%;
    margin: 0px 5px 10px 5px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 14px;

    margin: 5% 5% 0;
  }
  .sub_title_of_univervity {
    font-size: 12px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 30px 3% 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .view_detail_btn_univercity {
    height: 28px;
    padding: 0 5px;
    width: 38%;
    font-size: 10.5px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;

    max-height: 120px;
    height: 120px;
  }
}

@media (min-width: 991px) {
  .icon_block_text_container {
    gap: 2px;
    font-size: 12px;
}
  .univer_wrap_container {
    margin: 3% 0 0 0;
  }
  .content_tabs_univer_container {
    width: 88%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 31.33%;
    margin: 0px 5px 10px 5px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 12px;

    margin: 5% 5% 0;
  }
  .sub_title_of_univervity {
    font-size: 13px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 20px 10px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .view_detail_btn_univercity {
    height: 24px;
    padding: 0;
    width: 40%;
    font-size: 10.5px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;
    max-height: 100px;
    height: 100px;
  }
}

@media (min-width: 1200px) {
  .icon_block_text_container {
    gap: 5px;
    font-size: 13px;
}
  .univer_wrap_container {
    margin: 3% 0 0 0;
  }
  .content_tabs_univer_container {
    width: 93%;
    position: relative;
    margin: 0% auto 0 auto;
  }
  .univercity_box_contianer {
    width: 31.33%;
    margin: 0px 5px 10px 5px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title_of_unicercity {
    font-size: 15px;

    margin: 5% 5% 0;
  }
  .sub_title_of_univervity {
    font-size: 13px;

    margin: 0% 0 0 5%;
  }
  .view_details_contianer {
    margin: 20px 10px 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 1px 0 15px;
  }
  .view_detail_btn_univercity {
    height: 28px;
    padding: 0;
    width: 32%;
    letter-spacing: 0.2px;
    font-size: 13px;
    border: 1px solid rgb(27 46 75 / 82%);
  }
  .univer_contianer {
    width: 100%;
    max-height: 130px;
    height: 130px;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1400px) {
  .icon_block_text_container {
    gap: 4px;
    font-size: 14px;
}
  .content_tabs_univer_container {
    width: 95%;
    position: relative;
    margin: 0% auto 0 auto;
  }
}

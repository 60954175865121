.univer_title_text_subtitle_contianer {
  display: flex;
  flex-direction: column;
  padding: 0% 0 0 0%;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 0 0%;
}
.univer_img_popular_self {
  width: 72%;
  border-radius: 50%;
  border: 1px solid #00000052;
}

@media (max-width: 767px) {
  .univer_title_text_subtitle_contianer {
  }
  .univer_img_popular_self {
    width: 40px;
    object-fit: contain;
  }
}

@media (min-width: 768px) {
  .univer_title_text_subtitle_contianer {
  }
  .univer_img_popular_self {
    width: 40px;
    object-fit: contain;
  }
}

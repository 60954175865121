.bloc-tabs {
  display: flex;
  margin: 3% 0 0 0;
}

.tabs {
  padding: 0px;
  margin: 0 3% 0 0;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  border: none;
  color: rgba(27, 46, 75, 0.32);
  background: #fff;
  font-family: IBMMed;
}

/* .tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
} */

.active-tabs {
  background: white;
  color: var(--theme-blue);
  font-family: IBMMed;
  border-bottom: 3px solid var(--theme-blue);
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  color: rgba(27, 46, 75, 0.32);
  font-family: IBMMed;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  padding: 3% 0 0 0;
  width: 100%;
  height: 100%;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

/* .content p {
    width: 100%;
    height: 100%;
} */

.active-content {
  display: block;
}

@media (max-width: 767px) {
  .bloc-tabs {
    display: flex;
    margin: 3% auto 0 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .active-tabs {
    border-bottom: 2px solid var(--theme-blue);
    font-size: 13px;
  }
  .tabs {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .content {
    padding: 0 0 0 0;
  }
  .bloc-tabs {
    display: flex;
    margin: 1% auto 0 auto;
    justify-content: flex-start;
  }
  .active-tabs {
    border-bottom: 3px solid var(--theme-blue);
    font-size: 15px;
  }
  .tabs {
    font-size: 11px;
    line-height: 14px;
  }
}

@media (min-width: 991px) {
  .tabs {
    font-size: 13px;
    line-height: 14px;
  }
}

@media (min-width: 1200px) {
  .active-tabs {
    border-bottom: 3px solid var(--theme-blue);
    font-size: 18px;
  }
  .tabs {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .active-tabs {
    border-bottom: 3px solid var(--theme-blue);
    font-size: 18px;
  }
  .tabs {
    font-size: 17px;
  }
}

.main_container_sign_up {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2% 5% 2% 5%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.left_sign_up_container {
  width: 40%;
}
.left_under_sign_up_container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.create_text {
  color: var(--theme-blue);
  margin-bottom: 5px;
  font-size: 26px;
  font-family: IBMMed;
}
.create_text1 {
  color: var(--theme-blue);
  margin-bottom: 5px;
  font-size: 15px;
  font-family: IBMMed;
}
.create_extra_text {
  font-size: 16px;
  color: rgb(131, 146, 165);
  margin-bottom: 40px;
  font-family: IBMReg;
}
.title_of_input {
  margin: 3% 0 3% 0;
  font-size: 16px;
  font-family: IBMReg;
}
.sign_up_input {
  outline: none;

  font-family: IBMReg;
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.check_box_self {
  width: 28px;
  height: 25px;
  cursor: pointer;
}
.text_terms {
  font-family: IBMReg;
  margin: 0 0 0 2%;
  cursor: pointer;
}

.same_btn_sign_up {
}
.right_sign_up_container {
  width: 60%;
  /* background: green; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.right_sing_up {
  width: 80%;
}
.check_box_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  margin: 4% 0 4% 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .main_container_sign_up {
    display: flex;
    flex-direction: column;
    margin: 5% 3% 2% 3%;
    align-items: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .left_sign_up_container {
    width: 100%;
  }
  .left_under_sign_up_container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .create_text {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 26px;
    font-family: IBMMed;
  }
  .create_text1 {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 14px;
    font-family: IBMMed;
  }
  .create_extra_text {
    font-size: 13px;
    color: rgb(131, 146, 165);
    margin-bottom: 29px;
    font-family: IBMReg;
  }
  .title_of_input {
    margin: 3% 0 3% 0;
    font-size: 13px;
    font-family: IBMReg;
  }
  .sign_up_input {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .check_box_self {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }
  .text_terms {
    font-family: IBMReg;
    margin: 1% 0 0 2%;
    cursor: pointer;
    line-height: 14px;
    font-size: 12px;
  }

  .same_btn_sign_up {
  }
  .right_sign_up_container {
    width: 60%;
    /* background: green; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .right_sing_up {
    width: 0%;
  }
  .check_box_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    margin: 4% 0 4% 0;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .main_container_sign_up {
    display: flex;
    flex-direction: column;
    margin: 5% 3% 2% 3%;
    align-items: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .left_sign_up_container {
    width: 50%;
  }
  .left_under_sign_up_container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .create_text {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 26px;
    font-family: IBMMed;
  }
  .create_text1 {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 14px;
    font-family: IBMMed;
  }
  .create_extra_text {
    font-size: 13px;
    color: rgb(131, 146, 165);
    margin-bottom: 29px;
    font-family: IBMReg;
  }
  .title_of_input {
    margin: 3% 0 3% 0;
    font-size: 13px;
    font-family: IBMReg;
  }
  .sign_up_input {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .check_box_self {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }
  .text_terms {
    font-family: IBMReg;
    margin: 1% 0 0 2%;
    cursor: pointer;
    line-height: 14px;
    font-size: 12px;
  }

  .same_btn_sign_up {
  }
  .right_sign_up_container {
    width: 60%;
    /* background: green; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .right_sing_up {
    width: 0%;
  }
  .check_box_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    margin: 4% 0 4% 0;
    cursor: pointer;
  }
}

@media screen and (min-width: 991px) {
  .main_container_sign_up {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2% 5% 2% 5%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .left_sign_up_container {
    width: 40%;
  }
  .left_under_sign_up_container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .create_text {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 26px;
    font-family: IBMMed;
  }
  .create_text1 {
    color: var(--theme-blue);
    margin-bottom: 5px;
    font-size: 15px;
    font-family: IBMMed;
  }
  .create_extra_text {
    font-size: 16px;
    color: rgb(131, 146, 165);
    margin-bottom: 40px;
    font-family: IBMReg;
  }
  .title_of_input {
    margin: 3% 0 3% 0;
    font-size: 16px;
    font-family: IBMReg;
  }
  .sign_up_input {
    font-family: IBMReg;
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(27, 46, 75);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(192, 204, 218);
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
  }
  .check_box_self {
    width: 28px;
    height: 25px;
    cursor: pointer;
  }
  .text_terms {
    font-family: IBMReg;
    margin: 0 0 0 2%;
    cursor: pointer;
  }

  .same_btn_sign_up {
  }
  .right_sign_up_container {
    width: 60%;
    /* background: green; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .right_sing_up {
    width: 80%;
  }
  .check_box_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    margin: 4% 0 4% 0;
    cursor: pointer;
  }
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

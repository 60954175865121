.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
  /* box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25); */
}

.page-active {
  background-color: #1b2e4b;
  border-radius: 50%;
  color: white;
  margin: 1%;
}

.page-item {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.pagination-main-root{
  margin-top: 2%;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
}

/*  */
.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

@media (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

.gallery-item {
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;
}

.gallery-item img,
.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumbnail {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
}

.post-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.post-modal {
  background: white;
  width: 90%;
  max-width: 935px;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.close-button {
  /* align-self: flex-end; */
  position: absolute;
  right: 2%;
  top: 2%;
  /* background: #1b2e4b; */
  background: #1b2e4b;
  color: white;
  z-index: 200;
  width: 30px;
  height: 30px;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.post-content {
  display: flex;
  height: 100%;
}

.post-media {
  /* width: 60%; */
  background: black;
}

.post-media img,
.post-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-details {
  /* width: 40%; */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-actions {
  display: flex;
  margin-top: 15px;
}

.post-actions button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.comments-section {
  flex-grow: 1;
  overflow-y: auto;
}

.comment-form {
  display: flex;
  margin-top: 15px;
}

.comment-form input {
  flex-grow: 1;
  padding: 5px;
}

.comment-form button {
  padding: 5px 10px;
  background: #0095f6;
  color: white;
  border: none;
  cursor: pointer;
}

/* .post-content {
  position: relative;
} */

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #1b2e4b;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 10;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.post-media {
  /* width: 60%; */
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-media img,
.post-media video {
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: contain;
}

/*  */
.gallery-item {
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
}

.gallery-item img,
.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.video-thumbnail {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
}

.gallery-item-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-item:hover .gallery-item-info {
  opacity: 1;
}

.gallery-item-info .text-white {
  font-size: 16px;
  font-weight: bold;
}
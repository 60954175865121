.profile_people_title_sub_title_container {
  display: flex;
  flex-direction: row;

  outline: none;
  border: 0;
  background: transparent;
}
.people_img_self {
  width: 10%;
  border-radius: 50%;
}
.people_title_text {
  font-weight: 500;
  margin-bottom: 0px;
  color: var(--theme-blue);
  font-size: 14px;
  font-family: IBMMed;
}
.people_sub_title_text {
  font-size: 12px;
  font-family: IBMReg;
  color: rgb(131, 146, 165);
}
.peoplw_title_text_subtitle_contianer {
  display: flex;
  flex-direction: column;
  padding: 0% 0 0 2%;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .peoplw_title_text_subtitle_contianer {
    padding: 0% 0 0 10px;
  }
  .profile_people_title_sub_title_container {
    margin: 10px 0;
    align-items: center;
  }
  .people_main_title_text {
    font-size: 13px;

    margin: 0% 0 5% 0;
  }
  .people_img_self {
    width: 45px;
  }
  .people_title_text {
    font-size: 13px;
  }
  .people_sub_title_text {
    font-size: 10px;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .people_title_text {
    font-size: 16px;
    text-align: left;
  }
  .people_img_self {
    width: 49px;
    height: 49px;
  }
  .profile_people_title_sub_title_container {
    margin: 12px 0;
    align-items: center;
    justify-content: flex-start;
  }
  .people_sub_title_text {
    font-size: 13px;
    margin: 0;
  }
  .peoplw_title_text_subtitle_contianer {
    padding: 0% 0 0 1.5%;
    width: 600px;

    overflow: hidden;
  }
}

@media (min-width: 991px) {
  .profile_people_title_sub_title_container {
    display: flex;
    flex-direction: row;

    outline: none;
    border: 0;
    background: transparent;
  }
  .people_img_self {
    width: 55px;

    height: 55px;
  }
  .people_title_text {
    font-weight: 500;
    margin-bottom: 0px;
    color: var(--theme-blue);
    font-size: 17px;

    font-family: IBMMed;
  }
  .people_sub_title_text {
    font-size: 14px;
    font-family: IBMReg;
    color: rgb(131, 146, 165);
  }
  .peoplw_title_text_subtitle_contianer {
    display: flex;
    flex-direction: column;
    padding: 0% 0 0 3%;
    align-items: flex-start;
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .profile_people_title_sub_title_container {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;
  }
  .people_img_self {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .people_title_text {
    font-weight: 500;
    margin-bottom: 0px;
    color: var(--theme-blue);
    font-size: 17px;

    font-family: "IBMREG";
  }
  .people_sub_title_text {
    font-size: 14px;
    font-family: IBMReg;
    color: rgb(131, 146, 165);
  }
  .peoplw_title_text_subtitle_contianer {
    display: flex;
    flex-direction: column;
    padding: 0% 0 0 4%;
    align-items: flex-start;
    width: 300px;
  }
}

@media (min-width: 1400px) {
  .people_img_self {
    width: 40px;
    height: 40px;
  }
  .peoplw_title_text_subtitle_contianer {
    width: 350px;
  }
}

.filter_right_side_main_container {
  margin: 0px 10px;
}
.highlighted_filter_btns {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 0 7px;
}
.text_close_btn {
  font-family: "IBMMED";
  display: inline-flex;
  color: #fff;
  font-size: 13px;
  outline: none;
  background-color: var(--theme-blue);
  padding: 6px 10px 6px 16px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin: 0 10px 5px 0;
  border-radius: 20px;
  text-transform: capitalize;
  gap: 5px;
  letter-spacing: 0.5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.text_close_btn > svg {
  font-size: 13px;
  cursor: pointer;
}
.text_close_btn > svg:hover {
  animation: mymove 1s;
}

@keyframes mymove {
  50% {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
  }
}

.data_of_result_filter_container {
  border: 1px solid #00000042;
  padding: 11px 15px 10px;
  margin: 0px auto 9px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.data_of_result_filter_container > h6 {
  font-size: 16px;
  font-family: IbmSemBold;
  margin: 0 0 18px;
}
.data_row_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 3px;
}
.filter_title_sub_data_img_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 32.75%;
  gap: 5px;
}
.filter_data_img_container {
  width: 30px !important;
}
.title_subtitle_filter_container {
  display: flex;
  flex-direction: column;
}
.title_subtitle_filter_container > h6:first-child {
  font-size: 14px;
  margin: 0;
  font-family: IBMMed;
  color: var(--theme-blue);
}

.title_subtitle_filter_container > h6:last-child {
  font-size: 13px;
  margin: 0;
  font-family: IBMReg;
  color: #1b2e4bdb;
}
.shortlisted_btn_container_filter_right_side {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shortlisted_btn_container_filter_right_side > button:last-child {
  border: 1px solid rgb(27, 46, 75);
  outline: none;
  color: rgb(27, 46, 75);
  font-size: 13px;
  font-family: IBMMed;
  background-color: transparent;
  text-transform: capitalize;
  padding: 1% 4% 1% 4%;
  padding: 3px 10px;

  letter-spacing: 0.5px;
  border-radius: 2px;
}
.shortlisted_btn_container_filter_right_side > button:last-child:hover {
  background-color: rgb(27, 46, 75);
  color: #fff;
}

@media (max-width: 767px) {
  .filter_title_sub_data_img_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 5px;
  }
  .filter_data_img_container {
    width: 25px !important;
  }
  .title_subtitle_filter_container {
    width: calc(100% - 30px);
  }
  .title_subtitle_filter_container > h6:first-child,
  .title_subtitle_filter_container > h6:last-child {
    font-size: 12px;
  }
  .data_of_result_filter_container > h6 {
    font-size: 15px;

    margin: 0 0 8px;
  }
  .text_close_btn {
    font-size: 10px;

    padding: 5px 6px 5px 11px;

    margin: 0 3px 3px 0px;

    gap: 1px;
    letter-spacing: 0.5px;
  }
}

@media (min-width: 767px) and (max-width: 1199px) {
  .filter_title_sub_data_img_container {
    width: 48.5%;
  }
  .title_subtitle_filter_container {
    width: calc(100% - 30px);
  }
}

@media (min-width: 1199px) {
  .title_subtitle_filter_container {
    width: calc(100% - 30px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.411);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar:horizontal {
    height: 4px;
  }
}

@media (min-width: 768px) {
  ::-webkit-scrollbar:horizontal {
    height: 4px;
  }
}

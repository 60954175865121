.main_per_step_section_container {
}
.two_stage_text_status_container {
  display: flex;
  justify-content: space-between;
  margin: 0 25px 0 0;
}
.stage_sec_container {
  display: inline-flex;
  background: var(--theme-blue);

  font-family: "IBMReg";
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;
  padding: 10px 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.stage_sec_container > p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.stage_content_container {
  margin: 25px 10px 25px 118px;
  display: flex;
  gap: 15px;
}
.status_text_container {
  background: #f9e7b0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  border-radius: 5px;
  padding: 0;
  height: 35px;
  padding: 0 10px;
}
.status_yellow_color_text {
  color: #d6a300;
  font-size: 19px;
}
.status_progress_text {
  margin: 0;
  font-family: IBMMed;
  font-size: 13px;
  color: #d6a300;
}
.stage_img_container {
  width: 55px;
}
.stage_agenda_title_subtitle_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stage_agenda_title_subtitle_container > button {
  display: inline-flex;
  margin: 0 15px 0 auto;
  width: 17%;
  padding: 7px;
  /* height: 34px; */
  border: 2px solid var(--theme-blue);
  background: #fff;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  color: var(--theme-blue);
  position: relative;
}
.stage_agenda_title_subtitle_container > button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--theme-blue);
  transition: all 0.35s;
  color: #fff;
}

.stage_agenda_title_subtitle_container > button:hover {
  color: #fff;
}

.stage_agenda_title_subtitle_container > button:hover:after {
  width: 100%;
  color: #fff;
}
.stage_agenda_title_subtitle_container > button > span {
  position: relative;
  z-index: 2;
  font-family: "IBMSemBold";
}

.next_btn_apk_ {
  display: flex;
  margin: 0 24px 27px auto;
  width: 17%;
  padding: 7px;
  /* height: 34px; */
  border: 2px solid var(--theme-blue);
  background: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: var(--theme-blue);
  position: relative;
}

.next_btn_apk_:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--theme-blue);
  transition: all 0.35s;
  color: #fff;
}

.next_btn_apk_:hover {
  color: #fff;
}

.next_btn_apk_:hover:after {
  width: 100%;
  color: #fff;
}
.next_btn_apk_ > span {
  position: relative;
  z-index: 2;
  font-family: "IBMSemBold";
}

.stage_title_subtitle_container {
}

.stage_title_subtitle_container1 {
  margin: 0px 0 0px;
}

.stage_title_text {
  margin: 0px 0 5px;
  font-family: "IBMSemBold";
  font-size: 14px;
}
.stage_subtitle_text {
  font-size: 13px;
}
.stage_subtitle_text > p{
  font-size: 13px;
}

.complete_text {
  margin: 0;
  font-size: 13px;
  display: flex;
  gap: 7px;

  align-items: center;
}
.icon_size_status {
  font-size: 13px;
}
.attention_text {
  margin: 0;
  color: #a92626;
  font-size: 13px;
  display: flex;
  gap: 7px;

  align-items: center;
}
.extra_text {
  margin: 3px 0 0;

  font-size: 13px;
}
.extra_text > p {
  font-size: 12px;
  margin: 0;
}

@media (max-width: 299px) {
  .br_tag_hid {
    display: none;
  }
  .stage_sec_container {
    margin: 0 0 15px;
    padding: 5px 7px;
  }
  .stage_sec_container > p {
    font-size: 12px;
  }
  .two_stage_text_status_container {
    margin: 15px 15px 0 0;
  }
  .status_progress_text {
    font-size: 10px;
  }
  .status_yellow_color_text {
    font-size: 13px;
  }
  .status_text_container {
    gap: 3px;

    padding: 0;
    height: 30px;
    padding: 0 6px;
  }
  .stage_content_container {
    margin: 20px 10px 25px 10px;

    gap: 10px;
  }
  .stage_img_container {
    width: 40px;
  }
  .stage_title_text {
    margin: 0px 0 2px;

    font-size: 12px;
  }
  .stage_subtitle_text {
    font-size: 10px;
  }
  .stage_title_subtitle_container1 {
    margin: 10px 0 20px;
  }
  .complete_text {
    font-size: 10px;
    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .icon_size_status {
    font-size: 0;
    display: none;
  }
  .attention_text {
    font-size: 10px;
    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .extra_text {
    margin: 10px 0 0;
    font-size: 10px;
  }
  .stage_agenda_title_subtitle_container > button {
    width: 40%;
    padding: 5px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 11px;
  }
  .next_btn_apk_ {
    width: 40%;
    padding: 5px;
  }
  .next_btn_apk_ > span {
    font-size: 11px;
  }
}

@media (min-width: 299px) and (max-width: 399px) {
  .br_tag_hid {
    display: none;
  }
  .stage_sec_container {
    margin: 0 0 15px;
    padding: 5px 7px;
  }
  .stage_sec_container > p {
    font-size: 12px;
  }
  .two_stage_text_status_container {
    margin: 15px 15px 0 0;
  }
  .status_progress_text {
    font-size: 10px;
  }
  .status_yellow_color_text {
    font-size: 13px;
  }
  .status_text_container {
    gap: 3px;

    padding: 0;
    height: 30px;
    padding: 0 6px;
  }
  .stage_content_container {
    margin: 20px 10px 25px 10px;

    gap: 10px;
  }
  .stage_img_container {
    width: 40px;
  }
  .stage_title_text {
    margin: 0px 0 2px;

    font-size: 12.5px;
  }
  .stage_subtitle_text {
    font-size: 11px;
  }
  .stage_title_subtitle_container1 {
    margin: 10px 0 20px;
  }
  .complete_text {
    font-size: 11px;

    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .icon_size_status {
    font-size: 0;
    display: none;
  }
  .attention_text {
    font-size: 11px;

    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .extra_text {
    margin: 10px 0 0;

    font-size: 11px;
  }
  .stage_agenda_title_subtitle_container > button {
    width: 40%;
    padding: 5px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 12px;
  }
  .next_btn_apk_ {
    width: 40%;
    padding: 5px;
  }
  .next_btn_apk_ > span {
    font-size: 12px;
  }
}

@media (min-width: 399px) and (max-width: 767px) {
  .br_tag_hid {
    display: none;
  }
  .stage_sec_container {
    margin: 0 0 15px;
    padding: 5px 7px;
  }
  .stage_sec_container > p {
    font-size: 12px;
  }
  .two_stage_text_status_container {
    margin: 15px 15px 0 0;
  }
  .status_progress_text {
    font-size: 10px;
  }
  .status_yellow_color_text {
    font-size: 13px;
  }
  .status_text_container {
    gap: 3px;

    padding: 0;
    height: 30px;
    padding: 0 6px;
  }
  .stage_content_container {
    margin: 20px 10px 25px 10px;

    gap: 10px;
  }
  .stage_img_container {
    width: 40px;
  }
  .stage_title_text {
    margin: 0px 0 2px;

    font-size: 14px;
  }
  .stage_subtitle_text {
    font-size: 12.5px;
  }
  .stage_title_subtitle_container1 {
    margin: 10px 0 20px;
  }
  .complete_text {
    font-size: 12.5px;

    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .icon_size_status {
    font-size: 0;
    display: none;
  }
  .attention_text {
    font-size: 12.5px;

    gap: 4px;
    display: list-item;
    list-style: inside;
  }
  .extra_text {
    margin: 10px 0 0;

    font-size: 12.5px;
  }
  .stage_agenda_title_subtitle_container > button {
    width: 40%;
    padding: 5px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 14px;
  }
  .next_btn_apk_ {
    width: 40%;
    padding: 5px;
  }
  .next_btn_apk_ > span {
    font-size: 14px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .stage_sec_container > p {
    font-size: 13px;
  }
  .stage_sec_container {
    margin: 0 0 12px;
    padding: 7px 16px;
  }
  .status_text_container {
    gap: 5px;
    border-radius: 5px;
    padding: 0;
    height: 33px;
    padding: 0 10px;
  }
  .stage_content_container {
    margin: 25px 10px 25px 55px;

    gap: 10px;
  }
  .stage_img_container {
    width: 50px;
  }
  .stage_agenda_title_subtitle_container > button {
    margin: 0 15px 0 auto;
    width: 18%;
    padding: 5px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 13px;
  }
  .next_btn_apk_ {
    margin: 0 25px 20px auto;
    width: 18%;
    padding: 5px;
  }
  .next_btn_apk_ > span {
    font-size: 13px;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .stage_agenda_title_subtitle_container > button {
    margin: 0 15px 0 auto;
    width: 18%;
    padding: 8px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 14px;
  }
  .next_btn_apk_ {
    margin: 0 25px 25px auto;
    width: 145px;
    padding: 8px 0;
  }
  .next_btn_apk_ > span {
    font-size: 14px;
  }
}



@media (min-width: 1200px) and (max-width: 1400px) {
  .stage_agenda_title_subtitle_container > button {
    margin: 0 15px 0 auto;
    width: 18%;
    padding: 8px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 16px;
  }
  .next_btn_apk_ {
    margin: 0 25px 25px auto;
    width: 145px;
    padding: 8px 0;
  }
  .next_btn_apk_ > span {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .stage_agenda_title_subtitle_container > button {
    margin: 0 15px 0 auto;
    width: 18%;
    padding: 8px;
  }
  .stage_agenda_title_subtitle_container > button > span {
    font-size: 16px;
  }
  .next_btn_apk_ {
    margin: 0 25px 25px auto;
    width: 145px;
    padding: 8px 0;
  }
  .next_btn_apk_ > span {
    font-size: 16px;
  }
}

.filter_search_clear_main_container {
    width: 100%;
    display: flex;
    margin: 20px auto 0;
}

.filter_text_container {
    display: flex;
    width: 30%;
}

.filter_text_container>h6 {

    margin-bottom: 0px;
    font-size: 16px;
    color: var(--theme-blue);
    font-family: "IBMMed";
    letter-spacing: 0.5px;
}

.search_result_clear_all_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;

    margin-bottom: 0px;

    color: var(--theme-blue);


}

.search_result_clear_all_container>h6 {
    margin: 0 0 5px;
    font-family: "IBMMed";
    letter-spacing: 0.5px;
}

.search_result_clear_all_container>button:last-child {
    font-family: "IBMMed";
    font-size: 16px;
    background: transparent;
    border: 0;
    letter-spacing: 0.5px;
    margin: 0 0 5px;
}


/* section */

.all_width_filters_container {
    width: 30%;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    max-height: 100vh;
    height: 80vh;
    flex-direction: column;
    /* background-color: aqua; */

}

.result_width_section_container {
    width: 70%;
    max-height: 100vh;
    height: 80vh;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* background-color: blueviolet; */
}


.all_width_filters_container>p {
    font-size: 40px;
}


.result_width_section_container>p {
    font-size: 40px;

}

.multi_select {
    border: 0;
}

.input_dialog_style1 {

    box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
    -webkit-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
    -moz-box-shadow: 1px 4px 7px -4px rgb(0 0 0 / 57%);
    outline: none;
    background: rgba(249, 249, 249, 0.82);
    border: 0;
    height: 45px;
    border-radius: 7px;
    padding: 10px 1% 10px 1%;
    margin: 4px 0 0 0;
    font-family: "IBMMed";
    font-weight: 400;
    font-size: 18px;
    width: 100%;
    color: rgba(27, 46, 75, 1);
}

.css-wsp0cs-MultiValueGeneric {
    font-family: "IBMMed" !important;

}

.all_select_input_box_in_filterleft_side_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
}

.squre_text_checkbox_container {
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .search_result_clear_all_container {
      
        width: 100%;
    }
  .margin_cut{
    margin: 0 0 10px;
  }
    .filter_search_clear_main_container>button {
        font-family: 'IBMMED';
        display: flex;
        /* width: 35%; */
        width: 90px;
        height: 32px;
        border: 0;
        color: #fff;
        font-size: 13px;
        outline: none;
        background-color: var(--theme-blue);
        padding: 0;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        margin: 0 0 10px auto;
        border-radius: 20px;
        text-transform: capitalize;
        gap: 4px;
        letter-spacing: .5px;
        -webkit-border-radius: 4px;
    }

    .filter_search_clear_main_container {
        width: 90%;
        display: flex;
        margin: 20px auto 0;
        flex-direction: column;
    }

    .apply_clear_btn_container{
        width: 96%;
        margin: 0 auto;
    }
    .all_width_filters_container {
        background: rgba(0, 0, 0, 0.034);
    border-radius: 5px;
    padding: 4px 16px;
        width: 100%;
        margin: 15px auto;
        position: relative;
        top: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        max-height: 100vh;
        height: auto;
        flex-direction: column;
      
        animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
    }

    .hide {
        display: none;

        /* animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-out-hesitate both; */
    }

    .show_filter_btn {
        display: static;
    }

    .result_width_section_container {
        width: 100%;
        margin: 10px auto 15px;
        position: relative;
        height: auto;
        overflow-y: auto;
        animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
    }


    @keyframes wipe-in-right {
        from {
            clip-path: inset(0 100% 0 0);
        }

        to {
            clip-path: inset(0 0 0 0);
        }
    }







    @keyframes wipe-in-down {
        from {
            clip-path: inset(0 0 100% 0);
        }

        to {
            clip-path: inset(0 0 0 0);
        }
    }




}

@media (min-width: 767px) {
    .margin_cut{
        font-size: 16px !important;
   
      }
    .show_filter_btn {
        display: none;
    }

    .filter_search_clear_main_container {
        width: 767px;
        display: flex;

    }

    .all_width_filters_container {
        width: 230px;
        top: 115px;
        padding: 0 0 100px;

    }

    .result_width_section_container {
        top: 115px;
        width: 536px;
        display: flex;
        margin-left: 230px;

    }


}

@media (min-width: 991px) {
    .filter_search_clear_main_container {
        width: 990px;
        display: flex;

    }

    .all_width_filters_container {
        width: 297px;
        top: 115px;
    }

    .result_width_section_container {
        top: 115px;
        width: 694px;
        display: flex;
        margin-left: 297px;

    }
}


@media (min-width: 1200px) {
    .filter_search_clear_main_container {
        width: 1100px;
        display: flex;

    }

    .all_width_filters_container {
        width: 330px;
        top: 130px;
    }

    .result_width_section_container {
        top: 130px;
        width: 770px;
        display: flex;
        margin-left: 330px;

    }


}

@media (min-width: 1400px) {
    .filter_search_clear_main_container {
        width: 1200px;
        display: flex;

    }

    .all_width_filters_container {
        width: 360px;
        top: 120px
    }

    .result_width_section_container {
        top: 120px;
        width: 840px;
        display: flex;
        margin-left: 360px;


    }

}
.shortlisted-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.floating_shortlisted_icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

.shortlisted-popover {
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* max-width: 600px; */
    max-width: max-content;
    max-height: 300px;
    overflow-y: auto;
}

/* .popover {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 16px;
  } */

.popover-content {
    /* max-width: 200px; */
    max-width: 400px;
    width: 40vw;
}

.popover-content h3 {
    margin-top: 0;
}

.popover-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.popover-content li {
    margin-bottom: 8px;
}
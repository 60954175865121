.two_under_main_container {
}
.text_content_container > strong {
  text-transform: capitalize;
}
.scroll_comment_section{
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.upload_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(72, 94, 144, 0.16);
  margin-bottom: 30px;
  width: 100%;
  font-family: IBMReg;
  background-color: #fff;
}
.upload_main_container:hover {
  border: 1px solid rgba(1, 1, 2, 0.16);
}

.upload_btn1 {
  /* background: red; */
  height: 50px;
  width: 100%;
  padding: 1% 0 1% 1%;
  display: flex;
  font-family: IBMReg;
  align-items: center;
  flex-direction: row;
}
.all_upload_btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gallry_btn {
  /* background: greenyellow; */
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border-left: 1px solid rgba(72, 94, 144, 0.16);
  justify-content: center;
}
.share_text {
  font-size: 15px;
  margin: 0 0 0 2%;
  font-family: IBMReg;
  color: rgb(131, 146, 165);
}

.icons_self {
  font-size: 16px;
  color: rgb(131, 146, 165);
  line-height: 1;
}
.icons_self1 {
  color: rgb(131, 146, 165);
  line-height: 1;
}

.latest_activity_container {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  /* border: 1px solid rgba(72, 94, 144, 0.16); */
  border-radius: 4px;
}
.latest_activity_container1 {
  padding: 0px 20px;
  /* border-top: 1px solid rgba(72, 94, 144, 0.16); */
  background-clip: border-box;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
.latest_profile_name_subname_timeperiod_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 15px 20px;
}

.latest_profile_contianer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #c3c3c3;
  /* background: #f5f5f5; */
}

.profile_img_activity {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile_name_subname_container {
  margin-left: 15px;
  flex: 1 1 0%;
}

.profile_name_acivity {
  margin-bottom: 3px;
  font-family: IBMMed;
  text-transform: capitalize;
  color: rgb(131, 146, 165);
}

.profile_subname_acivity {
  display: block;
  color: rgb(131, 146, 165);
  font-size: 13px;
  font-family: IBMReg;
  text-transform: capitalize;
}
.five_hour_text {
  font-size: 12px;
  color: rgb(131, 146, 165);
  align-self: flex-start;
  display: block;
}
.szh-menu-button {
  border: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
.latest_acitivity_text {
  text-transform: uppercase;
  margin-bottom: 0px;
  font-family: IBMMed;
  padding: 3% 0 3% 0;
}
.details_activity_text {
  margin: 0px 0px 15px;
  font-family: IBMReg;
  color: var(--theme-blue);
  font-size: 14px;
  line-height: 19px;
  padding: 15px 20px;
}
.like_comment_share_container {
  display: flex;
  flex-direction: row;
  color: var(--theme-blue);
  position: relative;
  align-items: center;
  height: 50px;
  border-top: 1px solid rgba(72, 94, 144, 0.16);
}
.comment_contaier {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: 0;
  flex-direction: row;
  color: var(--theme-blue);
}
.like_text {
  margin-right: 10px;
  font-family: IBMReg;
  transition: 5s ease-in 1;
}
.like_text:hover {
  margin-right: 10px;
  font-family: IBMMed;
}
.liked_text {
  margin-right: 10px;
  font-family: IBMMed;
}

.space_box {
  height: 30px;
  background: #c9bebe47;
}
.img_post {
  width: 100%;
  margin: auto;
  padding: 1% 0 5% 0;
}
.post_img_self {
  width: 80%;
  display: block;
  /* margin-left: auto; */
  margin-left: auto;
  margin-right: auto;
}
.like_count_modal_container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 5px;
  cursor: pointer;
}
.like_count_modal_container:hover {
  text-decoration: underline;
}

.like_count_modal_container > svg {
  color: var(--theme-blue);
  font-size: 20px;
}
.like_count_modal_container > p {
  color: var(--theme-blue);
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
.video_iframe_upload {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  margin: 10px 0;
  background: rgb(0 0 0 / 71%);
  border: 1px dashed #1b2e4b33;
}
.close_btn_photos_container {
  position: absolute;
  background: var(--theme-blue);
  color: #fff;
  width: 25px;
  height: 25px;
  /* border: 1px solid var(--theme-blue); */
  /* font-size: 20px; */
  display: flex;
  border: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  border-radius: 50%;
}
.img_flex_wrap_container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 3% 0;
  position: relative;
}
.ply_pause_btn_container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 30px;
  position: absolute;
  bottom: 7%;
  left: 2%;
}
.play_vdo_btn_container {
  background: transparent;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 0;
  /* position: absolute;
  background: transparent;
  color: var(--theme-red);
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  left: 20%;
  bottom: 23%; */
  /* width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-blue);
  color: #fff;
  border: 0;
  gap: 10px;

  font-size: 14px; */

  /* position: absolute;
  background: transparent;
  color: var(--theme-red);
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  tran
  sform: translate(-50%, -50%); */
}
.play_vdo_btn_container:hover {
  transition: 0.5s all;
  color: var(--theme-red);
}
.full_screen_icon {
  position: absolute;
  background: transparent;
  color: var(--theme-red);
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  right: 2%;
  bottom: 7%;
}
.full_screen_icon > svg {
  color: #fff;
  font-size: 20px;
}
/* .play_vdo_btn_container:hover {
  background: #fff;
  color: var(--theme-blue);
  border: 1px solid var(--theme-blue);
} */

.play_vdo_btn_container > svg {
  font-size: 26px;
}
/* .vdo_post_style {
  height: auto !important;
} */

@media screen and (max-width: 767px) {
  .scroll_comment_section{
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  /* .play_vdo_btn_container {
    gap: 7px;
    font-size: 10px;
    letter-spacing: 1px;
  }
  .play_vdo_btn_container > svg {
    font-size: 14px;
  } */
  .ply_pause_btn_container {
    bottom: 4%;
  }
  .play_vdo_btn_container {
    background: transparent;
    color: #fff;
    width: 25px;
    height: 25px;
    border: 0;
  }
  .play_vdo_btn_container > svg {
    font-size: 18px;
  }
  .close_btn_photos_container {
    width: 18px;
    height: 18px;
  }
  .close_btn_photos_container > svg {
    font-size: 12px;
  }
  .full_screen_icon > svg {
    font-size: 16px;
  }
  .like_count_modal_container > svg {
    font-size: 16px;
  }
  .like_count_modal_container > p {
    margin: 0;
    font-size: 12px;
  }
  .upload_main_container {
    margin-bottom: 13px;
  }
  .icons_self {
    font-size: 16px;
  }
  .icons_self1 {
    font-size: 20px;
  }
  .upload_btn1 {
    padding: 1% 0 1% 3%;
  }
  .share_text {
    font-size: 13px;
    margin: 0 0 0 8%;
  }
  .latest_acitivity_text {
    padding: 6% 0 6% 0;
    font-size: 13px;
  }
  .latest_profile_contianer {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .profile_name_subname_container {
    margin-left: 10px;
  }
  .profile_name_acivity {
    font-size: 13px;
  }
  .profile_subname_acivity {
    font-size: 9px;
  }
  .details_activity_text {
    margin: 0px 0px 15px;

    font-size: 11px;
    line-height: 14px;
    padding: 0px 24px;
  }
  .like_text {
    font-size: 13px;
  }
  .like_icon {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .img_post {
    width: 400px;
  }
  .profile_subname_acivity {
    font-size: 11px;
  }
  .details_activity_text {
    margin: 0px 0px 15px;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 20px;
  }
  .two_under_main_container {
    /* background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
  }
  .upload_main_container {
    border-radius: 0 0;
  }
  .latest_activity_container {
    margin-bottom: 0px;
    border: 1px solid #0000002b;
  }
}

@media screen and (min-width: 991px) {
  .like_count_modal_container > svg {
    font-size: 15px;
  }
  .like_count_modal_container > p {
    font-size: 12px;
  }
  .two_under_main_container {
    width: 355px;
    /* border: 1px solid #0000002b; */
    margin: 0 auto;
  }
  .upload_main_container {
    margin-bottom: 16px;
  }

  .upload_btn1 {
    height: 40px;

    padding: 1% 0 1% 1%;
  }
  .all_upload_btn_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .gallry_btn {
    height: 40px;
  }
  .share_text {
    font-size: 14px;
    margin: 0 0 0 4%;
  }

  .icons_self {
    font-size: 14px;

    line-height: 1;
  }
  .icons_self1 {
    line-height: 1;
  }

  .latest_activity_container {
    margin-bottom: 25px;

    min-width: 0px;
  }
  .latest_activity_container1 {
    padding: 6px 20px;
    /* border-top: 1px solid rgba(72, 94, 144, 0.16); */
    background-clip: border-box;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  }
  .latest_profile_name_subname_timeperiod_container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 15px 20px;
  }

  .latest_profile_contianer {
    width: 40px;
    border-radius: 50%;
  }

  .profile_img_activity {
    width: 100%;
    border-radius: 50%;
  }

  .profile_name_subname_container {
    margin-left: 15px;
    flex: 1 1 0%;
  }

  .profile_name_acivity {
    margin-bottom: 3px;
    font-family: IBMMed;
    font-size: 12px;
  }

  .profile_subname_acivity {
    display: block;
    color: rgb(131, 146, 165);
    font-size: 11px;
    font-family: IBMReg;
  }
  .five_hour_text {
    font-size: 11px;
    color: rgb(131, 146, 165);
    align-self: flex-start;
    display: block;
  }
  .szh-menu-button {
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: transparent;
  }
  .latest_acitivity_text {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .details_activity_text {
    margin: 0px 0px 15px;
    font-family: IBMReg;
    color: var(--theme-blue);
    font-size: 12px;
    line-height: 16px;
    padding: 0px 20px;
  }
  .like_comment_share_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
  }
  .comment_contaier {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: none;
    border: 0;
    flex-direction: row;
  }
  .like_text {
    margin-right: 10px;
    font-size: 12px;
  }
  .like_text:hover {
    margin-right: 10px;
    font-family: IBMMed;
  }
  .liked_text {
    font-size: 12px;
    margin-right: 10px;
    font-family: IBMMed;
  }
  .like_icon {
    font-size: 14px;
  }
  .space_box {
    height: 30px;
    background: #c9bebe47;
  }
  .img_post {
    width: 100%;

    padding: 1% 0 5% 0;
  }
  .post_img_self {
    width: 80%;
    display: block;
    /* margin-left: auto; */
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .vdo_post_style {
    height: 300px;
  }
  .upload_main_container {
    border-radius: 0;
  }
  .like_text {
    margin-right: 10px;
    font-size: 14px;
  }
  .liked_text {
    font-size: 14px;
  }
  .gallry_btn {
    height: 50px;
  }
  .upload_btn1 {
    height: 50px;
    padding: 1% 0 1% 1%;
  }

  .two_under_main_container {
    width: 498px;
    margin: 0px auto 30px;
    /* border: 1px solid #0000002b; */
    border-radius: 5px;
    /* background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 75%);
    border-radius: 10px; */
  }
  .icons_self {
    font-size: 16px;
    line-height: 1;
  }
  .share_text {
    font-size: 16px;
    margin: 0 0 0 3%;
  }
  .icons_self {
    font-size: 17px;
    line-height: 1;
    margin: 0 0 0 2%;
  }
  .icons_self1 {
    line-height: 1;
    font-size: 19px;
  }
  .profile_name_acivity {
    margin-bottom: 3px;
    font-family: IBMMed;
    font-size: 15px;
  }
  .profile_subname_acivity {
    display: block;
    color: rgb(131, 146, 165);
    font-size: 13px;
    font-family: IBMReg;
  }
  .details_activity_text {
    margin: 0px 0px 15px;

    font-size: 13px;
    line-height: 18px;
    padding: 0px 20px;
  }
  .post_img_self {
    width: 90%;
  }
}

@media screen and (min-width: 1400px) {
  .two_under_main_container {
    width: 570px;
    margin: 0 auto;
  }
  .vdo_post_style {
    height: 350px;
  }
}

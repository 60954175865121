.application_main_content_table {
  overflow-x: auto;
  color: var(--theme-blue);
}
.icon_yellow{
  color: #FDCC0D;
}
.pagination_tab_univercity_course_container{
  min-height: 50px;
  /* bottom: 5%; */
  margin: 20px auto 0;
  display: grid;
  width: 100%;
  align-items: center;

}
.highlight_text_univ{
  cursor: pointer;
  
}
.highlight_text_univ:hover{
 
    font-family: 'IBMSemBold';
}
.heading_container {
  width: 100%;
}
.heading_container > th {
  text-align: center;
  font-family: IBMBold;
}
.data_content_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clg_img_container_circle {
}
.clg_img_self {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.univ_date_container {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0 10px;
}
.univ_title_text {
  margin: 0;
  font-family: IBMMed;
  text-align: left;
  font-size: 13px;
}
.date_text_ {
  margin: 0;
  color: #afa9a9;
  font-family: IBMMed;
  font-size: 13px;
  text-align: left;
}

th,
td {
  text-align: center;
  padding: 8px;
}
.icon_progress_text {
  background: #f9e7b0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding: 5px;
  gap: 9px;
  border-radius: 5px;
}
.yellow_color_text {
  color: #d6a300;
  font-size: 19px;
}
.progress_text {
  margin: 0;
  font-family: IBMMed;
  font-size: 13px;
}
.stage_text {
  margin: 0;
  font-family: IBMMed;
  font-size: 13px;
}
.view_btn_container {
  background-color: var(--theme-grey);
  /* border: 2px solid var(--theme-blue); */
  outline: none;
  padding: 8px;
  border: 0;
  font-family: IBMSemBold;
  width: 40%;
  margin: 0 auto;
  border-radius: 5px;
  color: var(--theme-blue);
  font-size: 15px;
}
.view_btn_container:hover {
  background-color: var(--theme-blue);
  color: #fff;
}
.heading_container > tr > th {
  font-family: 'IBMSemBold';
}
.go_to_shortlisted_text{

}

@media (max-width: 767px) {
  .heading_container {
    font-size: 13px;
    width: 100%;
  }

  .heading_container > tr > th {
    font-size: 13.5px;
    width: auto;
  }
  .clg_img_container_circle {
    display: none;
  }
  .view_btn_container {
    width: 100%;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .heading_container {
    font-size: 13px;
    width: 1000px;
  }
  .heading_container > tr > th {
    font-size: 12px;
  }
  .view_btn_container {
    /* border: 1px solid var(--theme-blue); */
    width: auto;
    font-size: 12px;
  }
  .data_content_container {
    width: 100%;
  }
  .univ_title_text,
  .date_text_,
  .stage_text,
  .progress_text {
    font-size: 12px;
  }
  .clg_img_self {
    width: 25px;
    height: 25px;
  }
  /* .icon_ {
    display: none;
  } */
}

@media (min-width: 990px) and (max-width: 1199px) {
  .heading_container {
    font-size: 13px;
    width: 100%;
  }
  .heading_container > tr > th {
    font-size: 13px;
  }
  .view_btn_container {
    /* border: 1px solid var(--theme-blue); */
    width: auto;
    font-size: 12px;
  }
  .data_content_container {
    width: 100%;
  }
  .univ_title_text,
  .date_text_,
  .stage_text,
  .progress_text {
    font-size: 12px;
  }
  .clg_img_self {
    width: 25px;
    height: 25px;
  }
  /* .icon_ {
    display: none;
  } */
}

@media (min-width: 1200px) {
  .heading_container {
    width: 1100px;
  }
  .heading_container > tr > th {
    width: 220px;
  }
  .view_btn_container {
    width: auto;
  }
  .clg_img_self {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1400px) {
  .heading_container {
    width: 1200px;
    font-size: 14px;
  }

  .view_btn_container {
    width: 60px;
  }

  .heading_container > tr > th {
    font-size: 14px;
    width: 240px;
  }
  .view_btn_container {
    width: 50px;
    font-size: 13px;
  }
  .data_content_container {
    width: 100%;
  }
  .univ_title_text,
  .date_text_,
  .stage_text,
  .progress_text {
    font-size: 12px;
  }
  .clg_img_self {
    width: 35px;
    height: 35px;
  }
}

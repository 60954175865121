.filter_main_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin: 3% 0 0 0;
}
.filter_submit_btn_container {
  display: flex;
  justify-content: flex-end;
  width: 99%;
  flex-direction: row;
}
.clear_btn_contaienrt {
  display: flex;
  justify-content: flex-end;
  margin: 3% 0 0 0;
}
.clear_all_btn {
  background: transparent;
  border: 0;
  outline: none;
  font-family: IBMBold;
  font-size: 16px;
  color: #001739;
}
.clear_all_btn:hover {
  color: #6a7a88;
}


@media (max-width: 767px) {
  .clear_btn_contaienrt {
    
    margin: 5% 0 0 0;
  }
  .clear_all_btn {
    
    font-size: 13px;
  }
  .filter_main_contianer {
    margin: 5% 0 0 0;
  }
  .filter_submit_btn_container {
    width: 100%;
  }
  
}

@media (min-width: 991px){
  .clear_all_btn {
    
    font-size: 17px;
  }
  .clear_btn_contaienrt {
    width: 98%;
    margin: 0% 0 0 0;
  }
  .filter_main_contianer {
    margin: 5% 0 0 0;
  }
  .filter_submit_btn_container {
    width:98%;
  }
}
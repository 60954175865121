.view_profile_main_container {
  display: flex;
  max-width: 100%;
  margin: 2% auto 0% auto;
  justify-content: center;
  position: relative;
}
.center_loader_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.one_main_container {
  /* width: 30%; */
  position: relative;
  /* background: rgb(133, 241, 133); */
}

.two_main_container {
  margin: 0 1% 0 1%;
  /* background: #c9bebe47; */
  /* background: rgb(0, 128, 100); */
}

.third_main_container {
  /* width: 30%; */
  /* background: rgb(22, 112, 214); */
}

.btn_whats_ap {
  position: fixed;
  z-index: 5;
  bottom: 10%;
  right: 5%;
  background: transparent;
  border: 0;
  outline: none;
}

.whts_app_chat {
  width: 50px;
}

.chat_with_text {
  font-size: 15px;
  color: #000;
  font-family: "IBMMed";
  letter-spacing: 1px;
  background: #ffffff61;
}

.header_chat_content {
  background: var(--theme-red);
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.close_btn {
  outline: none;
  background: transparent;
  border: 0;
  padding: 5%;
}

.close_btn_icon {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 767px) {
  .btn_whats_ap {
    position: fixed;
    z-index: 5;
    bottom: 4%;
    right: 5%;
    background: transparent;
    border: 0;
    outline: none;
  }
  .whts_app_chat {
    width: 35px;
  }
  .chat_with_text {
    font-size: 11px;
    color: #000;
    font-family: "IBMMed";
    letter-spacing: 1px;
    background: #ffffff61;
  }
  .header_chat_content {
    background: var(--theme-red);
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
  .rsc {
    width: 92%;
    margin: 0% 0 0 8%;
  }
  .close_btn {
    outline: none;
    background: transparent;
    border: 0;
    padding: 5%;
  }
  .close_btn_icon {
    width: 25px;
    height: 25px;
  }
  .view_profile_main_container {
    display: flex;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    flex-direction: column;
  }
  .one_main_container {
    width: 100%;
  }
  .two_main_container {
    width: 100%;
    margin: 5% 1% 0 1%;
    background: #c9bebe47;
    /* background: rgb(0, 128, 100); */
  }
  .third_main_container {
    width: 100%;
    /* background: rgb(22, 112, 214); */
  }
  .two_under_main_container {
    margin: 4% 3% 0 3%;
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .whts_app_chat {
    width: 35px;
  }
  .chat_with_text {
    font-size: 12px;
    color: #000;
    font-family: "IBMMed";
    letter-spacing: 1px;
    background: #ffffff61;
  }
  .btn_whats_ap {
    position: fixed;
    z-index: 5;
    bottom: 2%;
    right: 5%;
    background: transparent;
    border: 0;
    outline: none;
  }
  .view_profile_main_container {
    display: flex;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    flex-direction: column;
  }
  .one_main_container {
    min-width: 100%;
  }
  .two_main_container {
    min-width: 94%;
    margin: 5% 1% 0 1%;
    background: #c9bebe47;
    /* background: rgb(0, 128, 100); */
  }
  .third_main_container {
    min-width: 100%;
    /* background: rgb(22, 112, 214); */
  }
  .two_under_main_container {
    margin: 0% 0% 0 0%;
  }
}

@media screen and (min-width: 991px) {
  .view_profile_main_container {
    display: flex;
    max-width: 900px;
    margin: 30px auto;
    flex-direction: row;
  }
  .one_main_container {
    width: 30%;
  }
  .two_main_container {
    width: 40%;
    margin: 0% 1%;
    /* background: #c9bebe47; */
  }
  .third_main_container {
    width: 30%;
  }
}

@media screen and (min-width: 1200px) {
  .view_profile_main_container {
    max-width: 1100px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }
  .whts_app_chat {
    width: 45px;
  }
  .two_main_container {
    width: 47%;
    margin: 30px 0% 0 0%;
  }
  .one_main_container {
    width: 25%;
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    position: relative;
  }
  .third_main_container {
    width: 28%;
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    position: relative;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1400px) {
  .view_profile_main_container {
    max-width: 1300px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }
  .two_main_container {
    width: 47%;
  }
  .one_main_container {
    width: 25%;
  }
  .third_main_container {
    width: 28%;
  }
}

.tab_connection_contianer {
  background: #fff;
  margin: 3% 0 0 0;
  padding: 0 5% 0% 0%;
  width: 90%;
  margin: 0 13px 0 auto;
  border-bottom: 1px solid #00000054;
  border-radius: 0;
}
.tab_active_count_text {
  background-color: rgb(229, 233, 242);
  color: rgb(131, 146, 165);
  font-size: 10px;
  font-weight: 500;
  font-family: IBMReg;
  padding: 3px 5px 4px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

  border-radius: 3px;
  margin-left: 10px;
  margin-bottom: 5px;
}
.tab_count_text {
  background-color: var(--theme-blue);
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 500;
  font-family: IBMReg;
  padding: 3px 5px 4px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  margin-bottom: 5px;
  border-radius: 3px;
  margin-left: 10px;
}
.content_tabs_container {
  width: 91%;
  margin: 0 0 0 auto;
}
.group_contianer {
  width: 92%;
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* profile */

.group_box_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px px solid #000;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.25rem;
  padding: 2%;
  width: 31.33%;
  margin: 0 10px 20px 0;
}
.group_pic_self {
  width: 36%;
  border-radius: 50%;
  margin: 0px 0 15px 0;
}
.group_title {
  color: var(--theme-blue);
}
.member_count_text {
  color: rgb(131, 146, 165);
  font-size: 13px;
}
.avatar_img {
  width: 15%;
  border-radius: 50%;
  margin: 0 -3% 0 -3%;
  border: 2px solid #fff;
}
.connection_text_mutual {
  color: rgb(131, 146, 165);
  font-size: 12px;
}
.avtar_group_img_contianer {
  margin: 0 0 4% 0;
}
.join_btn {
  border: 1px solid rgb(192, 204, 218);
  color: rgba(27, 46, 75, 0.7);
  background-color: rgb(255, 255, 255);
}
.same_btn_group {
  border-radius: 0.25rem;
  display: block;
  font-family: IBMReg;
  width: 100%;
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  outline: none;
  align-items: center;
  justify-content: center;

  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s;
}
.leave_btn {
  color: rgb(255, 255, 255);
  background-color: rgba(27, 46, 75, 1);
  border: 0;
}
.leave_btn:hover {
  color: rgba(27, 46, 75, 1);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(27, 46, 75, 1);
}
.join_btn:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(27, 46, 75, 1);
}

@media (max-width: 767px) {
  .tab_connection_contianer {
    margin: 3% 0 0 0;
    padding: 0 0% 0% 0%;
    width: 94%;

    margin: 0 auto 0 auto;
  }
  .same_btn_group {
    font-size: 13px;
    padding: 4% 2%;
  }
  .content_tabs_container {
    width: 91%;
    margin: 0 auto 0 auto;
  }
}

@media (min-width: 768px) {
  .tab_connection_contianer {
    margin: 3% 0 0 0;
    padding: 0 0% 0% 0%;
    width: 94%;

    margin: 0 auto 0 auto;
  }
  .same_btn_group {
    font-size: 13px;
    padding: 4% 2%;
  }
  .content_tabs_container {
    width: 91%;
    margin: 0 auto 0 auto;
  }
}

@media (min-width: 1200px) {
  .tab_count_text {
    font-size: 15px;
  }
  .tab_active_count_text {
    font-size: 15px;
  }
}

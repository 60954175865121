.gallery_main_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gallery_img1 {
  position: relative;
  width: 24%;
  padding: 8px;
  background: #ebebeb;
  transition: 0.5s;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border: 2px solid #00000061;
  margin: 0.5%;
  border-radius: 5px;
  cursor: pointer;
}
.gallery_img1 > img {
  width: 100%;
  object-fit: contain;
}
.gallery_img1:hover {
  /* transform: scale(1.1); */
  box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 75%);
}
.gallery_vdo_show {
  width: 24%;
  margin: 0.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  transition: 1s;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 2px 2px 17px 6px rgb(0 0 0 / 50%);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;

  background: var(--theme-blue);
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0 5px 0;
  border-radius: 50%;
}
.overlay-arrows_left {
  display: flex;
  background-color: var(--theme-blue);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  cursor: pointer;
  z-index: 999;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  padding: 5px;
  margin: 0 0 0 15px;
}
.overlay-arrows_right {
  top: 50%;
  right: 0;
  position: absolute;
  cursor: pointer;
  display: flex;
  background-color: var(--theme-blue);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  padding: 5px;
  margin: 0 15px 0 0px;
}

.overlay-arrows_left svg {
  width: 35px;
  height: 35px;
  color: #ffffff;
}

.overlay-arrows_right svg {
  width: 35px;
  height: 35px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .gallery_img1 {
    width: 49%;
  }
  .overlay-arrows_left {
    width: 30px;
    height: 30px;
    margin: 0 0 0 5px;
  }
  .overlay-arrows_left svg {
    width: 22px;
    height: 26px;
  }
  .overlay-arrows_right {
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0px;
  }
  .overlay-arrows_right svg {
    width: 22px;
    height: 26px;
  }
  .overlay img {
    display: block;
    max-width: 50%;
    max-height: 80%;
    margin: 50px auto;
  }
  .overlay > span {
    position: absolute;
    top: 71px;
    right: 14px;
    font-size: 23px;

    background: var(--theme-blue);
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0 5px 0;
    border-radius: 50%;
  }
  .overlay-arrows_right {
    top: 50%;
    right: 0;
    position: absolute;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 50px auto;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .overlay img {
    max-width: 60%;
    max-height: 80%;
    margin: 50px auto;
  }
}

@media (min-width: 768px) {
  .overlay > span {
    position: absolute;
    top: 83px;
    right: 20px;
    font-size: 27px;
    padding: 0px 0 5px;
    width: 30px;
    height: 30px;
  }
  .gallery_img1 {
    width: 32%;
  }
  .overlay-arrows_left,
  .overlay-arrows_right {
    width: 40px;
    height: 40px;
  }
  .overlay-arrows_left svg,
  .overlay-arrows_right svg {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 1200px) {
  .overlay > span {
    position: absolute;
    top: 105px;
    right: 21px;
    font-size: 37px;
    padding: 0px 0 6px;
    width: 40px;
    height: 40px;
  }
  .gallery_img1 {
    width: 24%;
  }
  .overlay-arrows_left,
  .overlay-arrows_right {
    width: 45px;
    height: 45px;
  }
  .overlay-arrows_left svg,
  .overlay-arrows_right svg {
    width: 30px;
    height: 30px;
  }
}

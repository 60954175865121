.intro_page_main_container {
}
.intro_under_page_container {
  margin: 0 auto;
  padding: 50px 0;
}

.intro_robo_container {
  width: 100px;
}

.intro_robo_container > img {
  width: 100%;
}
.all_under_content_container_intro {
  margin: 40px auto 50px;
}
.extra_text_intro_btn {
  font-size: 17px;
  line-height: 22px;
  font-family: "IBMReg";
}
.title_text_intro_text {
  font-family: IBMSemBold;
  font-size: 23px;
  line-height: 34px;
  color: var(--theme-blue);
  margin: 0;
}

.title_btn_text_intro {
  font-family: ibmreg;
  font-size: 17px;
  line-height: 22px;
  color: var(--theme-blue);
  margin: 0;
}

.all_btn_container_intro {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin: 15px 0;
}

.select_btn_intro {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  border: 0;
  border-radius: 3px;
}

.select_btn_intro_selected {
  padding: 11px 33px;
  font-family: "IBMSemBold";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: var(--theme-blue);
  border-radius: 3px;
  border:0;
  outline: none;
}

.btn_continue_later_text_container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 25px;
}
.select_btn_intro:hover {
  border: 1px solid var(--theme-blue);
}
.remember_text_all,
.highlight_text_blue_intro {
  font-size: 13px;
  text-align: center;
  font-family: "IBMReg";
  line-height: 16px;
  color: var(--theme-blue);
}
.highlight_text_blue_intro {
  font-family: IBMSemBold !important;
}

@media (max-width: 299px) {
  .intro_under_page_container,
  .all_under_content_container_intro {
    width: 94%;
  }
  .intro_robo_container {
    width: 80px;
    margin: 0 auto;
  }
  .all_under_content_container_intro {
    margin: 25px auto;
  }
  .title_text_intro_text {
    font-size: 19px;
    line-height: 26px;
  }
  .extra_text_intro_btn {
    font-size: 12px;
    line-height: 17px;
  }
  .title_btn_text_intro {
    font-size: 12px;
    line-height: 16px;
  }
  .all_btn_container_intro {
    display: flex;
    flex-wrap: nowrap;
    gap: 19px;
    overflow-y: hidden;
    margin: 15px 0;
    flex-direction: row;
    padding: 0px 0 20px;
    overflow-x: auto;
  }
  .select_btn_intro {
    padding: 10px 11px;
    font-size: 12px;
    line-height: 15px;
  }
  .select_btn_intro_selected {
    padding: 10px 11px;
    font-size: 12px;
    line-height: 15px;
  }
  .btn_continue_later_text_container {
    gap: 16px;
  }
  .remember_text_all,
  .highlight_text_blue_intro {
    font-size: 13px;
    text-align: center;
    font-family: "IBMReg";
    line-height: 16px;
    color: var(--theme-blue);
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .intro_under_page_container,
  .all_under_content_container_intro {
    width: 290px;
  }
  .intro_robo_container {
    width: 80px;
    margin: 0 auto;
  }
  .all_under_content_container_intro {
    margin: 25px auto;
  }
  .title_text_intro_text {
    font-size: 20px;
    line-height: 31px;
  }
  .extra_text_intro_btn {
    font-size: 16px;
    line-height: 23px;
  }
  .title_btn_text_intro {
    font-size: 16px;
    line-height: 18px;
  }
  .all_btn_container_intro {
    display: flex;
    flex-wrap: nowrap;
    gap: 19px;
    overflow-y: hidden;
    margin: 15px 0;
    flex-direction: row;
    padding: 0px 0 20px;
    overflow-x: auto;
  }
  .select_btn_intro {
    padding: 10px 11px;
    font-size: 15px;
    line-height: 16px;
  }
  .select_btn_intro_selected {
    padding: 10px 11px;
    font-size: 15px;
    line-height: 16px;
  }
  .btn_continue_later_text_container {
    gap: 16px;
  }
  .remember_text_all,
  .highlight_text_blue_intro {
    font-size: 15px;
    text-align: center;
    font-family: "IBMReg";
    line-height: 19px;
    color: var(--theme-blue);
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .intro_under_page_container,
  .all_under_content_container_intro {
    width: 390px;
  }
  .intro_robo_container {
    width: 80px;
    margin: 0 auto;
  }
  .all_under_content_container_intro {
    margin: 25px auto;
  }
  .title_text_intro_text {
    font-size: 20px;
    line-height: 31px;
  }
  .extra_text_intro_btn {
    font-size: 16px;
    line-height: 23px;
  }
  .title_btn_text_intro {
    font-size: 16px;
    line-height: 18px;
  }
  .all_btn_container_intro {
    display: flex;
    flex-wrap: nowrap;
    gap: 19px;
    overflow-y: hidden;
    margin: 15px 0;
    flex-direction: row;
    padding: 0px 0 20px;
    overflow-x: auto;
  }
  .select_btn_intro {
    padding: 10px 11px;
    font-size: 15px;
    line-height: 16px;
  }
  .select_btn_intro_selected {
    padding: 10px 11px;
    font-size: 15px;
    line-height: 16px;
  }
  .btn_continue_later_text_container {
    gap: 16px;
  }
  .remember_text_all,
  .highlight_text_blue_intro {
    font-size: 15px;
    text-align: center;
    font-family: "IBMReg";
    line-height: 19px;
    color: var(--theme-blue);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .intro_under_page_container {
    width: 700px;
  }
  .all_under_content_container_intro {
    width: 570px;
  }
  .all_under_content_container_intro {
    width: 570px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .intro_under_page_container {
    width: 930px;
  }
  .all_under_content_container_intro {
    width: 670px;
  }
  .title_text_intro_text {
    font-size: 26px;
    line-height: 37px;
  }
  .extra_text_intro_btn {
    font-size: 18px;
    line-height: 26px;
  }
  .title_btn_text_intro {
    font-size: 19px;
    line-height: 24px;
  }
  .select_btn_intro {
    padding: 11px 33px;

    font-size: 17px;
    line-height: 22px;
    border: 0;
    padding: 16px 25px;
    border-radius: 3px;
  }
  .select_btn_intro_selected {
    padding: 11px 33px;

    font-size: 17px;
    line-height: 22px;
    padding: 16px 25px;
    border-radius: 3px;
  }
  .remember_text_all,
  .highlight_text_blue_intro {
    font-size: 15px;

    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  .intro_under_page_container {
    width: 1100px;
  }
  .all_under_content_container_intro {
    width: 870px;
  }
}

@media (min-width: 1400px) {
  .intro_under_page_container {
    width: 1300px;
  }
  .all_under_content_container_intro {
    width: 911px;
  }
  .extra_text_intro_btn {
    font-size: 21px;
    line-height: 28px;
  }
  .title_text_intro_text {
    font-size: 27px;
    line-height: 39px;
  }
  .title_btn_text_intro {
    font-size: 19px;
    line-height: 25px;

    margin: 0;
  }
  .select_btn_intro {
    padding: 16px 24px;

    font-size: 17px;
    line-height: 20px;
  }
  .select_btn_intro_selected {
    padding: 16px 24px;

    font-size: 17px;
    line-height: 20px;
  }
  .all_btn_container_intro {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    margin: 16px 0;
  }
  .remember_text_all,
  .highlight_text_blue_intro {
    font-size: 16px;
  }
}
